import { FixedSessionReadModel } from '../../../../common/models/calendar/fixedsession.readmodel';
import { OpenBookedSessionReadModel } from '../../../../common/models/calendar/openbookedsession.readmodel';
import { JsonProperty, SimpleProperty } from '../../../../common/parsing/json-property.decorator';
import { TrajectoryItemNodeReadModel } from './trajectoryitemnode.readmodel';

export class UserPurchasesReadModel {
  @SimpleProperty() public userId: number = undefined;
  @SimpleProperty() public userName: string = undefined;
  @SimpleProperty() public credits: number = undefined;
  @JsonProperty(null, FixedSessionReadModel)
  public sessions: FixedSessionReadModel[] = undefined;
  @JsonProperty(null, OpenBookedSessionReadModel)
  public openSessions: OpenBookedSessionReadModel[] = undefined;
  @SimpleProperty() public notPlannedSessions: TrajectoryItemNodeReadModel[] = undefined;
}
