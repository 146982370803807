import { NgModule } from '@angular/core';

import { HttpNotificationService } from './services/httpnotifications.service';
import { HttpTaskNotificationService } from './services/httptasknotifications.service';
import { MenuService } from './services/menu.service';
import { SignalRService } from './services/signalr.service';
import { HttpScheduledNotificationService } from './services/httpschedulednotifications.service';

@NgModule({
    providers: [SignalRService, MenuService, HttpNotificationService, HttpScheduledNotificationService, HttpTaskNotificationService],
})
export class AppCoreModule {}
