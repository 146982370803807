import {
    GetApplicationMenuCompletedAction,
    GetFooterMenuItemsCompletedAction,
    GetHeaderMenuItemsCompletedAction,
    GetMenuItemsCompletedAction,
    layoutActionKeys,
    LayoutActions,
    SetLanguageCompletedAction,
    SetActiveRoleCompletedAction,
} from './layout.actions';
import { initialLayoutState, LayoutState } from './layout.state';

export function layoutReducer(state: LayoutState = initialLayoutState, action: LayoutActions): LayoutState {
    switch (action.type) {
        case layoutActionKeys.OPENSIDENAV:
            return Object.assign({}, state, { showSideNav: true });
        case layoutActionKeys.CLOSESIDENAV:
            return Object.assign({}, state, { showSideNav: false });
        case layoutActionKeys.OPENOFFSIDENAV:
            return Object.assign({}, state, { showOffSideNav: true });
        case layoutActionKeys.CLOSEOFFSIDENAV:
            return Object.assign({}, state, { showOffSideNav: false });
        case layoutActionKeys.GETMENUITEMS_COMPLETED:
            return Object.assign({}, state, { menuItems: (<GetMenuItemsCompletedAction>action).menuItems });
        case layoutActionKeys.GETFOOTERMENUITEMS_COMPLETED:
            return Object.assign({}, state, { footerMenuItems: (<GetFooterMenuItemsCompletedAction>action).menuItems });
        case layoutActionKeys.GETHEADERMENUITEMS_COMPLETED:
            return Object.assign({}, state, { headerMenuItems: (<GetHeaderMenuItemsCompletedAction>action).menuItems });
        case layoutActionKeys.GETAPPLICATIONMENU_COMPLETED:
            return Object.assign({}, state, { applicationMenu: (<GetApplicationMenuCompletedAction>action).applicationMenu });
        case layoutActionKeys.SETTRANSLATIONLANGUAGE_COMPLETED:
            return Object.assign({}, state, { currentLanguage: (<SetLanguageCompletedAction>action).language });
        case layoutActionKeys.SETACTIVEROLE_COMPLETED:
            return Object.assign({}, state, { currentRole: (<SetActiveRoleCompletedAction>action).role });
        case layoutActionKeys.SHOWAPPLOADINGINDICATOR:
            return Object.assign({}, state, { showLoadingIndicatorCounter: state.showLoadingIndicatorCounter + 1 });
        case layoutActionKeys.HIDEAPPLOADINGINDICATOR:
            return Object.assign({}, state, {
                showLoadingIndicatorCounter: state.showLoadingIndicatorCounter <= 0 ? 0 : state.showLoadingIndicatorCounter - 1,
            });
        case layoutActionKeys.COLLAPSEHEADERNAV:
            return Object.assign({}, state, { collapseHeaderNav: true });
        case layoutActionKeys.EXPANDHEADERNAV:
            return Object.assign({}, state, { collapseHeaderNav: false });
        default:
            return Object.assign({}, state);
    }
}
