import * as moment from 'moment';

import { DateTimeParser } from '../../../../../common/parsing/datetime.parser';
import { JsonProperty, SimpleProperty } from '../../../../../common/parsing/json-property.decorator';

export class ProgramListModel {
  @SimpleProperty()
  public id: number = undefined;
  @SimpleProperty()
  public name: string = undefined;
  @SimpleProperty()
  public poNumber: string = undefined;
  @SimpleProperty()
  public articleNumber: string = undefined;
  @SimpleProperty()
  public nrOfMonths: number = undefined;
  @JsonProperty(null, null, () => new DateTimeParser())
  public endDate: moment.Moment = undefined;
  @SimpleProperty()
  public isOrderPlaced: boolean = undefined;
  @SimpleProperty()
  public isInvoiceCreated: boolean = undefined;
  @SimpleProperty()
  public orderId: number = undefined;
  @SimpleProperty()
  public invoiceId: number = undefined;
  @SimpleProperty()
  public isGranted: boolean = undefined;
  @SimpleProperty()
  public isCreatedByOv: boolean = undefined;
  @SimpleProperty()
  public isPublished: boolean = undefined;
}
