import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '../../../common/core/http/http.service';
import { MappingHttpService } from '../../../common/core/http/mappinghttp.service';
import { UriBuilder } from '../../../common/core/http/uri.builder';
import { IPageDefinition } from '../../../common/core/models/ipagedefinition';
import { CreditPageResult } from '../../../common/models/items/credits/credit.result';
import { MapUtils } from '../../../common/parsing/mapping.util';
import { UserMembershipModel } from '../../admin/memberships/users/models/user.membership.model';
import { TrajectoryItemNodeReadModel } from './models/trajectoryitemnode.readmodel';
import { UserPurchasesReadModel } from './models/userpurchases.readmodel';







@Injectable()
export class HttpUserPurchaseService {
  private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('userpurchases');
  protected get baseUri(): UriBuilder {
    return this._uri.clone();
  }

  constructor(private httpService: HttpService, private tenantConfigurationFactory: TenantConfigurationFactory,
    private tenantConigurationFactory: TenantConfigurationFactory, private mappingHttpService: MappingHttpService) { }

  public getById(userId: number, isLongRequest: boolean = true): Observable<UserPurchasesReadModel> {
    return this.mappingHttpService.getAndMap<UserPurchasesReadModel>(
      UserPurchasesReadModel,
      this.baseUri.withId(userId).build(),
      null,
      isLongRequest
    );
  }

  public getCurrentUserPurchases(isLongRequest: boolean = true): Observable<UserPurchasesReadModel> {
    return this.mappingHttpService.getAndMap<UserPurchasesReadModel>(
      UserPurchasesReadModel,
      this.baseUri.withPath('currentuser').build(),
      null,
      isLongRequest
    );
  }

  public getCurrentUserPlannableTrajectoryItems(isLongRequest: boolean = true): Observable<TrajectoryItemNodeReadModel[]> {
    return this.httpService.getAll<TrajectoryItemNodeReadModel>(
      this.baseUri
        .withPath('currentuser')
        .withPath('plannabletrajectoryitems')
        .build(),
      null,
      isLongRequest
    );
  }

  public getCredits(userId: number, filterDefinition: IPageDefinition, isLongRequest: boolean = true): Observable<CreditPageResult> {
    return this.httpService.getPagedFiltered<CreditPageResult, IPageDefinition>(
      this.baseUri
        .withId(userId)
        .withPath('credits')
        .build(),
      filterDefinition,
      null,
      isLongRequest
    );
  }

  public buyMembership(membership: UserMembershipModel, isLongRequest: boolean = true): Observable<boolean> {
    return this.httpService
      .custom('POST', this.baseUri.withPath('buymembership').build(), MapUtils.serialize(membership), null, isLongRequest)
      .pipe(map(resp => resp.status === 204));
  }
}
