import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { defaultTranslation } from './common/core/models/translation.model';
import { HttpNotificationService } from './common/core/services/httpnotifications.service';
import { MessageBuilder, MessageTextBuilder } from './common/core/services/message.builder';
import { MessageService } from './common/core/services/message.service';
import { errorHandlingSelector } from './common/errorhandling/errorhandling.selector';
import { ErrorHandlingState } from './common/errorhandling/errorhandling.state';
import {
    GetApplicationMenuAction,
    HideAppLoadingIndicatorAction,
    SetLanguageAction,
    SetActiveRoleAction,
    ShowAppLoadingIndicatorAction,
} from './common/layout/layout.actions';
import { layoutSelectors } from './common/layout/layout.selector';
import { AppState } from './common/redux/app.state';
import { TenantConfigurationFactory } from './tenantconfiguration.factory';
import { BreadcrumbService } from './common/core/services/breadcrumb.service';
import { defaultActiveRole } from './common/core/models/activerole.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @HostBinding('class.layout-fixed')
    isFixed = false;
    @HostBinding('class.aside-collapsed')
    isCollapsed = false; // todo!
    @HostBinding('class.layout-boxed')
    isBoxed = false;
    @HostBinding('class.layout-fs')
    useFullLayout = false;
    @HostBinding('class.hidden-footer')
    hiddenFooter = false;
    @HostBinding('class.layout-h')
    horizontal = false;
    @HostBinding('class.aside-float')
    isFloat = false; // todo
    @HostBinding('class.offsidebar-open')
    offsidebarOpen = false;
    @HostBinding('class.aside-toggled')
    asideToggled = false;
    @HostBinding('class.aside-collapsed-text')
    isCollapsedText = false;

    public showAppWideLoader: boolean;
    @ViewChild(ToastContainerDirective, { static: false })
    toastContainer: ToastContainerDirective;

    constructor(
        private oauthService: OAuthService,
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private translateService: TranslateService,
        private appStore: Store<AppState>,
        private toastr: ToastrService,
        private viewContainer: ViewContainerRef,
        private cdr: ChangeDetectorRef,
        private httpNotiService: HttpNotificationService,
        private messageService: MessageService,
        private tenantConfiguration: TenantConfigurationFactory,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        const tenantConfig = this.tenantConfiguration.getTenantConfig();
        this.setFavIcon('favIcon', tenantConfig.favIcon);
        this.setTitle(tenantConfig.title);
        this.loadStyle('global', tenantConfig.globalCss);
        this.loadStyle('app', tenantConfig.appCss);

        if (tenantConfig.gtmCode) {
            (window as any).__setupGTM(tenantConfig.gtmCode);
        }
        this.oauthService.configure(this.tenantConfiguration.generateAuthConfig());
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.events.subscribe((ev) => this.handleOAuthEvent(ev));
        this.oauthService.loadDiscoveryDocument();
        // Initialize the toaster Root view
        this.toastr.overlayContainer = this.toastContainer;
        // Dispatch all default actions
        this.appStore.dispatch(new SetLanguageAction(defaultTranslation));
        this.appStore.dispatch(new SetActiveRoleAction(defaultActiveRole));
        this.appStore.dispatch(new GetApplicationMenuAction());

        // Register error handling actions
        this.appStore.select(errorHandlingSelector).subscribe((error: ErrorHandlingState) => {
            if (!error.message) {
                return;
            }
            this.messageService.push(
                new MessageBuilder()
                    .asDanger()
                    .withTitle(MessageTextBuilder.makeText('common.erroroccured').doTranslate())
                    .withContent(MessageTextBuilder.makeText(error.message).doTranslate())
                    .withTranslationParameters(error.parameters)
                    .withMediumDuration()
                    .build()
            );
        });

        // // Register Layout actions
        // this.appStore.select(layoutSelectors.showLoadingIndicator).subscribe((isvisible) => {
        //     this.showAppWideLoader = isvisible;
        //     this.cdr.markForCheck();
        // });
        this.appStore.select(layoutSelectors.sidenavSelector).subscribe((isvisible) => (this.isCollapsed = !isvisible));
        this.appStore.select(layoutSelectors.offSidenavSelector).subscribe((isvisible) => (this.offsidebarOpen = isvisible));

        this.httpNotiService.registerSignalR();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.appStore.dispatch(new ShowAppLoadingIndicatorAction());
            }
            if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.appStore.dispatch(new HideAppLoadingIndicatorAction());
            }
        });
    }

    handleOAuthEvent(event: OAuthEvent) {
        if (event.type.indexOf('error') !== -1) {
            this.router.navigate(['/']);
            console.warn(`Retrying signin flow due to ${event.type}!`);
        }
        console.log(`OAuth Event @ ${new Date().toLocaleTimeString()}`, event);
    }

    loadStyle(identifier: string, styleName: string) {
        const head = this.document.getElementsByTagName('head')[0];

        const themeLink = this.document.getElementById(identifier) as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = styleName;
        } else {
            const style = this.document.createElement('link');
            style.id = identifier;
            style.rel = 'stylesheet';
            style.href = `${styleName}`;

            head.appendChild(style);
        }
    }

    setFavIcon(elementId: string, favIconName: string) {
        const favIconElement: any = document.querySelector(`#${elementId}`);
        if (favIconElement) {
            favIconElement.href = favIconName;
        }
    }

    setTitle(title: string) {
        this.titleService.setTitle(title);
    }
}
