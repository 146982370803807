import {
  AuthActionKeys,
  AuthActions,
  GetCurrentUserDataCompletedAction,
  SetCurrentCompanyAction, SetImpersonationStateAction, SetIsAuthenticatedAction,
  UserImpersonationCompletedAction
} from './auth.actions';
import { IAuthState, initialAuthState } from './auth.state';

export function authReducer(state: IAuthState = initialAuthState, action: AuthActions): IAuthState {
  switch (action.type) {
    case AuthActionKeys.GETCURRENTUSERDATA_COMPLETED:
      return Object.assign({}, state, { currentUser: (<GetCurrentUserDataCompletedAction>action).user });
    case AuthActionKeys.SETISAUTHENTICATED:
      return Object.assign({}, state, { isAuthenticated: (<SetIsAuthenticatedAction>action).isAuthenticated });
    case AuthActionKeys.setimpersonationstate:
      return Object.assign({}, state, { impersonatingState: (<SetImpersonationStateAction>action).state });
    case AuthActionKeys.STARTUSERIMPERSONATION_COMPLETED:
      return Object.assign({}, state, { impersonatingUser: (<UserImpersonationCompletedAction>action).userId });
    case AuthActionKeys.FINALIZEIMPERSONATION_COMPLETED:
      return Object.assign({}, state, { impersonatingUser: null });
    case AuthActionKeys.SETCURRENTCOMPANYACTION:
      return Object.assign({}, state, { currentCompanyId: (<SetCurrentCompanyAction>action).currentCompanyId });
    default:
      return Object.assign({}, state);
  }
}
