import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { IMessage } from '../models/message.model';

@Injectable()
export class MessageService {
    private _itemQueued: ReplaySubject<IMessage> = new ReplaySubject<IMessage>(undefined);
    private _resetQueue: Subject<void> = new Subject<void>();
    private _alreadyPushedItems: number[] = [];
    private _deletedQueue: ReplaySubject<IMessage> = new ReplaySubject<IMessage>(undefined);

    public get itemQueued(): Observable<IMessage> {
        return this._itemQueued.asObservable();
    }

    public get resetQueue(): Observable<void> {
        return this._resetQueue.asObservable();
    }

    public get deletedQueue(): Observable<IMessage> {
        return this._deletedQueue.asObservable();
    }

    constructor(private translateService: TranslateService) { }

    public push(message: IMessage) {
        if (message.id) {
            if (_.some(this._alreadyPushedItems, (i) => message.id === i)) {
                return;
            } else {
                this._alreadyPushedItems.push(message.id);
            }
        }

        if (String.isNullOrWhiteSpace(message.origin)) {
            message.origin = 'app';
        }
        if (message.translateTitle) {
            message.title = this.translateService.instant(message.title);
            message.translateTitle = false; // we set it on false, to indicate that it is handled
        }

        if (message.translateContent) {
            message.content = this.translateService.instant(message.content, message.translationParameters);
            message.translateContent = false; // we set it on false, to indicate that it is handled
        }
        this._itemQueued.next(message);
    }

    public reset() {
        this._resetQueue.next();
        this._alreadyPushedItems = [];
    }

    public dismiss(message: IMessage) {
        this._deletedQueue.next(message);
    }
}
