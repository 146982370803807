import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

import { MapUtils } from './../../parsing/mapping.util';

@Injectable()
export class MappingHttpService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Makes a Http GET request to a certain URI for a Certain type T and map it using the maputils
   * @param cls Constructor Type of the model to map
   * @param uri The URI to make the request to
   * @param headers [OPTIONAL] additional headers to append.
   */
  public getAndMap<T>(clz: Type<T>, uri: string, headers?: { key: string; value: string }[], isLongRequest: boolean = false): Observable<T> {
    const hdrs = this.getHeaders(headers, isLongRequest);
    return this.httpClient.get(uri, { headers: hdrs }).pipe(rxoperators.map(res => MapUtils.deserialize(clz, res)));
  }

  /**
   * Makes a Http GET request to a certain URI for a Certain type T[] and map it using the maputils
   * @param cls Constructor Type of the model to map
   * @param uri The URI to make the request to
   * @param headers [OPTIONAL] additional headers to append.
   */
  public getAllAndMap<T>(clz: Type<T>, uri: string, headers?: { key: string; value: string }[], isLongRequest: boolean = false): Observable<T[]> {
    const hdrs = this.getHeaders(headers, isLongRequest);
    return this.httpClient
      .get(uri, { headers: hdrs })
      .pipe(rxoperators.map((res: Array<any>) => _.map(res, item => MapUtils.deserialize(clz, item))));
  }

  /**
   * Creates an entity using a certain path and map it using the maputils
   * @param cls Constructor Type of the Outbound model to map
   * @param model The model to Create
   * @param uri The URI to make the request to
   * @param headers [OPTIONAL] additional headers to append.
   */
  public createAndMap<Tin, Tout>(
    cls: Type<Tout>,
    model: Tin,
    uri: string,
    headers?: { key: string; value: string }[],
    isLongRequest: boolean = false
  ): Observable<Tout> {
    const hdrs = this.getHeaders(headers, isLongRequest);
    return this.httpClient.post(uri, MapUtils.serialize(model), { headers: hdrs }).pipe(rxoperators.map(res => MapUtils.deserialize(cls, res)));
  }

  /**
   * Updates an entity using a certain path and map it using the maputils
   * @param cls Constructor Type of the Outbound model to map
   * @param model The model to Update
   * @param uri The URI to make the request to
   * @param headers [OPTIONAL] additional headers to append.
   */
  public updateAndMap<Tin, Tout>(
    cls: Type<Tout>,
    model: Tin,
    uri: string,
    headers?: { key: string; value: string }[],
    isLongRequest: boolean = false
  ): Observable<Tout> {
    const hdrs = this.getHeaders(headers, isLongRequest);
    const json = MapUtils.serialize(model);
    return this.httpClient.put(uri, json, { headers: hdrs }).pipe(rxoperators.map(res => MapUtils.deserialize(cls, res)));
  }

  private getHeaders(headers: { key: string; value: string }[], isLongRequest: boolean = false): HttpHeaders {
    let hdrs = new HttpHeaders();
    _.forEach(headers, hdr => (hdrs = hdrs.append(hdr.key, hdr.value)));
    if (isLongRequest) {
      hdrs = hdrs.append('app-long-request', 'true');
    }
    return hdrs;
  }
}
