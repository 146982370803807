import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable, map, mergeMap, of, tap } from 'rxjs';
import * as rxoperators from 'rxjs/operators';
import { ActiveRole, defaultActiveRole } from 'app/common/core/models/activerole.model';
import * as _ from 'lodash';
import { Roles } from 'app/common/core/constants/roles';
import { CurrentUserService } from '../../core/auth/currentuser.service';
import { HttpAuthService } from '../../core/auth/httpauth.service';
import { ImpersonationService } from '../../core/auth/impersonation.service';
import { DialogService } from '../../core/dialog/dialog.service';
import { defaultTranslation } from '../../core/models/translation.model';
import { AppInsightService } from '../../core/services/appinsight.service';
import { DataLayerService } from '../../datalayer/datalayer.service';
import { CloseOffSidenavAction, CloseSidenavAction, GetMenuItemsAction, SetLanguageAction, SetActiveRoleAction } from '../../layout/layout.actions';
import { AppState } from '../app.state';
import { GetAllNotificationsAction, GetAllScheduledNotificationsAction } from '../notification.actions';
import { FinalizeAction } from './../app.state';
import {
    AfterGetCurrentUserDataCompletedAction,
    AuthActionKeys,
    GetCurrentUserDataAction,
    GetCurrentUserDataCompletedAction,
    SetCurrentCompanyAction,
} from './auth.actions';

@Injectable()
export class AuthEffect {
    getCurrentUserDataEffect: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType<GetCurrentUserDataAction>(AuthActionKeys.GETCURRENTUSERDATA),
            mergeMap((action) => {
                return this.httpService.getCurrentUser().pipe(
                    tap((user) => this.currentUserService.setUser(user)),
                    tap((user) => this.dataLayerService.setCurrentUser(user.reference, user.isCourseManager)),
                    tap(() =>
                        this.appinsights.setAuthenticatedUserContext(
                            this.currentUserService.userId.toString(),
                            this.impersonationService.isImpersonating ? `Impersonating${this.impersonationService.impersonatingUserId}` : undefined
                        )
                    ),
                    tap((user) => {
                        if (!user.consent.acceptedPolicy) {
                            this.currentUserService.clearRolesAndPermissions();
                            this.store.dispatch(new CloseSidenavAction());
                            this.store.dispatch(new CloseOffSidenavAction());
                            this.router.navigate(['/', 'policy']);
                        }
                    }),
                    map((user) => new GetCurrentUserDataCompletedAction(user))
                );
            })
        );
    });
    getCurrentUserDataCompletedEffect: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType<GetCurrentUserDataCompletedAction>(AuthActionKeys.GETCURRENTUSERDATA_COMPLETED),
            mergeMap((action) => {
                this.store.dispatch(new GetMenuItemsAction());
                this.store.dispatch(
                    new SetLanguageAction(
                        action && action.user && action.user.cultureCode ? { code: action.user.cultureCode, displayName: null } : defaultTranslation
                    )
                );

                const highestRole = (action?.user?.roles || []).find((code) => Roles.map((role) => role.code).includes(code));

                this.store.dispatch(new SetActiveRoleAction(highestRole ? { code: highestRole, displayName: null } : defaultActiveRole));
                this.store.dispatch(new GetAllNotificationsAction());
                this.store.dispatch(new GetAllScheduledNotificationsAction());
                if (action.user.profilePercentage < +this.tenantConfigurationFactory.getTenantConfig().profileCompletionTreshold) {
                    window.localStorage.setItem('goto-profile', 'true');
                    window.localStorage.setItem('redirectTo', 'my-profile');
                }
                return of(new AfterGetCurrentUserDataCompletedAction(action.user));
            })
        );
    });

    setCurrentCompanyEffect: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType<SetCurrentCompanyAction>(AuthActionKeys.SETCURRENTCOMPANYACTION),
            rxoperators.mergeMap((action) => {
                this.router.navigate(['/']);
                return of(new FinalizeAction());
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private httpService: HttpAuthService,
        private currentUserService: CurrentUserService,
        private dataLayerService: DataLayerService,
        private router: Router,
        private dialogService: DialogService,
        private appinsights: AppInsightService,
        private tenantConfigurationFactory: TenantConfigurationFactory,
        private impersonationService: ImpersonationService
    ) {}
}
