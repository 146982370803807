import { SimpleProperty } from '../parsing/json-property.decorator';

export class CultureReadModel {
  @SimpleProperty()
  public id: number = undefined;
  @SimpleProperty()
  public code: string = undefined;
  @SimpleProperty()
  public text: string = undefined;

  public get translationKey(): string {
    return this.code ? `common.culture.${this.code.toLowerCase()}` : String.empty();
  }

  static empty(): CultureReadModel {
    const model = new CultureReadModel();
    model.id = undefined;
    model.code = String.empty();
    model.text = String.empty();
    return model;
  }
}
