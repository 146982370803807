import { BehaviorSubject, Observable } from 'rxjs';
import { StepperItem } from './stepper.model';

export class StepperService {
    private _stepperItems: BehaviorSubject<StepperItem[]> = new BehaviorSubject([]);

    public get stepperItems$(): Observable<StepperItem[]> {
        return this._stepperItems.asObservable();
    }

    public initSteppers(stepperItems: StepperItem[]) {
        this._stepperItems.next(stepperItems);
    }
}
