import 'moment/locale/en-gb';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

/* Global overloads of various prototypes */

declare global {
  interface NumberConstructor {
    paddedZeroString(n: number): string;
  }
  interface StringConstructor {
    getDJB2(s: string): number;
    stringToHexColor(s: string): string;
    isNullOrWhiteSpace(s: string): boolean;
    isNullOrEmpty(s: string): boolean;
    empty(): string;
    monthByNumber(monthNumber: number): string;
  }
}

Number.paddedZeroString = (n: number) => {
  let s = String.empty();
  if (n) {
    if (n < 10) {
      s = '0';
    }
    s += n.toString();
  } else {
    s = '00';
  }

  return s;
};

String.getDJB2 = (s: string) => {
  let hash = 5381;
  for (let i = 0; i < s.length; i++) {
    hash = (hash << 5) + hash + s.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
};

String.stringToHexColor = (s: string) => {
  const hash = String.getDJB2(s);
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;
  return '#' + ('0' + r.toString(16)).substr(-2) + ('0' + g.toString(16)).substr(-2) + ('0' + b.toString(16)).substr(-2);
};

String.empty = (): string => '';

String.isNullOrEmpty = (s: string): boolean => s == null || s === String.empty();

String.isNullOrWhiteSpace = (s: string): boolean => String.isNullOrEmpty(s) || s.trim() === String.empty();

String.monthByNumber = (monthNumber: number): string => {
  switch (monthNumber) {
    default:
    case 1:
      return 'common.months.january';
    case 2:
      return 'common.months.february';
    case 3:
      return 'common.months.march';
    case 4:
      return 'common.months.april';
    case 5:
      return 'common.months.may';
    case 6:
      return 'common.months.june';
    case 7:
      return 'common.months.july';
    case 8:
      return 'common.months.august';
    case 9:
      return 'common.months.september';
    case 10:
      return 'common.months.october';
    case 11:
      return 'common.months.november';
    case 12:
      return 'common.months.december';
  }
};

/* MomentJS configuration */

/* Angular Bootstrapping */

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: true
  })
  .catch(err => console.log(err));
