import { routerReducer } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import { errorHandlingReducer } from '../errorhandling/errorhandling.reducer';
import { layoutReducer } from '../layout/layout.reducer';
import { AppState } from './app.state';
import { authReducer } from './auth/auth.reducer';

export const reducers: ActionReducerMap<AppState> = {
  layout: layoutReducer,
  auth: authReducer,
  routerReducer: routerReducer,
  errorHandling: errorHandlingReducer
};

export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function(state: AppState, action: any): AppState {
    const dateStr = new Date().toLocaleTimeString();
    console.log(`State @ ${dateStr} :`, state);
    console.log(`Action @ ${dateStr} :`, action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
