import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ActiveToast, Toast, ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { IMessage, MessageDuration, MessageLevel, MessageType } from '../../../common/core/models/message.model';
import { MessageService } from '../../../common/core/services/message.service';
import { SubscriptionCleanerService } from '../../../common/core/subscriptioncleaner.service';
import { HttpNotificationService } from '../../../common/core/services/httpnotifications.service';

const storageKey = 'fa-toast-items';

@Component({
    selector: 'app-toasts',
    template: '',
})
export class ToastComponent implements OnInit, OnDestroy {
    private subCleaner = new SubscriptionCleanerService();
    constructor(
        @Inject(MessageService) private messageService: MessageService,
        private toastr: ToastrService,
        private router: Router,
        private httpNotificationService: HttpNotificationService
    ) {}

    ngOnInit() {
        this.messageService.itemQueued
            .pipe(
                filter((item) => (item.messageType === MessageType.Toast || item.messageType === MessageType.ToastNotification) && !item.isDismissed)
            )
            .subscribe((item) => {
                if (!item) {
                    return;
                }
                const options = {
                    disableTimeOut: item.messageDuration === MessageDuration.Fixed || item.dismissable,
                    timeOut: <number>item.messageDuration,
                };
                const message = String.isNullOrWhiteSpace(item.content) ? item.title : item.content;
                const title = !String.isNullOrWhiteSpace(item.content) ? item.title : undefined;

                let toast: ActiveToast<Toast>;
                switch (item.messageLevel) {
                    default:
                    case MessageLevel.Info:
                        toast = this.toastr.info(message, title, options);
                        break;
                    case MessageLevel.Success:
                        toast = this.toastr.success(message, title, options);
                        break;
                    case MessageLevel.Warn:
                        toast = this.toastr.warning(message, title, options);
                        break;
                    case MessageLevel.Danger:
                        toast = this.toastr.error(message, title, options);
                        break;
                }
                toast.toastRef.afterActivate().subscribe((t) => {
                    if (item.dismissable || item.dismissMessage) {
                        if (item.dismissMessage) {
                            item.dismissMessage()
                                .then(() => this.toastr.clear(toast.toastId))
                                .catch(() => this.toastr.clear(toast.toastId));
                        }
                    }
                });
                toast.onTap.subscribe((t) => {
                    if (item && item.dismissable) {
                        if (item.beforeDismiss) {
                            item.beforeDismiss().then((canDismiss) => {
                                if (canDismiss) {
                                    this.toastr.clear(toast.toastId);
                                    if (item.onDismiss) {
                                        item.onDismiss();
                                    }
                                }
                            });
                        } else {
                            this.toastr.clear(toast.toastId);
                            if (item.onDismiss) {
                                item.onDismiss();
                            }
                        }
                    }
                    if (item && item.hasAttachment) {
                        this.httpNotificationService.downloadAttachment(item);
                    }
                    if (item.isTask) {
                        this.router.navigate(['/', 'admin', 'users', 'matching', item.id]);
                    }
                });
            });

        // todo: subscribe to deletequeue on messageservice, keep track of toastrs? set dismiss message on object?
        this.messageService.resetQueue.subscribe(() => this.toastr.clear());
    }

    ngOnDestroy() {
        this.subCleaner.destroy();
    }

    private isItemRead(item: IMessage): boolean {
        if (item.origin !== 'serverside') {
            return false;
        }

        let storageItem = window.localStorage.getItem(storageKey);
        if (!String.isNullOrWhiteSpace(storageItem)) {
            storageItem = '[]';
        }
        const messages = (JSON.parse(storageItem) as { origin: string; id: number }[]) || [];

        return _.some(messages, (m) => m.origin === item.origin && m.id === item.id);
    }
}
