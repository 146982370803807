import { TrajectoryType } from '@lms-common/models/cm/trajectorytype.enum';
import { febelfinAcademyFaqLink, febelfinAcademyTrainingHelpLink } from 'environments/constants/fa-translatablelinks.constant';
import { IUiConfiguration } from 'environments/iui.config';

export const febelfinAcademyUiConfiguration: IUiConfiguration = {
    gdprDialog: {
        showAcceptNewsletter: true,
    },
    myCertificates: {
        showAttendanceCertificates: true,
        showExtraTrainingCertificates: true,
        showTrainingResults: true,
    },
    user360: {
        showTrainingResults: true,
        showAccreditations: true,
        showAttendanceCertificates: true,
        showExtraTrainingCertificates: true,
    },
    locations: {
        showFacilities: true,
        showCategories: true,
        showO365Mail: true,
    },
    courseManagement: {
        showSelfManagementToggle: true,
    },
    courseCatalog: {
        defaultType: TrajectoryType.Trajectory,
        showTypeFilter: true,
        showCategoryFilter: true,
        showCertificationTypeFilter: true,
        showDifficultyLevelFilter: true,
        showAccreditationTypeFilter: true,
        showLearningTypeFilter: true,
    },
    dashboard: {
        showTrainingCertificatesWidget: true,
        showAccreditationProgressWidget: true,
        showInterestingTrainingsWidget: true,
    },
    externalLink: {
        userFaq: febelfinAcademyFaqLink,
        extraTraining: febelfinAcademyTrainingHelpLink,
        searchTraining: null,
    },
};
