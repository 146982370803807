import { AuthUserModel } from '../../core/auth/authuser.model';
import { ImpersonationStates } from './auth.actions';

export interface IAuthState {
  currentUser: AuthUserModel;
  isAuthenticated: boolean;
  silentUserdataLoad: boolean;
  impersonatingUser?: number;
  impersonatingState: ImpersonationStates;
  currentCompanyId?: number;
}

export const initialAuthState: IAuthState = {
  currentUser: null,
  isAuthenticated: false,
  silentUserdataLoad: false,
  impersonatingUser: null,
  impersonatingState: ImpersonationStates.Disabled,
  currentCompanyId: null
};
