import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CurrentUserService } from './../../auth/currentuser.service';

@Injectable()
export class CurrentCompanyInterceptor implements HttpInterceptor {
  constructor(@Inject(CurrentUserService) private service: CurrentUserService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let extraHeaders = req.headers;
    if (this.service.currentCompany !== null) {
      extraHeaders = extraHeaders.append('X-CurrentCompany', this.service.currentCompany.toString());
    }
    const interceptedReq = req.clone({ headers: extraHeaders });
    return next.handle(interceptedReq);
  }
}
