import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map } from 'rxjs/operators';

import * as _ from 'lodash';
import { Roles } from '../core/constants/roles';
import { ActiveRole, defaultActiveRole } from '../core/models/activerole.model';
import { ActiveRoleService } from '../core/services/activerole.service';
import { layoutActionKeys, SetActiveRoleAction, SetActiveRoleCompletedAction } from './layout.actions';

@Injectable()
export class ActiveRoleEffects {
    setCurrentRoleEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetActiveRoleAction>(layoutActionKeys.SETACTIVEROLE),
                map((action) => {
                    let modifiedRole = { ...action.role }; // Create a new object with the same properties as action.role

                    if (!modifiedRole) {
                        modifiedRole = defaultActiveRole;
                    }
                    if (String.isNullOrWhiteSpace(modifiedRole.displayName)) {
                        modifiedRole = _.find(Roles, (item: ActiveRole) => item.code === modifiedRole.code);
                    }

                    this.arService._invokeRoleUpdated(modifiedRole);
                    return new SetActiveRoleCompletedAction(modifiedRole);
                })
            ),
        { dispatch: true }
    );

    constructor(private actions$: Actions, private arService: ActiveRoleService) {}
}
