import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { environment } from './../../../../../environments/environment';
import { CurrentCompanyInterceptor } from './currentcompany.interceptor';
import { HttpDebugInterceptor } from './debug.interceptor';
import { DefaultHeadersInterceptor } from './defaultheaders.interceptor';
import { ErrorHandlingInterceptor } from './errorhandling.interceptor.';
import { ImpersonationInterceptor } from './impersonation.interceptor';
import { HttpLongRequestInterceptor } from './longrequest.interceptor';
import { NoContentInterceptor } from './nocontent.interceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule, StoreModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoContentInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLongRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ImpersonationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CurrentCompanyInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true
    },
    environment.production
      ? []
      : {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpDebugInterceptor,
          multi: true
        }
  ]
})
export class InterceptorsModule {}
