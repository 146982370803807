import { errorHandlingActionKeys, ErrorHandlingActions, ShowErrorAction } from './errorhandling.actions';
import { ErrorHandlingState, initialErrorHandlingState } from './errorhandling.state';

export function errorHandlingReducer(state: ErrorHandlingState = initialErrorHandlingState, action: ErrorHandlingActions): ErrorHandlingState {
  switch (action.type) {
    case errorHandlingActionKeys.SHOWERROR:
      return { ...state, message: (<ShowErrorAction>action).message, parameters: (<ShowErrorAction>action).parameters };
    default:
      return { ...initialErrorHandlingState };
  }
}
