import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';

import { HttpService } from '../../common/core/http/http.service';
import { MappingHttpService } from '../../common/core/http/mappinghttp.service';
import { UriBuilder } from '../../common/core/http/uri.builder';
import { CultureReadModel } from '../../common/models/culture.readmodel';







@Injectable()
export class HttpCultureService {
    private _uri: UriBuilder;

    protected get baseUri(): UriBuilder {
        if (!this._uri) {
            this._uri = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('cultures');
        }
        return this._uri.clone();
    }

    constructor(private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory, private mappingHttpService: MappingHttpService) { }

    public getCultures(culture: string, longRequest = false): Observable<CultureReadModel[]> {
        return this.mappingHttpService.getAllAndMap<CultureReadModel>(CultureReadModel, this.baseUri.withPath(culture).build(), null, longRequest);
    }
}
