import { UserDataLayerModel } from './user.datalayermodel';

export class PendingDataLayerModel {
  user: UserDataLayerModel;
  trajectoryOccurrenceId: number;
  name: string;
  category: string;
  variant: string;
  quantity: number = undefined;
  price: number = undefined;

  constructor(user: UserDataLayerModel, trajectoryOccurrenceId: number, name: string, category: string, variant: string, quantity?: number) {
    this.user = user;
    this.trajectoryOccurrenceId = trajectoryOccurrenceId;
    this.name = name;
    this.category = category;
    this.variant = variant;
    this.quantity = quantity;
  }

  setQuantity(quantity: number) {
    this.quantity = quantity;
  }

  setPrice(price: number) {
    this.price = price;
  }

  toDataLayerFormat() { // convert to format specified by D&G
    return {
      'ID': this.trajectoryOccurrenceId,
      'Name': this.name,
      'Category': this.category,
      'Variant': this.variant,
      'User-Id': this.user.reference,
      'Responsible': this.user.isCourseManager,
      'Quantity': this.quantity,
      'Price': this.price
    };
  }
}
