import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

import { defaultTranslation, Translation } from '../models/translation.model';

@Injectable()
export class TranslationManager {
  private _langUpdated: BehaviorSubject<Translation> = new BehaviorSubject<Translation>(defaultTranslation);
  public get onLangUpdated(): Observable<Translation> {
    return this._langUpdated.asObservable();
  }

  _invokeLangUpdated(translation: Translation) {
    this._langUpdated.next(translation);
  }
}
