import { TranslatableLinks } from '@lms-common/core/models/translatablelinks.model';

export const febelfinAcademyFaqLink: TranslatableLinks[] = [
    //faq menu item
    {
        cultureCode: 'en-GB',
        elink: 'https://www.febelfin-academy.be/en/questions',
    },
    {
        cultureCode: 'nl-BE',
        elink: 'https://www.febelfin-academy.be/nl/vragen',
    },
    {
        cultureCode: 'fr-BE',
        elink: 'https://www.febelfin-academy.be/fr/questions',
    },
];

//Extra training
export const febelfinAcademyTrainingHelpLink: TranslatableLinks[] = [
    {
        cultureCode: 'en-GB',
        elink: 'https://www.febelfin-academy.be/en/questions/training-courses-cpd/how-many-cpd-hours-do-i-need-to-take',
    },
    {
        cultureCode: 'nl-BE',
        elink: 'https://www.febelfin-academy.be/nl/vragen/opleidingen-bijscholing/hoeveel-bijscholing-dien-ik-te-volgen',
    },
    {
        cultureCode: 'fr-BE',
        elink: 'https://www.febelfin-academy.be/fr/questions/formations-recyclage/combien-dheures-de-recylcages-dois-je-suivre',
    },
];
