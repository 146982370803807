import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/core';
import {
    DateTimeProvider,
    DefaultOAuthInterceptor,
    NullValidationHandler,
    OAuthLogger,
    OAuthModule,
    OAuthModuleConfig,
    OAuthNoopResourceServerErrorHandler,
    OAuthResourceServerErrorHandler,
    OAuthService,
    OAuthStorage,
    SystemDateTimeProvider,
    UrlHelperService,
    ValidationHandler,
} from 'angular-oauth2-oidc';
import { TenantConfigurationFactory } from './tenantconfiguration.factory';

export class CustOAuthModule {
    constructor(private tenantConfigFactory: TenantConfigurationFactory) {}

    static forRoot(validationHandlerClass = NullValidationHandler): ModuleWithProviders<OAuthModule> {
        return {
            ngModule: OAuthModule,
            providers: [
                OAuthService,
                UrlHelperService,
                { provide: OAuthLogger, useFactory: CustOAuthModule.createDefaultLogger },
                { provide: OAuthStorage, useFactory: CustOAuthModule.createDefaultStorage },
                { provide: ValidationHandler, useClass: validationHandlerClass },
                { provide: DateTimeProvider, useClass: SystemDateTimeProvider },
                { provide: OAuthResourceServerErrorHandler, useClass: OAuthNoopResourceServerErrorHandler },
                { provide: OAuthModuleConfig, useFactory: CustOAuthModule.oAuthModuleConfigFactory },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: DefaultOAuthInterceptor,
                    multi: true,
                },
            ],
        };
    }

    static createDefaultLogger() {
        return console;
    }

    static createDefaultStorage() {
        return typeof sessionStorage !== 'undefined' ? sessionStorage : null;
    }

    static oAuthModuleConfigFactory() {
        return {
            resourceServer: {
                allowedUrls: [
                    TenantConfigurationFactory.getTenantConfigDirectly().poapiroot,
                    TenantConfigurationFactory.getTenantConfigDirectly().mediaapiroot,
                ],
                sendAccessToken: true,
            },
        };
    }
}
