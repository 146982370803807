import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpService } from '../http/http.service';
import { UriBuilder } from '../http/uri.builder';
import { IMessage, Message } from '../models/message.model';
import { MessageService } from './message.service';

@Injectable()
export class HttpScheduledNotificationService {
    private _uri: UriBuilder;

    protected get baseUri(): UriBuilder {
        if (!this._uri) {
            this._uri = new UriBuilder(this.tenantConigurationFactory.getTenantConfig()).withPoApiRoot().withPath('scheduledusernotifications');
        }
        return this._uri.clone();
    }

    constructor(
        private httpService: HttpService,
        private tenantConigurationFactory: TenantConfigurationFactory,
        private messageService: MessageService
    ) {}

    getAll(): Observable<IMessage[]> {
        return this.httpService.getAll<IMessage>(this.baseUri.build(), null, false).pipe(
            map((messages) => {
                return messages.map((message) => {
                    const msg = new Message(
                        message.title,
                        message.messageType,
                        message.messageLevel,
                        message.messageDuration,
                        message.id,
                        message.creationTimestamp,
                        message.iconClass,
                        message.translateTitle,
                        message.content,
                        message.translateContent,
                        true,
                        message.isDismissed,
                        'serverside',
                        message.translationParameters,
                        false,
                        message.hasAttachment,
                        message.dismissMessage,
                        message.onDismiss,
                        message.beforeDismiss
                    );
                    if (msg.id) {
                        msg.beforeDismiss = () => {
                            return new Promise((onResolve, onReject) => {
                                this.dismiss(msg.id).subscribe((res) => {
                                    onResolve(res);
                                    msg.isDismissed = true;
                                    this.messageService.dismiss(msg);
                                });
                            });
                        };
                    }
                    return msg;
                });
            })
        );
    }

    dismiss(id: number): Observable<boolean> {
        return this.httpService.custom('PUT', this.baseUri.withId(id).build()).pipe(
            map((resp) => resp.status === 204),
            catchError((resp) => of(false))
        );
    }
}
