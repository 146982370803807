import * as moment from 'moment';
import { DateTimeParser } from '../../../parsing/datetime.parser';
import { JsonProperty, SimpleProperty } from '../../../parsing/json-property.decorator';


export class PurchasedLicenseReadModel {
    @SimpleProperty() public id: number = undefined;
    @SimpleProperty() public name: string = undefined;
    @SimpleProperty() public articleNumber: string = undefined;
    @SimpleProperty() public price: number = undefined;
    @JsonProperty(null, null, () => new DateTimeParser()) public purchaseDate: moment.Moment = undefined;
    @JsonProperty(null, null, () => new DateTimeParser()) public startDate: moment.Moment = undefined;
    @JsonProperty(null, null, () => new DateTimeParser()) public expireDate: moment.Moment = undefined;
    @SimpleProperty() public numberOfEntities: number = undefined;
    public isInEditMode = false;

    public get isExpired() {
        return this.expireDate.isBefore(moment(), 'day');
    }
}
