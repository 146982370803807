import { SimpleProperty } from '../../parsing/json-property.decorator';

export class SessionArticleReadModel {
    @SimpleProperty()
    public id: number = undefined;
    @SimpleProperty()
    public articleId: number = undefined;
    @SimpleProperty()
    public articleNumber: string = undefined;
    @SimpleProperty()
    public description: string = undefined;
    @SimpleProperty()
    public discount: number = undefined;
    isReadonly = true;

    constructor(id?: number, articleId?: number, articleNumber?: string, description?: string, discount?: number) {
        this.id = id;
        this.articleId = articleId;
        this.articleNumber = articleNumber;
        this.description = description;
        this.discount = discount;
    }
}
