import { Component, Inject, Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { ITenantConfiguration } from 'environments/itenant.config';
import { WINDOW } from './window.injectiontoken';

@Injectable({ providedIn: 'root' })
export class TenantConfigurationFactory {
    constructor(@Inject(WINDOW) private window: Window) {
        console.log('TenantConfigurationFactory created');
    }

    public getTenantConfig(): ITenantConfiguration {
        return environment.multitenancy[this.window.location.host];
    }

    public generateAuthConfig(): AuthConfig {
        const tenantConfig = this.getTenantConfig();

        return new AuthConfig({
            issuer: tenantConfig.authConfig.identityroot,
            redirectUri: tenantConfig.approot + '/auth/callback',
            silentRefreshRedirectUri: tenantConfig.approot + '/silent-refresh.html',
            timeoutFactor: 0.4,
            logoutUrl: tenantConfig.authConfig.identityroot + tenantConfig.authConfig.identityLogoutUrl,
            postLogoutRedirectUri: tenantConfig.approot + '/index.html',
            clientId: tenantConfig.authConfig.clientId,
            scope: tenantConfig.authConfig.scopes,
            showDebugInformation: true,
            sessionChecksEnabled: true,
        });
    }

    static getTenantConfigDirectly() {
        return environment.multitenancy[window.location.host];
    }
}
