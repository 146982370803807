import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../models/breadcrumb.model';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            const root = this.router.routerState.snapshot.root;
            const breadcrumbs: Breadcrumb[] = [];

            this.addBreadcrumb(root, [], breadcrumbs);

            this._breadcrumbs$.next(breadcrumbs);
        });
    }

    private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
        if (route) {
            // Construct the route URL
            const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

            // Add an element for the current route part
            if (route.data.breadcrumb || route.data.breadcrumbkey) {
                const usekey = !String.isNullOrEmpty(route.data.breadcrumbkey)
                    && !breadcrumbs.some((b) => b.translateKey === route.data.breadcrumbkey);

                const breadcrumb = {
                    label: !usekey ? this.getLabel(route.data) : null,
                    translateKey: usekey ? route.data.breadcrumbkey : null,
                    url: '/' + routeUrl.join('/'),
                };
                if (!breadcrumbs.some((b) => b.url === breadcrumb.url)) {
                    breadcrumbs.push(breadcrumb);
                }
            }

            this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }
    }
    private getLabel(data: Data) {
        return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
    }
}
