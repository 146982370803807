import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../redux/app.state';
import { UserImpersonationAction } from '../../redux/auth/auth.actions';

@Injectable()
export class ImpersonationService {
    private _isImpersonating = false;
    private _impersonatingUserId?: number;

    public get impersonateHeaderName() {
        return 'X-Impersonate';
    }

    public get impersonateQueryString() {
        return 'impersonationctx';
    }

    public get isImpersonating() {
        return this._isImpersonating;
    }

    public get impersonatingUserId() {
        return this._impersonatingUserId;
    }

    constructor(private store: Store<AppState>) {}

    public startUserImpersonating(userId: number) {
        this.store.dispatch(new UserImpersonationAction(userId));
    }

    public setIsImpersonating(userId?: number) {
        this._isImpersonating = userId !== null;
        this._impersonatingUserId = userId;
    }
}
