import { Injectable } from '@angular/core';
import { SessionListModel } from 'app/common/models/sessions/session.listmodel';
import { SessionAvailabilityModel } from 'app/common/models/sessions/sessionavailability.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SelectableTreeNode } from '../../../common/models/cm/tree/treebladenode.model';
import { CourseTreeNodeViewModel } from '../../coursecatalog/detail/models/icatalog.viewmodel';

@Injectable()
export class CatalogdetailService {
    /**
     * Recursive method to flatten out all entities from the tree structure and filters to only get the EE, LE (plannable)
     * If an articleNumber is available, a node needs to be selectable
     * @param children the nested children of a certain element
     */
    public extractNodes(parent: CourseTreeNodeViewModel, children: SelectableTreeNode[], selectFixedSessionAppointment: boolean) {
        const res: CourseTreeNodeViewModel[] = [];
        _.forEach(children, (node: SelectableTreeNode) => {
            const treeModel: CourseTreeNodeViewModel = {
                node: node,
                sessions: _.map(node.sessions, (s) => this.createSessionListModel(s)),
                isSelected: true, // All entities are purchaseable,
                isSelectable: !String.isNullOrEmpty(node.articleNumber),
                isRequired: node.isRequired,
                isPlannable: node.isPlannable,
                childNodes: [],
                parentNode: parent,
                vacancyLevel: node.vacancyLevel,
            };

            if (parent) {
                treeModel.isSelectable = treeModel.isSelectable && parent.isSelectable;
            }

            res.push(treeModel);

            if (treeModel.isPlannable && treeModel.sessions) {
                if (selectFixedSessionAppointment) {
                    const fixedSessions = _.filter(treeModel.sessions, (s) => !s.isOpenSession);
                    if (fixedSessions.length === 1) {
                        treeModel.selectedAppointment = fixedSessions[0];
                    }
                } else {
                    if (treeModel.sessions.length === 1) {
                        treeModel.selectedAppointment = treeModel.sessions[0];
                    }
                }
            }

            if (node.childNodes && node.childNodes.length !== 0) {
                treeModel.childNodes.push(...this.extractNodes(treeModel, node.childNodes, selectFixedSessionAppointment));
            }
        });

        return res;
    }

    private createSessionListModel(session: SessionListModel): SessionListModel {
        const s = new SessionListModel();
        s.id = session.id;
        s.locationResource = session.locationResource;
        s.name = session.name;
        s.faAvailability = this.createAvailability(session.faAvailability);
        s.elanPlusAvailability = this.createAvailability(session.elanPlusAvailability);
        s.startDate = moment(session.startDate).local();
        s.endDate = moment(session.endDate).local();
        s.isOpenSession = session.isOpenSession;
        s.isExternal = session.isExternal;
        s.isSharedSession = session.isSharedSession;
        s.isProctorSession = session.isProctorSession;
        return s;
    }

    private createAvailability(sam: SessionAvailabilityModel): SessionAvailabilityModel {
        const newSam = new SessionAvailabilityModel();
        newSam.capacity = sam.capacity;
        newSam.availableSlots = sam.availableSlots;
        return newSam;
    }
}
