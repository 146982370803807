import { NamedResource } from '../../../../../common/core/models/namedresource.model';
import { JsonProperty } from '../../../../../common/parsing/json-property.decorator';
import { OccurrenceSessionReadModel } from './occurrencesession.readmodel';
import { PlannableOccurrenceEntityReadModel } from './plannableoccurrenceentity.readmodel';

export class OccurrenceEntityReadModel {
  @JsonProperty(null, NamedResource)
  public parentEntity: NamedResource = undefined;

  @JsonProperty(null, PlannableOccurrenceEntityReadModel)
  public plannableEntity: PlannableOccurrenceEntityReadModel = undefined;

  @JsonProperty(null, OccurrenceSessionReadModel)
  public sessions: OccurrenceSessionReadModel[] = undefined;
}
