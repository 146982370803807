import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';

import { MappingHttpService } from '../core/http/mappinghttp.service';
import { UriBuilder } from '../core/http/uri.builder';
import { CountryReadModel } from '../models/country.readmodel';





@Injectable()
export class HttpCountryServiceService {
  private _baseUri: UriBuilder;
  public get baseUri(): UriBuilder {
    if (!this._baseUri) {
      this._baseUri = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('countries');
    }
    return this._baseUri.clone();
  }

  constructor(private mappingHttpService: MappingHttpService,
    private tenantConfigurationFactory: TenantConfigurationFactory) { }

  public getCountries(): Observable<CountryReadModel[]> {
    return this.mappingHttpService.getAllAndMap<CountryReadModel>(CountryReadModel, this.baseUri.build(), null, true);
  }
}
