import { HttpParams } from '@angular/common/http';
import { ITenantConfiguration } from 'environments/itenant.config';
import * as _ from 'lodash';

export class UriBuilder {
    protected uri: string[] = [];
    protected queryString: HttpParams;
    private tenantConfig: ITenantConfiguration;

    constructor(private tenantConfiguration: ITenantConfiguration) {
        this.queryString = new HttpParams();
        this.tenantConfig = tenantConfiguration;
    }

    public withPoApiRoot(): UriBuilder {
        this.uri = [this.tenantConfig.poapiroot];
        return this;
    }

    public withMediaApiRoot(): UriBuilder {
        this.uri = [this.tenantConfig.mediaapiroot];
        return this;
    }

    public withIdentityRoot(): UriBuilder {
        this.uri = [this.tenantConfig.authConfig.identityroot];
        return this;
    }

    public withAppRoot(): UriBuilder {
        this.uri = [this.tenantConfig.approot];
        return this;
    }

    public withPath(path: string): UriBuilder {
        this.uri.push(path);
        return this;
    }

    public withQueryString(key: string, value: string): UriBuilder {
        if (!String.isNullOrWhiteSpace(key) && !String.isNullOrWhiteSpace(value)) {
            this.queryString = this.queryString.append(key, value);
        }
        return this;
    }

    public withId(id: number): UriBuilder {
        this.uri.push(id.toString());
        return this;
    }

    public withFilterParam<TFilterParam>(filterParam: TFilterParam) {
        _.forEach(Object.keys(filterParam), (key) => {
            if (filterParam[key] != null) {
                const isString = typeof filterParam[key] === 'string';
                const isArray = filterParam[key].constructor === Array;
                if (isArray) {
                    for (const param of filterParam[key]) {
                        if (param) {
                            this.queryString.append(key, param.toString());
                        }
                    }
                } else if (!isString || (isString && !String.isNullOrWhiteSpace(filterParam[key]))) {
                    this.queryString.append(key, filterParam[key].toString());
                }
            }
        });
        return this;
    }

    public build(): string {
        const qrystr = this.queryString.toString();
        return this.uri.join('/') + (String.isNullOrWhiteSpace(qrystr) ? '' : '?' + qrystr);
    }

    public clone(): UriBuilder {
        const builder = new UriBuilder(this.tenantConfig);
        builder.uri = [...this.uri];
        // todo copy querystring
        return builder;
    }
}
