import { JsonProperty } from '../../parsing/json-property.decorator';
import { IResource } from './iresource.model';

export class Resource implements IResource {
  @JsonProperty() public id: number = undefined;

  constructor(id?: number) {
    this.id = id;
  }
}

export class ResourceList {
  @JsonProperty(null, Resource)
  public items: Resource[] = undefined;

  constructor(items: Resource[] = []) {
    this.items = items;
  }
}
