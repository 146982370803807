import { Component, Inject, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    template: `<span>{{ 'auth.logout' | translate }}</span>`,
})
export class LogoutComponent implements OnInit {
    constructor(@Inject(OAuthService) private oauthService: OAuthService) {}

    ngOnInit() {
        this.oauthService.loadDiscoveryDocument().then(() => {
            this.oauthService.logOut();
        });
    }
}
