import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ImpersonationService } from '../../auth/impersonation.service';

@Injectable()
export class ImpersonationInterceptor implements HttpInterceptor {
  constructor(@Inject(ImpersonationService) private service: ImpersonationService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let extraHeaders = req.headers;
    if (this.service.isImpersonating && this.service.impersonatingUserId !== null) {
      extraHeaders = extraHeaders.append(this.service.impersonateHeaderName, this.service.impersonatingUserId.toString());
    }
    const interceptedReq = req.clone({ headers: extraHeaders });
    return next.handle(interceptedReq);
  }
}
