import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TabComponent } from './tab.component';
import { TabsetComponent } from './tabset.component';
import { TabTranscludeDirective } from './tabtransclude.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TabComponent, TabsetComponent, TabTranscludeDirective],
  exports: [TabComponent, TabsetComponent]
})
export class TabsModule {}
