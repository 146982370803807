import { getClazz, getJsonProperty, IJsonMetaData, IParser } from './json-property.decorator';

export class MapUtils {
  public static isPrimitiveType(obj): boolean {
    const typeIs = typeof obj;
    return (
      typeIs === 'string' ||
      obj instanceof String ||
      obj === String ||
      (typeIs === 'number' || obj instanceof Number || obj === Number) ||
      (typeIs === 'boolean' || obj instanceof Boolean || obj === Boolean)
    );
  }

  public static isArray(obj): boolean {
    return obj === Array || (typeof Array.isArray === 'function' && Array.isArray(obj)) || obj instanceof Array;
  }

  public static deserialize<T>(clazz: new() => T, jsonObject: any): T {
    if (jsonObject === null || typeof jsonObject === 'undefined' || clazz === null) {
      return null;
    }
    const res = new clazz();
    Object.keys(res).forEach(key => {
      const metadata: IJsonMetaData<T> = getJsonProperty<T>(res, key);
      if (metadata != null) {
        const property = metadata.name || key;
          const innerJson = jsonObject ? jsonObject[property] : null;
          const clazz = getClazz(res, key);
          const simpleMapping = metadata.simpleMapping;
        let parser: IParser<any> = null;
        if (simpleMapping) {
          res[key] = jsonObject[key];
        } else {
          if (metadata.parserFactory != null) {
            parser = metadata.parserFactory();
          }
          if (MapUtils.isArray(clazz)) {
            if (parser != null || metadata.cls || MapUtils.isPrimitiveType(clazz)) {
              if (innerJson && MapUtils.isArray(innerJson)) {
                if (parser === null) {
                  res[key] = innerJson.map(item => MapUtils.deserialize(metadata.cls, item));
                } else {
                  res[key] = innerJson.map(item => parser.parse(item));
                }
              }
            } else {
              res[key] = undefined;
            }
          } else if (MapUtils.isPrimitiveType(clazz)) {
            if (parser === null) {
              res[key] = jsonObject ? jsonObject[property] : undefined;
            } else {
              res[key] = jsonObject ? parser.parse(jsonObject[property]) : undefined;
            }
          } else {
            if (parser === null) {
              res[key] = MapUtils.deserialize(clazz, innerJson);
            } else {
              res[key] = parser.parse(innerJson);
            }
          }
        }
      } else if (jsonObject && jsonObject[key] != null) {
        res[key] = jsonObject[key];
      }
    });
    return res;
  }
  public static serialize<T>(instance: T): any {
    if (instance === null || typeof instance === 'undefined') {
      return null;
    }
    const res = {};
    Object.keys(instance).forEach(key => {
      const metadata: IJsonMetaData<T> = getJsonProperty<T>(instance, key);
      if (metadata == null) {
        // if there is no metadata found and the property does not start with _, just map them 1-1
        if (!key.startsWith('_')) {
          res[key] = instance[key];
        }
      } else {
        const property = metadata.name || key;
          const clz = getClazz(instance, key);
          const simpleMapping = metadata.simpleMapping;
        if (simpleMapping) {
          res[key] = instance[key];
        } else {
          let parser: IParser<any> = null;
          if (metadata.parserFactory != null) {
            parser = metadata.parserFactory();
          }

          if (MapUtils.isPrimitiveType(clz)) {
            if (parser === null) {
              if (!isNaN(instance[key])) {
                res[property] = instance[key];
              } else {
                res[property] = instance[key] || null;
              }
            } else {
              res[property] = parser.jsonify(instance[key] || null);
            }
          } else if (MapUtils.isArray(clz)) {
            if (instance[key] && (parser != null || metadata.cls || MapUtils.isPrimitiveType(clz))) {
              if (parser === null) {
                res[property] = instance[key].map(item => MapUtils.serialize(item));
              } else {
                res[property] = instance[key].map(item => parser.jsonify(item));
              }
            } else {
              res[property] = null;
            }
          } else {
            if (parser === null) {
              res[property] = MapUtils.serialize(instance[key]);
            } else {
              // deserialize the object which is in Key
              res[property] = parser.jsonify(instance[key]);
            }
          }
        }
      }
    });
    return res;
  }
}
