import { ITenantConfigs } from '../../environments/itenants.config';

import { certassurUiConfiguration } from './certassur.ui.config';
import { febelfinAcademyUiConfiguration } from './febelfinacademy.ui.config';

/**
 * This file is intended to use as environment specificly to build. Tokens are being replaced by the build definition
 */
export const multitenancy: ITenantConfigs = {
    '#{ngapp.fa.approothost}#': {
        title: '#{ngapp.fa.title}#',
        favIcon: 'favicon-fa.ico',
        logoPath: 'assets/images/logo-fa.svg',
        instrumentationKey: '#{ngapp.fa.instrumentationkey}#',
        profileCompletionTreshold: '#{ngapp.fa.profilecompletiontreshold}#',
        disableSignalR: false,
        globalCss: 'global-fa.css',
        appCss: 'app-fa.css',
        gtmCode: '#{ngapp.fa.gtmcode}#',
        poapiroot: '#{ngapp.fa.poapiroot}#',
        signalrroot: '#{ngapp.fa.signalrroot}#',
        mediaapiroot: '#{ngapp.fa.mediaapiroot}#',
        qaroot: '#{ngapp.fa.qaroot}#',
        approot: '#{ngapp.fa.approot}#',
        fetchFaqExternalLinks: '#{ngapp.fa.fetchfaqexternallinks}#',
        authConfig: {
            identityroot: '#{ngapp.fa.identityroot}#',
            identityLogoutUrl: '/account/logout',
            identityItsmeUrl: 'account/itsme',
            isItsmeEnabled: '#{ngapp.fa.authcnfig.isitsmeenabled}#'.toLowerCase() === 'true',
            clientId: '#{ngapp.fa.authconfig.clientid}#',
            scopes: '#{ngapp.fa.authconfig.scopes}#',
        },
        uiConfig: febelfinAcademyUiConfiguration,
        isM19MailingEnabled: true,
        isM20MailingEnabled: true,
        searchTrainingFromExternalLink: false,
    },
    '#{ngapp.ca.approothost}#': {
        title: '#{ngapp.ca.title}#',
        favIcon: 'favicon-ca.ico',
        logoPath: 'assets/images/logo-ca-full.png',
        instrumentationKey: '#{ngapp.ca.instrumentationkey}#',
        profileCompletionTreshold: '#{ngapp.ca.profilecompletiontreshold}#',
        disableSignalR: false,
        globalCss: 'global-ca.css',
        appCss: 'app-ca.css',
        gtmCode: '#{ngapp.ca.gtmcode}#',
        poapiroot: '#{ngapp.ca.poapiroot}#',
        signalrroot: '#{ngapp.ca.signalrroot}#',
        mediaapiroot: '#{ngapp.ca.mediaapiroot}#',
        qaroot: '#{ngapp.ca.qaroot}#',
        approot: '#{ngapp.ca.approot}#',
        fetchFaqExternalLinks: '#{ngapp.ca.fetchfaqexternallinks}#',
        authConfig: {
            identityroot: '#{ngapp.ca.identityroot}#',
            identityLogoutUrl: '/account/logout',
            identityItsmeUrl: 'account/itsme',
            isItsmeEnabled: '#{ngapp.ca.authcnfig.isitsmeenabled}#'.toLowerCase() === 'true',
            clientId: '#{ngapp.ca.authconfig.clientid}#',
            scopes: '#{ngapp.ca.authconfig.scopes}#',
        },
        uiConfig: certassurUiConfiguration,
        isM19MailingEnabled: false,
        isM20MailingEnabled: false,
        searchTrainingFromExternalLink: true,
    },
};
