<section class="widget panel-suptile">
    <section *ngIf="label">
        <section class="address-header">
            <h3 class="form-label" *ngIf="translateLabel" [translate]="label"></h3>
            <h3 class="form-label" *ngIf="!translateLabel">{{ label }}</h3>
            <ng-content></ng-content>
        </section>
    </section>
    <section class="">
        <app-loader *ngIf="formGroup === undefined"></app-loader>
        <form *ngIf="!hideForm && model && formGroup" [formGroup]="formGroup">
            <section class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ 'common.address.street' | translate }}
                </label>
                <section class="col-sm-9">
                    <input type="text" class="form-control" pInputText [readonly]="readOnly" formControlName="{{ getFormControlName('street') }}" />
                    <app-validationmessages *ngIf="formGroup" [control]="getFormControl('street')"></app-validationmessages>
                </section>
            </section>
            <section class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ 'common.address.number' | translate }}
                </label>
                <section class="col-sm-9">
                    <input type="text" class="form-control" pInputText [readonly]="readOnly" formControlName="{{ getFormControlName('number') }}" />
                    <app-validationmessages *ngIf="formGroup" [control]="getFormControl('number')"></app-validationmessages>
                </section>
            </section>
            <section class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ 'common.address.box' | translate }}
                </label>
                <section class="col-sm-9">
                    <input type="text" class="form-control" pInputText formControlName="{{ getFormControlName('box') }}" />
                </section>
            </section>
            <section class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ 'common.address.postalcode' | translate }}
                </label>
                <section class="col-sm-9">
                    <input
                        type="text"
                        class="form-control"
                        pInputText
                        [readonly]="readOnly"
                        formControlName="{{ getFormControlName('postalCode') }}"
                    />
                    <app-validationmessages *ngIf="formGroup" [control]="getFormControl('postalCode')"></app-validationmessages>
                </section>
            </section>
            <section class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ 'common.address.city' | translate }}
                </label>
                <section class="col-sm-9">
                    <input type="text" class="form-control" pInputText [readonly]="readOnly" formControlName="{{ getFormControlName('city') }}" />
                    <app-validationmessages *ngIf="formGroup" [control]="getFormControl('city')"></app-validationmessages>
                </section>
            </section>
            <section class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ 'common.address.country' | translate }}
                </label>
                <section class="col-sm-9">
                    <app-dropdown
                        [items]="countries"
                        translateMessage="true"
                        [selected]="selectedCountry"
                        displaySelector="name"
                        (onChange)="setCountry($event)"
                        [isDisabled]="readOnly"
                    ></app-dropdown>
                </section>
            </section>
        </form>
    </section>
</section>
