import { JsonProperty } from '../../../common/parsing/json-property.decorator';

export class Address {
  @JsonProperty() public street: string = undefined;
  @JsonProperty() public number: string = undefined;
  @JsonProperty() public box: string = undefined;
  @JsonProperty() public postalCode: string = undefined;
  @JsonProperty() public city: string = undefined;
  @JsonProperty() public country: string = undefined;

  static empty(): Address {
    const newItem = new Address();
    newItem.street = newItem.number = newItem.box = newItem.postalCode = newItem.city = newItem.country = '';
    return newItem;
  }

  public toString(): string {
    let addressString = '';
    if (this.street !== null) {
      addressString = `${this.street}`;
    }
    if (this.number !== null) {
      addressString = `${addressString} ${this.number}`;
    }
    if (this.box !== null) {
      addressString = `${addressString} ${this.box}`;
    }
    if (this.postalCode !== null) {
      addressString = `${addressString} ${this.postalCode}`;
    }
    if (this.city !== null) {
      addressString = `${addressString} ${this.city}`;
    }

    return addressString;
  }

  static clone(address: Address): Address {
    const newItem = new Address();
    newItem.street = address.street;
    newItem.number = address.number;
    newItem.box = address.box;
    newItem.postalCode = address.postalCode;
    newItem.city = address.city;
    newItem.country = address.country;
    return newItem;
  }
}
