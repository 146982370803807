import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAuthState } from './auth.state';


export const getAuthState = createFeatureSelector<IAuthState>('auth');

export const authSelectors = {
  currentUserSelector: createSelector(getAuthState, (state: IAuthState) => state.currentUser),
  isAuthenticatedSelector: createSelector(getAuthState, (state: IAuthState) => state.isAuthenticated),
  impersonatteState: createSelector(getAuthState, (state: IAuthState) => state.impersonatingState),
  isImpersonating: createSelector(getAuthState, (state: IAuthState) => state.impersonatingUser !== null),
  currentCompanyId: createSelector(getAuthState, (state: IAuthState) => state.currentCompanyId)
};
