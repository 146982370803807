export interface Translation {
    code: string;
    displayName: string;
}

export const defaultTranslation: Translation = {
    code: 'en-GB',
    displayName: 'English',
};

export const defaultTranslatedTranslation: Translation = {
    code: 'en-GB',
    displayName: 'common.culture.en-gb',
};

export const defaultShortTranslation: Translation = {
    code: 'en-GB',
    displayName: 'EN',
};
