import * as moment from 'moment';

import { sessionConstants } from '../../core/constants/session.constants';
import { IResource } from '../../core/models/iresource.model';
import { NamedResource } from '../../core/models/namedresource.model';
import { DateTimeParser } from '../../parsing/datetime.parser';
import { JsonProperty, SimpleProperty } from '../../parsing/json-property.decorator';
import { SessionLocationReadModel } from './location.model';

export abstract class SessionReadModel implements IResource {
  @JsonProperty()
  public id: number = undefined;
  @JsonProperty()
  public name: string = undefined;
  @JsonProperty(null, null, () => new DateTimeParser())
  public startTime: moment.Moment = undefined;
  @JsonProperty(null, null, () => new DateTimeParser())
  public endTime: moment.Moment = undefined;
  @JsonProperty(null, NamedResource)
  public teachers: NamedResource[] = undefined;
  @JsonProperty(null, NamedResource)
  public examiners: NamedResource[] = undefined;
  @JsonProperty(null, NamedResource)
  public invoiceReference: NamedResource = undefined;
  @JsonProperty(null, SessionLocationReadModel)
  public location: SessionLocationReadModel = undefined;
  @JsonProperty(null, SessionLocationReadModel)
  public examLocation: SessionLocationReadModel = undefined;

  @SimpleProperty()
  public hasAttendances: boolean = undefined;
  @SimpleProperty()
  public isLunchIncluded: boolean = undefined;

  @SimpleProperty()
  public elanPlusCapacity: number = undefined;
  @SimpleProperty()
  public faCapacity: number = undefined;

  @SimpleProperty()
  public availability: number = undefined;
  @SimpleProperty()
  public companyId?: number = undefined;
  @SimpleProperty()
  public isProgramSession: boolean = undefined;
  public sessionType: string = undefined;

  @SimpleProperty()
  public comments: string = undefined;
  @SimpleProperty()
  public webinarUrl: string = undefined;

  public toString(): string {
    if (this.startTime.dayOfYear() === this.endTime.dayOfYear()) {
      return `${this.startTime.local().format('LLLL')} - ${this.endTime.local().format('LT')}`;
    }
    return `${this.startTime.local().format('LLLL')} - ${this.endTime.local().format('LLLL')}`;
  }

  public setTimeFrame(date: moment.Moment) {
    this.startTime = moment(date)
      .hour(sessionConstants.defaultStartTimeHour)
      .minutes(sessionConstants.defaultStartTimeMinutes);
    this.endTime = moment(this.startTime).add(sessionConstants.defaultIntervalInHours, 'hours');
  }
}
