export enum MessageType {
    Toast,
    Notification,
    ToastNotification
}

export enum MessageLevel {
    Info,
    Success,
    Warn,
    Danger
}

export enum MessageDuration {
    Short = 1500,
    Medium = 2500,
    Long = 5000,
    ExtraLong = 7500,
    Fixed = -1
}

export interface IMessage {
    id?: number;
    creationTimestamp?: number;
    title: string;
    iconClass?: string;
    translateTitle?: boolean;
    content?: string;
    translateContent?: boolean;
    messageType: MessageType;
    messageLevel: MessageLevel;
    dismissable?: boolean;
    isDismissed?: boolean;
    messageDuration: MessageDuration;
    dismissMessage?: () => Promise<boolean>;
    onDismiss?: () => void;
    beforeDismiss?: () => Promise<boolean>;
    origin?: string;
    translationParameters?: { [key: string]: string };
    isTask?: boolean;
    hasAttachment?: boolean;
}

export class Message implements IMessage {
    id?: number;
    creationTimestamp?: number;
    title: string;
    iconClass?: string;
    translateTitle?: boolean;
    content?: string;
    translateContent?: boolean;
    messageType: MessageType;
    messageLevel: MessageLevel;
    dismissable?: boolean;
    isDismissed?: boolean;
    messageDuration: MessageDuration;
    dismissMessage?: () => Promise<boolean>;
    onDismiss?: () => void;
    beforeDismiss?: () => Promise<boolean>;
    origin?: string;
    translationParameters?: { [key: string]: string };
    isTask?: boolean;
    hasAttachment?: boolean;

    constructor(title: string, type: number, level: number, duration: number,
        id?: number, timeStamp?: number, iconClass?: string, translateTitle?: boolean,
        content?: string, translateContent?: boolean, dismissable?: boolean, isDismissed?: boolean,
        origin?: string, translateParameters?: { [key: string]: string }, isTask?: boolean, hasAttachment?: boolean,
        dismissMessage?: () => Promise<boolean>, onDisMiss?: () => void, beforeDismiss?: () => Promise<boolean>) {
            this.id = id;
            this.creationTimestamp = timeStamp;
            this.title = title;
            this.iconClass = iconClass;
            this.translateTitle = translateTitle;
            this.content = content;
            this.translateContent = translateContent;
            this.messageType = <MessageType>type;
            this.messageLevel = <MessageLevel>level;
            this.dismissable = dismissable;
            this.isDismissed = isDismissed;
            this.messageDuration = <MessageDuration>duration;
            this.origin = origin;
            this.translationParameters = translateParameters;
            this.isTask = isTask;
            this.hasAttachment = hasAttachment;
            this.dismissMessage = dismissMessage;
            this.onDismiss = onDisMiss;
            this.beforeDismiss = beforeDismiss;
    };
}

export function messageEquals(m1: IMessage, m2: IMessage): boolean {
    return m1 && m2 && m1.creationTimestamp === m2.creationTimestamp;
}
