import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { InputTextModule } from 'primeng/inputtext';

import { SubscriptionCleanerService } from '../../../common/core/subscriptioncleaner.service';
import { HttpCountryServiceService } from '../../../common/httpservices/httpcountryservice.service';
import { CountryReadModel } from '../../../common/models/country.readmodel';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { Address } from './address.model';

@Component({
    selector: 'app-address-editor',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    standalone: true,
    imports: [CommonModule, DropdownComponent, TranslateModule, ReactiveFormsModule, InputTextModule],
})
export class AddressComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    model: Address;
    @Input()
    name: string;
    @Input()
    public formGroup: FormGroup;
    @Input()
    label: string;
    @Input()
    translateLabel = false;
    @Input()
    readOnly = false;
    @Input()
    hideForm = false;
    @Input()
    isRequired = false;
    @Input()
    public onSavePressed: Observable<void>;
    @Output()
    public onChange: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public onIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public countries: CountryReadModel[] = [];
    public selectedCountry: CountryReadModel;
    private subCleaner: SubscriptionCleanerService = new SubscriptionCleanerService();
    private defaultCountry = 'be';

    constructor(private formBuilder: FormBuilder, private httpcountryService: HttpCountryServiceService) {}

    ngOnInit() {
        this.loadCountries();
        this.buildForms();

        if (this.onSavePressed) {
            this.subCleaner.push(
                this.onSavePressed.subscribe((a) => {
                    if (!this.formGroup.valid) {
                        _.forEach(this.formGroup.controls, (item) => {
                            if (!item.valid) {
                                item.markAsTouched();
                                item.markAsDirty();
                            }
                        });
                        this.formGroup.markAsDirty();
                        this.formGroup.markAsTouched();
                    }
                })
            );
        }
    }

    ngOnDestroy() {
        this.subCleaner.destroy();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.formGroup && changes && changes.model && changes.model.currentValue) {
            this.formGroup.patchValue({
                [this.getFormControlName('street')]: this.model.street || '',
                [this.getFormControlName('number')]: this.model.number || '',
                [this.getFormControlName('box')]: this.model.box || '',
                [this.getFormControlName('postalCode')]: this.model.postalCode || '',
                [this.getFormControlName('city')]: this.model.city || '',
                [this.getFormControlName('country')]: this.model.country || 'be', // standard value 'be' for dropdown
            });
            this.setSelectedCountry();
        }
    }

    public getFormControlName(field: string): string {
        if (this.name) {
            return `${this.name}-${field}`;
        }
        return field;
    }

    public getFormControl(field: string): AbstractControl {
        return this.formGroup.get(this.getFormControlName(field));
    }

    public buildForms() {
        this.formGroup = !this.formGroup ? this.formBuilder.group({}) : this.formGroup;

        this.formGroup.addControl(
            this.getFormControlName('street'),
            new FormControl(this.model.street || '', this.isRequired ? Validators.required : null)
        );
        this.formGroup.addControl(
            this.getFormControlName('number'),
            new FormControl(this.model.number || '', this.isRequired ? Validators.required : null)
        );
        this.formGroup.addControl(this.getFormControlName('box'), new FormControl(this.model.box || ''));
        this.formGroup.addControl(
            this.getFormControlName('postalCode'),
            new FormControl(this.model.postalCode || '', this.isRequired ? Validators.required : null)
        );
        this.formGroup.addControl(
            this.getFormControlName('city'),
            new FormControl(this.model.city || '', this.isRequired ? Validators.required : null)
        );
        this.formGroup.addControl(
            this.getFormControlName('country'),
            new FormControl(this.model.country || this.defaultCountry, this.isRequired ? Validators.required : null)
        );

        if (this.readOnly) {
            this.formGroup.controls[this.getFormControlName('street')].disable();
            this.formGroup.controls[this.getFormControlName('number')].disable();
            this.formGroup.controls[this.getFormControlName('box')].disable();
            this.formGroup.controls[this.getFormControlName('postalCode')].disable();
            this.formGroup.controls[this.getFormControlName('city')].disable();
            this.formGroup.controls[this.getFormControlName('country')].disable();
        }
        this.subCleaner.pushNamed(
            'form_address_changed',
            this.formGroup.valueChanges.subscribe((change) => this.onFormChange(change))
        );
        this.onChange.emit();
        this.onIsValid.emit(this.formGroup.valid);
    }

    public setCountry(country: CountryReadModel) {
        this.formGroup.controls[this.getFormControlName('country')].setValue(country.code);
    }

    private setSelectedCountry() {
        if (this.model && this.model.country) {
            this.selectedCountry = this.countries.find((c) => c.code.toLowerCase() === this.model.country.toLowerCase());
        }

        if (!this.selectedCountry) {
            this.selectedCountry = this.countries.find((c) => c.code.toLowerCase() === this.defaultCountry.toLowerCase());
        }
    }

    private loadCountries() {
        this.httpcountryService.getCountries().subscribe((countries) => {
            this.countries = countries;
            this.setSelectedCountry();
        });
    }

    private onFormChange(change) {
        this.model.street = change[this.getFormControlName('street')];
        this.model.number = change[this.getFormControlName('number')];
        this.model.box = change[this.getFormControlName('box')];
        this.model.postalCode = change[this.getFormControlName('postalCode')];
        this.model.city = change[this.getFormControlName('city')];
        this.model.country = change[this.getFormControlName('country')];
        this.setSelectedCountry();
        this.onChange.emit();
        this.onIsValid.emit(this.formGroup.valid);
    }
}
