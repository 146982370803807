export interface DecomposedUri {
  uri: string;
  fragments?: string;
  queryParams?: { [key: string]: string };
}

export function extractFragment(fragment: string): { [key: string]: string } {
  const res: { [key: string]: string } = {};
  const sections = fragment.split('&');
  for (const section of sections) {
    if (!String.isNullOrWhiteSpace(section)) {
      const parts = section.split('=');
      if (parts.length === 2) {
        res[parts[0]] = parts[1];
      }
    }
  }
  return res;
}

export function decomposeUri(uri: string): DecomposedUri {
  const url = new URL(uri, 'http://ngapp/');

  return <DecomposedUri>{
    uri: url.pathname.replace('//ngapp', String.empty()),
    queryParams: extractFragment(url.search.substring(1)),
    fragments: url.hash.substring(1)
  };
}
