import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { layoutSelectors } from './../../../layout/layout.selector';
import { AppState } from './../../../redux/app.state';
import { defaultTranslation, Translation } from './../../models/translation.model';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
  private currentTranslation: Translation = defaultTranslation;

  constructor(@Inject(Store) private store: Store<AppState>) {
    this.store.select(layoutSelectors.currentLanguageSelector).subscribe(lang => (this.currentTranslation = lang));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const headers = req.headers
      .set('Accept-Language', this.currentTranslation.code) // language
      .set('Cache-Control', 'no-cache') // Cache related headers
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .set('If-Modified-Since', '0');

    const interceptedReq = req.clone({ headers: headers });
    return next.handle(interceptedReq);
  }
}
