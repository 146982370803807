import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { CurrentUserService } from './currentuser.service';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(protected currentUserService: CurrentUserService, protected router: Router) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.currentUserService.isAuthenticated) {
      this.router.navigateByUrl(`/auth/login?redirectTo=${encodeURIComponent(state.url)}`);
      return false;
    }

    // The user is authenticated but there is no userdata
    if (this.currentUserService.userId == null) {
      this.router.navigate(['/', 'auth', 'callback'], { queryParams: { redirectTo: encodeURIComponent(state.url) } });
      return false;
    }

    return true;
    // const data: Data = next.routeConfig.data;
    // const role = data ? data['permission'] : null;

    // const isAuthorized = role === null || this.currentUserService.permissions.indexOf(role) >= 0;

    // if (!isAuthorized) {
    //   this.router.navigateByUrl('/auth/forbidden');
    // }
    // return isAuthorized;
  }
}
