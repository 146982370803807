import { Action } from '@ngrx/store';

import { AuthUserModel } from '../../core/auth/authuser.model';
import { GetApplicationMenuCompletedAction } from '../../layout/layout.actions';

export const AuthActionKeys = {
  GETCURRENTUSERDATA: 'auth.currentuserdata',
  GETCURRENTUSERDATA_COMPLETED: 'auth.currentuserdata.completed',
  AFTER_GETCURRENTUSERDATA_COMPLETED: 'auth.currentuserdata.completed.after',

  SETISAUTHENTICATED: 'auth.setisauthenticated',

  STARTUSERIMPERSONATION: 'auth.impersonation.user',
  STARTUSERIMPERSONATION_COMPLETED: 'auth.impersonation.user.completed',
  STARTUSERIMPERSONATION_FAILED: 'auth.impersonation.user.failed',

  FINALIZEIMPERSONATION: 'auth.impersonation.finalize',
  FINALIZEIMPERSONATION_COMPLETED: 'auth.impersonation.finalize.completed',

  SETCURRENTCOMPANYACTION: 'auth.setcurrentcompany',

  setimpersonationstate: 'auth.impersonation.setstate'
};

export class GetCurrentUserDataAction implements Action {
  readonly type = AuthActionKeys.GETCURRENTUSERDATA;
  constructor(public silent = false) {}
}

export class GetCurrentUserDataCompletedAction implements Action {
  readonly type = AuthActionKeys.GETCURRENTUSERDATA_COMPLETED;
  constructor(public user: AuthUserModel, public silent = false) {}
}

export class AfterGetCurrentUserDataCompletedAction implements Action {
  readonly type = AuthActionKeys.AFTER_GETCURRENTUSERDATA_COMPLETED;
  constructor(public user: AuthUserModel) {}
}

export class SetIsAuthenticatedAction implements Action {
  readonly type = AuthActionKeys.SETISAUTHENTICATED;
  constructor(public isAuthenticated?: boolean) {}
}

export class UserImpersonationAction implements Action {
  readonly type = AuthActionKeys.STARTUSERIMPERSONATION;
  constructor(public userId: number) {}
}

export class UserImpersonationCompletedAction implements Action {
  readonly type = AuthActionKeys.STARTUSERIMPERSONATION_COMPLETED;
  constructor(public userId: number) {}
}

export class UserImpersonationFailedAction implements Action {
  readonly type = AuthActionKeys.STARTUSERIMPERSONATION_FAILED;
  constructor(public reason: string) {}
}

export class SetCurrentCompanyAction implements Action {
  readonly type = AuthActionKeys.SETCURRENTCOMPANYACTION;
  constructor(public currentCompanyId: number) {}
}

export enum ImpersonationStates {
  Disabled,
  Validation,
  GetCurrentUser,
  GetMenuItems,
  Finalize,
  UserNotFound,
  NotAllowed
}

export class SetImpersonationStateAction implements Action {
  readonly type = AuthActionKeys.setimpersonationstate;
  constructor(public state: ImpersonationStates) {}
}

export class FinalizeImpersonationAction implements Action {
  readonly type = AuthActionKeys.FINALIZEIMPERSONATION;
  constructor() {}
}

export class FinalizeImpersonationActionCompleted implements Action {
  readonly type = AuthActionKeys.FINALIZEIMPERSONATION_COMPLETED;
  constructor() {}
}

export type AuthActions =
  | GetCurrentUserDataAction
  | GetApplicationMenuCompletedAction
  | SetIsAuthenticatedAction
  | UserImpersonationAction
  | UserImpersonationCompletedAction
  | UserImpersonationFailedAction
  | SetImpersonationStateAction
  | FinalizeImpersonationAction
  | FinalizeImpersonationActionCompleted
  | SetCurrentCompanyAction;
