import { NamedResource } from '../../core/models/namedresource.model';
import { SimpleProperty } from '../../parsing/json-property.decorator';

export class LocationNamedResource extends NamedResource {
    public capacity: number;
    public companyId: number;
    constructor(id: number, name: string, capacity?: number, companyId?: number) {
        super(id, name);
        this.capacity = capacity;
        this.companyId = companyId;
    }
    public toString(): string {
        return `${this.name} (${this.capacity})`;
    }
}

export class SessionLocationReadModel {
    @SimpleProperty()
    public id: number = undefined;
    @SimpleProperty()
    public name: string = undefined;
    @SimpleProperty()
    public roomName: string = undefined;
    @SimpleProperty()
    public street: string = undefined;
    @SimpleProperty()
    public postalCode: string = undefined;
    @SimpleProperty()
    public city: string = undefined;
    @SimpleProperty()
    public companyId: number = undefined;
    @SimpleProperty()
    public capacity: number = undefined;
    @SimpleProperty()
    public isOnline: boolean = undefined;
    @SimpleProperty()
    public fullName: string = undefined;

    public toString(): string {
        return `${this.fullName} (${this.capacity})`;
    }
}
