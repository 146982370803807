import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { moduleTranslateIdentifiers } from '../constants/translate.constants';
import { UriBuilder } from '../http/uri.builder';
import { ShortLanguages } from '../constants/languages';
import { Translation } from '../models/translation.model';
import { getPaths } from './httptranslate.helper';

@Injectable()
export class HttpTranslateLoader implements TranslateLoader {
    private _paths: string[] = null;
    private get paths(): string[] {
        if (this._paths == null) {
            this._paths = getPaths(moduleTranslateIdentifiers);
        }
        return this._paths;
    }

    constructor(private httpClient: HttpClient, private tenantConfigurationFactory: TenantConfigurationFactory) {}

    getTranslation(lang: string): Observable<any> {
        const uri = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('translations');

        return this.httpClient
            .get<any>(uri.clone().withPath('translations').withPath(lang).build(), { headers: { 'app-long-request': 'true' } })
            .pipe(
                rxoperators.mergeMap((translations) => {
                    // Load PrimeNG translations from JSON
                    return this.loadPrimeNGTranslations(lang).pipe(
                        rxoperators.map((primengTranslations) => {
                            // Merge application and PrimeNG translations
                            return { ...translations, ...primengTranslations };
                        })
                    );
                }),
                rxoperators.catchError((err) => of({})),
                rxoperators.map((data) => Object.assign({}, data))
            );
    }
    private loadPrimeNGTranslations(lang: string): Observable<any> {
        const fileName = _.find(ShortLanguages, (item: Translation) => item.code === lang).displayName.toLowerCase();
        return this.httpClient.get(`assets/i18n/primeng/${fileName}.json`).pipe(rxoperators.catchError((err) => of({})));
    }
}
