import { Component, OnInit, TemplateRef } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
    selector: 'app-grid-editor-template',
    template: `<ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="context"></ng-container>`,
})
export class GridEditorTemplateComponent implements OnInit {
    constructor() {}

    public template: TemplateRef<any>;
    public context: any = {};

    ngOnInit() {}

    // gets called once before the renderer is used
    agInit(params: ICellEditorParams) {
        this.setTemplateAndParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellEditorParams) {
        this.setTemplateAndParams(params);
    }
    getValue(): any {
        return this.context;
    }

    setTemplateAndParams(params) {
        this.template = params['ngTemplate'];
        this.context = {
            rowInfo: {
                rowData: params.data,
                rowId: params.node.id,
            },
        };
    }
}
