import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';

import { ConfigurationEntryModel } from '../../components/admin/configuration/models/configurationentry.model';
import { HttpService } from '../core/http/http.service';
import { UriBuilder } from '../core/http/uri.builder';






@Injectable()
export class HttpFooterService {
  private _uri: UriBuilder;

  protected get baseUri(): UriBuilder {
    if (!this._uri) {
      this._uri = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('footer');
    }
    return this._uri.clone();
  }

  constructor(private httpService: HttpService,
    private tenantConfigurationFactory: TenantConfigurationFactory) { }

  public getFooter(): Observable<ConfigurationEntryModel[]> {
    return this.httpService.getAll<ConfigurationEntryModel>(this.baseUri.withPath('menu').build(), null, true);
  }

  public getPrivacyPolicy(): Observable<ConfigurationEntryModel> {
    return this.httpService.get<ConfigurationEntryModel>(this.baseUri.withPath('privacypolicy').build(), null, true);
  }
}
