import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ActiveRole, defaultActiveRole } from '../models/activerole.model';
import { Roles } from '../constants/roles';

@Injectable()
export class ActiveRoleService {
    private _activeRole$: BehaviorSubject<ActiveRole> = new BehaviorSubject<ActiveRole>(defaultActiveRole);
    readonly activeRole$ = this._activeRole$.asObservable();
    public get onRoleUpdated(): Observable<ActiveRole> {
        return this._activeRole$.asObservable();
    }

    _invokeRoleUpdated(role: ActiveRole) {
        this._activeRole$.next(role);
    }
}
