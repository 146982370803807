import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppState } from '../../redux/app.state';
import { NotificationCreatedAction } from '../../redux/notification.actions';
import { ImpersonationService } from '../auth/impersonation.service';
import { HttpService } from '../http/http.service';
import { UriBuilder } from '../http/uri.builder';
import { IMessage, Message } from '../models/message.model';
import { MessageService } from './message.service';
import { SignalRService } from './signalr.service';

@Injectable()
export class HttpNotificationService {
    private _uri: UriBuilder;

    protected get baseUri(): UriBuilder {
        if (!this._uri) {
            this._uri = new UriBuilder(this.tenantConigurationFactory.getTenantConfig()).withPoApiRoot().withPath('notifications');
        }
        return this._uri.clone();
    }

    constructor(
        private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory,
        private tenantConigurationFactory: TenantConfigurationFactory,
        private signalR: SignalRService,
        private appStore: Store<AppState>,
        private authService: OAuthService,
        private messageService: MessageService,
        private impersonationService: ImpersonationService
    ) {}

    // Registers all Notification related Hub connections
    registerSignalR() {
        if (this.tenantConigurationFactory.getTenantConfig().disableSignalR) {
            return;
        }
        this.signalR.connection.subscribe((connection) => {
            connection.listenFor('OnNotificationCreated').subscribe((notification: IMessage) => {
                console.log(`Received Serverside notification @ ${new Date().toTimeString()}`, notification);
                this.appStore.dispatch(new NotificationCreatedAction(notification));
            });
        });
    }

    getAll(): Observable<IMessage[]> {
        return this.httpService.getAll<IMessage>(this.baseUri.build(), null, false).pipe(
            map((messages) => {
                return messages.map((message) => {
                    const msg = new Message(
                        message.title,
                        message.messageType,
                        message.messageLevel,
                        message.messageDuration,
                        message.id,
                        message.creationTimestamp,
                        message.iconClass,
                        message.translateTitle,
                        message.content,
                        message.translateContent,
                        true,
                        message.isDismissed,
                        'serverside',
                        message.translationParameters,
                        false,
                        message.hasAttachment,
                        message.dismissMessage,
                        message.onDismiss,
                        message.beforeDismiss
                    );
                    if (msg.id) {
                        msg.beforeDismiss = () => {
                            return new Promise((onResolve, onReject) => {
                                this.dismiss(msg.id).subscribe((res) => {
                                    onResolve(res);
                                    msg.isDismissed = true;
                                    this.messageService.dismiss(msg);
                                });
                            });
                        };
                    }
                    return msg;
                });
            })
        );
    }

    dismiss(id: number): Observable<boolean> {
        return this.httpService.custom('PUT', this.baseUri.withId(id).build()).pipe(
            map((resp) => resp.status === 204),
            catchError((resp) => of(false))
        );
    }

    public downloadAttachment(message: IMessage, isLongRequest: boolean = true) {
        const uri = this.baseUri.withId(message.id).withPath('download').build();

        const accessToken = this.authService.getAccessToken();

        const link = document.createElement('a');
        link.href = `${uri}?bearer=${accessToken}`;

        if (this.impersonationService.isImpersonating && this.impersonationService.impersonatingUserId != null) {
            link.href += `&${this.impersonationService.impersonateQueryString}=${this.impersonationService.impersonatingUserId}`;
        }

        link.download = message.content;
        let event: any;

        if (document.createEvent) {
            // Only for IE and Firefox
            event = document.createEvent('MouseEvent');
            event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        } else {
            // For Chrome
            event = new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window });
        }
        link.dispatchEvent(event);
        link.remove();
    }
}
