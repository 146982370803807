import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Makes a Http GET request to a certain URI for a Certain type T
     * @param uri The URI to make the request to
     * @param headers [OPTIONAL] additional headers to append.
     */
    public get<T>(uri: string, headers?: { key: string; value: string }[], isLongRequest: boolean = false): Observable<T> {
        const hdrs = this.getHeaders(headers, isLongRequest);
        return this.httpClient.get<T>(uri, { headers: hdrs });
    }

    /**
     * Makes a Http GET request to a certain URI for a Certain type T[]
     * @param uri The URI to make the request to
     * @param headers [OPTIONAL] additional headers to append.
     */
    public getAll<T>(uri: string, headers?: { key: string; value: string }[], isLongRequest: boolean = false): Observable<T[]> {
        return this.get<T[]>(uri, headers, isLongRequest);
    }

    /**
     * Makes a HTTP Get Request with paged and filtered options.
     * @param uri The URI to make the request to
     * @param filterParam The page and filter options. It will parse it
     * @param headers [OPTIONAL] additional headers to append.
     */
    public getPagedFiltered<TModel, TFilterParam>(
        uri: string,
        filterParam: TFilterParam,
        headers?: { key: string; value: string }[],
        isLongRequest: boolean = false
    ): Observable<TModel> {
        let queryParam = new HttpParams();
        _.forEach(Object.keys(filterParam), (key) => {
            if (filterParam[key] != null) {
                const isString = typeof filterParam[key] === 'string';
                const isArray = filterParam[key].constructor === Array;
                if (isArray) {
                    for (const param of filterParam[key]) {
                        if (param) {
                            queryParam = queryParam.append(key, param.toString());
                        }
                    }
                } else if (!isString || (isString && !String.isNullOrWhiteSpace(filterParam[key]))) {
                    queryParam = queryParam.append(key, filterParam[key].toString());
                }
            }
        });

        return this.get<TModel>(`${uri}?${queryParam.toString()}`, headers, isLongRequest);
    }

    /**
     * Creates an entity using a certain path
     * @param model The model to Create
     * @param uri The URI to make the request to
     * @param headers [OPTIONAL] additional headers to append.
     */
    public create<Tin, Tout>(model: Tin, uri: string, headers?: { key: string; value: string }[], isLongRequest: boolean = false): Observable<Tout> {
        const hdrs = this.getHeaders(headers, isLongRequest);
        return this.httpClient.post<Tout>(uri, model, { headers: hdrs });
    }

    /**
     * Updates an entity using a certain path
     * @param model The model to Update
     * @param uri The URI to make the request to
     * @param headers [OPTIONAL] additional headers to append.
     */
    public update<Tin, Tout>(model: Tin, uri: string, headers?: { key: string; value: string }[], isLongRequest: boolean = false): Observable<Tout> {
        const hdrs = this.getHeaders(headers, isLongRequest);
        return this.httpClient.put<Tout>(uri, model, { headers: hdrs });
    }

    /**
     * Deletes an entity using a certain path
     * Note: we thrust that the api gives a NoContent and that the nocontent interceptor converts the body to a boolean
     * @param uri The URI to make the request to
     * @param headers [OPTIONAL] additional headers to append.
     */
    public delete(uri: string, headers?: { key: string; value: string }[], isLongRequest: boolean = false): Observable<boolean> {
        const hdrs = this.getHeaders(headers, isLongRequest);
        return new Observable((observer) => {
            this.httpClient.delete<boolean>(uri, { headers: hdrs }).subscribe(
                (success) => {
                    observer.next(success);
                },
                (error) => {
                    observer.error(error);
                }
            );
        });
    }

    public downloadBlob(uri: string, mimeType: string, isLongRequest: boolean = false): Observable<any> {
        const headers: {} = {
            headers: this.getHeaders([{ key: 'Content-Type', value: 'application/json' }], isLongRequest),
            responseType: 'blob',
        };

        return this.httpClient.get(uri, headers).pipe(
            map((res: { body: Blob; headers: Headers; status: number }) => {
                return new Blob([res.body], { type: mimeType });
            })
        );
    }

    public downloadFile(uri: string, isLongRequest: boolean = false): Observable<boolean> {
        const headers: {} = {
            headers: this.getHeaders([{ key: 'Content-Type', value: 'application/json' }], isLongRequest),
            responseType: 'blob',
            observe: 'response',
        };
        return this.httpClient.get(uri, headers).pipe(
            catchError((resp) => of(null)),
            map((res: { body: Blob; headers: Headers; status: number }) => {
                if (!res || res.status >= 204 || res.status < 200) {
                    return false;
                }
                const blob = new Blob([res.body as Blob], { type: res.headers.get('Content-Type') });
                const contentDisposition = res.headers.get('Content-Disposition');
                const regex = new RegExp('filename=(.*)');
                let fileName = regex.exec(contentDisposition)[1];
                fileName = decodeURI(fileName.replace(/"/g, ''));
                FileSaver.saveAs(blob, fileName);
                return true;
            })
        );
    }
    public custom<T>(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        uri: string,
        body?: any,
        headers?: { key: string; value: string }[],
        isLongRequest: boolean = false
    ): Observable<HttpResponse<T>> {
        const hdrs = this.getHeaders(headers, isLongRequest);
        return this.httpClient.request<T>(method, uri, {
            body: body,
            headers: hdrs,
            observe: 'response',
        });
    }
    private getHeaders(headers: { key: string; value: string }[], isLongRequest: boolean = false): HttpHeaders {
        let hdrs = new HttpHeaders();
        _.forEach(headers, (hdr) => {
            hdrs = hdrs.append(hdr.key, hdr.value);
        });
        if (isLongRequest) {
            hdrs = hdrs.append('app-long-request', 'true');
        }
        return hdrs;
    }
}
