import { Injectable } from '@angular/core';
import { BlobService } from 'angular-azure-blob-service';
import { FileUploader, FileUploaderOptions, Headers } from 'ng2-file-upload';

import { ImpersonationService } from '../auth/impersonation.service';

@Injectable()
export class CreateFileUploaderService {
    constructor(
        private blobService: BlobService,
        private impersonationService: ImpersonationService) { }

    public createInstance(options: FileUploaderOptions) {
        let headers = null;
        if (this.impersonationService.isImpersonating && this.impersonationService.impersonatingUserId !== null) {
            headers = [<Headers>{
                name: this.impersonationService.impersonateHeaderName,
                value: this.impersonationService.impersonatingUserId.toString()
            }];
        }
        return new FileUploader({ ...options, headers: headers });
    }
}
