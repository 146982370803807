import { Injectable, Optional } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';

import { environment } from '../../../../environments/environment';
import { AppInsightConfig } from '../models/appinsight.config';

@Injectable({ providedIn: 'root' })
export class AppInsightService {
    context: Microsoft.ApplicationInsights.ITelemetryContext;
    config: AppInsightConfig;

    constructor(@Optional() _config: AppInsightConfig, public router: Router) {
        this.config = _config;
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackevent
    trackEvent(eventName: string, eventProperties?: { [name: string]: string }, metricProperty?: { [name: string]: number }) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.trackEvent(eventName, eventProperties, metricProperty);
        } catch (ex) {
            this.onError('trackEvent', ex);
        }
    }

    startTrackEvent(name: string): any {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.startTrackEvent(name);
        } catch (ex) {
            this.onError('startTrackEvent', ex);
        }
    }

    stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.stopTrackEvent(name, properties, measurements);
        } catch (ex) {
            this.onError('stopTrackEvent', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackpageview
    trackPageView(
        name?: string,
        url?: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number },
        duration?: number
    ) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.trackPageView(name, url, properties, measurements, duration);
        } catch (ex) {
            this.onError('trackPageView', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#starttrackpage
    startTrackPage(name?: string) {
        if (this.isLocalEnv()) {
            return;
        }

        try {
            AppInsights.startTrackPage(name);
        } catch (ex) {
            this.onError('startTrackPage', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#stoptrackpage
    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.stopTrackPage(name, url, properties, measurements);
        } catch (ex) {
            this.onError('stopTrackPage', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackmetric
    trackMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number, properties?: { [name: string]: string }) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.trackMetric(name, average, sampleCount, min, max, properties);
        } catch (ex) {
            this.onError('trackMetric', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackexception
    trackException(
        exception: Error,
        handledAt?: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number },
        severityLevel?: AI.SeverityLevel
    ) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.trackException(exception, handledAt, properties, measurements, severityLevel);
        } catch (ex) {
            this.onError('trackException', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#tracktrace
    // trackTrace(message: string, properties?: {[string]:string}, severityLevel?: AI.SeverityLevel)
    // Log a diagnostic event such as entering or leaving a method.
    trackTrace(message: string, properties?: { [name: string]: string }, severityLevel?: AI.SeverityLevel) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.trackTrace(message, properties, severityLevel);
        } catch (ex) {
            this.onError('trackTrace', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackdependency
    // trackDependency(id: string, method: string, absoluteUrl: string, pathName: string, totalTime: number, success: boolean, resultCode: number)
    // Log a dependency call (for instance: ajax)
    trackDependency(id: string, method: string, absoluteUrl: string, pathName: string, totalTime: number, success: boolean, resultCode: number) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.trackDependency(id, method, absoluteUrl, pathName, totalTime, success, resultCode);
        } catch (ex) {
            this.onError('trackDependency', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#flush

    flush() {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.flush();
        } catch (ex) {
            this.onError('flush', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
    setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie: boolean = false) {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
        } catch (ex) {
            this.onError('setAuthenticatedUserContext', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#clearauthenticatedusercontext
    clearAuthenticatedUserContext() {
        if (this.isLocalEnv()) {
            return;
        }
        try {
            AppInsights.clearAuthenticatedUserContext();
        } catch (ex) {
            this.onError('clearAuthenticatedUserContext', ex);
        }
    }

    public setup(): void {
        if (this.config && this.config.instrumentationKey) {
            if (this.isLocalEnv()) {
                console.warn('Appinsights is disabled cause current build is a local environment');
                return; // we dont want to setup
            }
            try {
                AppInsights.downloadAndSetup(this.config);
                setTimeout(() => {
                    AppInsights.context.addTelemetryInitializer((envelope) => {
                        const appVer = envelope.appVer || envelope.tags['ai.application.ver'];
                        return appVer !== 'local';
                    });
                    AppInsights.context.addTelemetryInitializer((envelope) => {
                        envelope.tags['TelemetryOriginType'] = 'poapp';
                        // mark this telemetry item with the TelemetryOriginType so we can find the Origin of the item
                    });
                    if (this.config.trackPageEvents) {
                        this.router.events.subscribe((event) => {
                            if (event instanceof NavigationStart) {
                                this.startTrackPage(event.url);
                            } else if (event instanceof NavigationEnd) {
                                this.stopTrackPage(event.url);
                            }
                        });
                    }
                    AppInsights.context.application.ver = environment.appversion;
                    this.context = AppInsights.context;
                }, 1500);
            } catch (ex) {
                this.onError('setup', ex);
            }
        } else {
            console.warn('Appinsight has to be setup using the forRoot registration!');
        }
    }

    private onError(issueSource: string, ex: Error) {
        console.error(`An error occured for [AppinsightService/${issueSource}`, ex);
    }

    private isLocalEnv(): boolean {
        return this.config.instrumentationKey === 'LOCAL-DEV-ENV';
    }
}
