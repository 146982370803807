import { CommonModule } from '@angular/common';
import { Injectable, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';

import { AppInsightConfig } from './models/appinsight.config';
import { AppInsightService } from './services/appinsight.service';

@NgModule({
    imports: [CommonModule],
    providers: [AppInsightService, AppInsightService],
})
@Injectable({ providedIn: 'root' })
export class AppinsightModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: AppinsightModule,
        appinsightService: AppInsightService
    ) {
        if (!parentModule) {
            appinsightService.setup();
        }
    }

    static forRoot(monitorRouting: boolean = true): ModuleWithProviders<AppinsightModule> {
        return {
            ngModule: AppinsightModule,
            providers: [
                {
                    provide: AppInsightConfig,
                    useValue: <AppInsightConfig>{
                        instrumentationKey: TenantConfigurationFactory.getTenantConfigDirectly().instrumentationKey,
                        trackPageEvents: monitorRouting,
                    },
                },
                AppInsightService,
            ] /*{ provide: ErrorHandler, useClass: AppinsightErrorHandler }*/,
        };
    }
}
