import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError as observableThrowError } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

import { ShowErrorAction } from '../../../errorhandling/errorhandling.actions';
import { MessageService } from '../../services/message.service';
import { HttpExtendedErrorResponse } from '../models/httperrorresponse.model';
import { AppState } from './../../../redux/app.state';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(@Inject(Store) private appStore: Store<AppState>, private messageService: MessageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(req).pipe(
      rxoperators.catchError((err: HttpExtendedErrorResponse) => {
        const parameters = err.error ? err.error.parameters : undefined;
        let message = err.error ? err.error.message : undefined;
        if (err.status === 404 && !message) {
          message = 'common.errornotfound';
        }
        if (!message) {
          return observableThrowError(err);
        }
        this.appStore.dispatch(new ShowErrorAction(message, parameters));
        return observableThrowError(err);
      })
    );
  }
}
