import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DialogContainerService {
    private _viewContainer: ViewContainerRef;
    private dialogCount = 0;
    public get viewContainer(): ViewContainerRef {
        if (!this._viewContainer) {
            throw new Error('Dialog service was not setup! Please register your dialogcontainer before pushing dialogs.');
        }
        return this._viewContainer;
    }
    /**
     * Initial register of the container where dialogs are visualized
     * @param viewContainer the viewcontainer
     * @param injector  the injector
     */
    public register(viewContainer: ViewContainerRef, onAdded?: (count: number) => void, onDestroyed?: (count: number) => void) {
        this._viewContainer = viewContainer;
        if (onAdded) {
            this._onAdded = onAdded;
        }
        if (onDestroyed) {
            this._onDestroyed = onDestroyed;
        }
    }
    _onAdded = (count: number) => {};
    _onDestroyed = (count: number) => {};
}
