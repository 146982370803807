import { OAuthStorage } from 'angular-oauth2-oidc';

export class AuthStorageService extends OAuthStorage {
    constructor(private prefix: string, private storage: Storage) {
        super();
        if (!this.prefix.endsWith('.')) {
            this.prefix = this.prefix + '.';
        }
    }

    get length(): number {
        return this.storage.length;
    }

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string {
        return this.storage.getItem(this.prefix + key);
    }

    key(index: number): string {
        const key = this.storage.key(index);
        return key.substring(this.prefix.length);
    }

    removeItem(key: string): void {
        this.storage.removeItem(this.prefix + key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(this.prefix + key, value);
    }
}

export function createStorageFactory(): OAuthStorage {
    return new AuthStorageService('poapp', localStorage);
}
