import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { ISignalRConnection, SignalR } from 'ng2-signalr';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppInsightService } from './appinsight.service';


@Injectable()
export class SignalRService {
  private subject: Subject<ISignalRConnection>;
  public connection: Observable<ISignalRConnection>;

  constructor(private signalR: SignalR,
    private tenantConfigurationFactory: TenantConfigurationFactory,
    private oauthService: OAuthService, private appinsightService: AppInsightService) {
    this.subject = new Subject<ISignalRConnection>();
    this.connection = this.subject.asObservable().pipe(shareReplay(1));

    if (navigator.onLine) {
      this.connect();
    }
  }

  private connect() {
    if (this.tenantConfigurationFactory.getTenantConfig().disableSignalR) {
      return;
    }
    const claims = <any>this.oauthService.getIdentityClaims();

    if (!claims || !claims.sub) {
      // try again later
      this.reconnect();
      return;
    }

    this.signalR
      .connect({ qs: { user: claims.sub } })
      .then(connection => {
        this.subject.next(connection);
        connection.errors.subscribe(error => {
          if (error.message.indexOf(`Couldn't reconnect within the configured timeout`) >= 0) {
            this.appinsightService.trackException(error);
            this.reconnect();
          }
        });
      })
      .catch(x => {
        this.appinsightService.trackException(x);
        this.reconnect();
      });
  }

  private reconnect() {
    const ms = 3000;
    console.log(`Manual reconnect after ${ms}ms`);
    setTimeout(() => this.connect(), ms);
  }
}
