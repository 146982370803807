import { Action } from '@ngrx/store';

export const errorHandlingActionKeys = {
  SHOWERROR: 'errorhandling.error.show'
};

export class ShowErrorAction implements Action {
  readonly type = errorHandlingActionKeys.SHOWERROR;
  constructor(public message: string, public parameters: { [key: string]: string }) {}
}

export type ErrorHandlingActions = ShowErrorAction;
