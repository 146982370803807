export const dateFormats = {
    date: {
        short: 'dd/MM/yyyy',
        long: 'EEEE dd/MM/yyyy',
        iso: 'yyyy-MM-dd',
        time: 'HH:mm',
        timeLong: 'HH:mm:ss',
        dateTime: 'dd/MM/yyyy HH:mm',
    },
    Moment: {
        short: 'DD/MM/YYYY',
        long: 'dddd, MMMM Do YYYY',
        iso: 'YYYY-MM-DD',
        time: 'HH:mm',
        timeLong: 'HH:mm:ss',
        dateTime: 'DD/MM/YYYY HH:mm',
    },
};
