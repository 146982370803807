import { ActiveRole, defaultActiveRole, defaultShortActiveRole, defaultTranslatedActiveRole } from '../models/activerole.model';

export const Roles: ActiveRole[] = [
    defaultActiveRole,

    { code: 'AFA', displayName: 'FA admin' },
    { code: 'SU', displayName: 'Super user' },
    { code: 'QAA', displayName: 'QA admin' },
    { code: 'IA', displayName: 'Institute admin' },
    { code: 'OV', displayName: 'OV' },
    { code: 'EX', displayName: 'Examinator' },
    { code: 'TE', displayName: 'Teacher' },
    { code: 'JM', displayName: 'Jury member' },
    { code: 'CEM', displayName: 'Certificate manager' },
    { code: 'ACC', displayName: 'Accounting' },
    { code: 'FSMA', displayName: 'FSMA' },
    { code: 'REP', displayName: 'Reporting manager' },
    { code: 'ATT', displayName: 'Attendance manager' },
];

export const TranslatedRoles: ActiveRole[] = [
    defaultTranslatedActiveRole,

    { code: 'AFA', displayName: 'common.role.afa' },
    { code: 'SU', displayName: 'common.role.su' },
    { code: 'IA', displayName: 'common.role.ia' },
    { code: 'OV', displayName: 'common.role.ov' },
    { code: 'QAA', displayName: 'common.role.qaa' },
    { code: 'REP', displayName: 'common.role.rep' },
    { code: 'EX', displayName: 'common.role.ex' },
    { code: 'ACC', displayName: 'common.role.acc' },
    { code: 'ATT', displayName: 'common.role.att' },
    { code: 'TE', displayName: 'common.role.te' },
    { code: 'CEM', displayName: 'common.role.cem' },
];
