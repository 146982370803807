import { Entities } from '../../../../../common/models/cm/tree/entities.enum';
import { SimpleProperty } from '../../../../../common/parsing/json-property.decorator';

export class PlannableOccurrenceEntityReadModel {
  @SimpleProperty()
  public id: number = undefined;
  @SimpleProperty()
  public name: string = undefined;
  @SimpleProperty()
  public trajectoryEntityType: Entities = undefined;
}
