import { RouterReducerState } from '@ngrx/router-store';
import { Action } from '@ngrx/store';

import { ErrorHandlingState } from '../errorhandling/errorhandling.state';
import { LayoutState } from '../layout/layout.state';
import { RouterStateUrl } from '../routing/routerstateurl.model';
import { IAuthState } from './auth/auth.state';

export const FinalizeActionKey = 'Global.FinalizeAction';
export class FinalizeAction implements Action {
  type: string = FinalizeActionKey;
}

export interface AppState {
  layout: LayoutState;
  auth: IAuthState;
  routerReducer: RouterReducerState<RouterStateUrl>;
  errorHandling: ErrorHandlingState;
}
