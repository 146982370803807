import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

export interface NamedSubscription {
  registeredName: string;
  subscription: Subscription;
}

/**
 * Manages Subscription lifecycle.
 * Usage:
 * Add this service on a component-provider list, and push subscriptions,
 * which can be destroyed in their OnDestroy Method to terminate
 * It is also possible to push named subscriptions, when you would like to manage the lifecycle of the subscription
 */
@Injectable()
export class SubscriptionCleanerService {
  private _subscriptions: Subscription[] = [];
  private _namedSubscription: NamedSubscription[] = [];

  /**
   * Pushes a named subscription.
   * Note, this Destroys existing subscription on that certain key, before pushing
   * @param key The Unique key to push the subscription
   * @param subscription The subscription to push
   */
  public pushNamed(key: string, subscription: Subscription) {
    if (this._hasNamedSubscription(key)) {
      this.destroyNamed(key);
    }

    this._namedSubscription.push({ registeredName: key, subscription: subscription });
  }

  /**
   * Destroys a Named subscription
   * @param key The Unique key to destroy the subscription
   */
  public destroyNamed(key: string) {
    const sub = this._getNamedSubscription(key);
    if (sub) {
      if (!sub.subscription.closed) {
        sub.subscription.unsubscribe();
      }
      this._removeNamedSubscription(key);
    }
  }

  /**
   * Pushes a set of not-named subscriptions
   * @param subscriptions the set of subscriptions to push
   */
  public push(...subscriptions: Subscription[]) {
    _.forEach(subscriptions, sub => {
      this._subscriptions.push(sub);
    });
  }

  /**
   * Permanently Destroys the whole set of subscriptions
   */
  public destroy() {
    _.forEach(this._namedSubscription, sub => {
      this.destroyNamed(sub.registeredName);
    });
    _.forEach(this._subscriptions, sub => sub.unsubscribe());
    this._subscriptions = undefined;
    this._namedSubscription = undefined;
  }

  private _hasNamedSubscription(key: string): boolean {
    return _.some(this._namedSubscription, sub => sub.registeredName && sub.registeredName === key);
  }

  private _getNamedSubscription(key: string): NamedSubscription {
    return _.find(this._namedSubscription, sub => sub.registeredName && sub.registeredName === key);
  }

  private _removeNamedSubscription(key: string): void {
    _.remove(this._namedSubscription, sub => sub.registeredName === key);
  }
}
