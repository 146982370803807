import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Message } from '../core/models/message.model';
import { HttpNotificationService } from '../core/services/httpnotifications.service';
import { HttpTaskNotificationService } from '../core/services/httptasknotifications.service';
import { MessageService } from '../core/services/message.service';
import { HttpScheduledNotificationService } from '../core/services/httpschedulednotifications.service';
import { FinalizeAction } from './app.state';
import {
    GetAllNotificationsAction,
    GetAllNotificationsCompletedAction,
    GetAllScheduledNotificationsAction,
    GetAllScheduledNotificationsCompletedAction,
    NotificationActionsKey,
    NotificationCreatedAction,
} from './notification.actions';

@Injectable({ providedIn: 'root' })
export class NotificationEffect {
    constructor(
        private actions$: Actions,
        private httpNotificationsService: HttpNotificationService,
        private httpScheduledNotificationsService: HttpScheduledNotificationService,
        private httpTaskNotificationsService: HttpTaskNotificationService,
        private messageService: MessageService
    ) {}

    getAllNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAllNotificationsAction>(NotificationActionsKey.GETALLNOTIFICATIONS),
            mergeMap(() =>
                forkJoin([this.httpNotificationsService.getAll(), this.httpTaskNotificationsService.getAll()]).pipe(
                    map(([messages, taskNotifications]) => {
                        const tmp = taskNotifications.length > 0 ? taskNotifications : [];
                        const allMessages = [...messages, ...tmp].sort((m) => m.creationTimestamp);
                        allMessages.forEach((msg) => this.messageService.push(msg));
                        return new GetAllNotificationsCompletedAction(allMessages);
                    })
                )
            )
        )
    );

    getAllScheduledNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAllScheduledNotificationsAction>(NotificationActionsKey.GETALLSCHEDULEDNOTIFICATIONSACTION),
            mergeMap(() =>
                this.httpScheduledNotificationsService.getAll().pipe(
                    map((messages) => {
                        messages.map((m) => {
                            this.messageService.push(m);
                        });
                        return new GetAllScheduledNotificationsCompletedAction(messages);
                    })
                )
            )
        )
    );

    notificationCreated: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType<NotificationCreatedAction>(NotificationActionsKey.NOTIFICATIONCREATED),
            mergeMap((action) => {
                if (action.message) {
                    const msg = new Message(
                        action.message.title,
                        action.message.messageType,
                        action.message.messageLevel,
                        action.message.messageDuration,
                        action.message.id,
                        action.message.creationTimestamp,
                        action.message.iconClass,
                        action.message.translateTitle,
                        action.message.content,
                        action.message.translateContent,
                        true,
                        action.message.isDismissed,
                        'serverside',
                        action.message.translationParameters,
                        action.message.isTask,
                        action.message.hasAttachment,
                        action.message.dismissMessage,
                        action.message.onDismiss,
                        action.message.beforeDismiss
                    );
                    if (msg.id) {
                        if (!msg.isTask) {
                            msg.beforeDismiss = () => {
                                return new Promise((onResolve, onReject) => {
                                    this.httpNotificationsService.dismiss(msg.id).subscribe((res) => {
                                        onResolve(res);
                                        msg.isDismissed = true;
                                        this.messageService.dismiss(msg);
                                    });
                                });
                            };
                        }
                    }
                    this.messageService.push(msg);
                }
                return of(new FinalizeAction());
            })
        );
    });
}
