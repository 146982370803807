import { SimpleProperty } from '@lms-common/parsing/json-property.decorator';
import { IViewModel } from '@lms-common/core/models/iviewmodel';

export class PricingMiscModel implements IViewModel {
    @SimpleProperty() public id: number = undefined;
    @SimpleProperty() public articleNumber: string = undefined;
    @SimpleProperty() public description: string = undefined;
    @SimpleProperty() public item: number = undefined;
    @SimpleProperty() public isReadOnly: boolean = undefined;
    @SimpleProperty() public validCount: number = undefined;

    constructor() {
        PricingMiscModel.provideDefaultPricings(this);
    }

    get displayText() {
        return `${this.articleNumber}:${this.description}`;
    }

    static empty(): PricingMiscModel {
        const model = new PricingMiscModel();
        model.articleNumber = String.empty();
        model.description = String.empty();
        return model;
    }

    static create(_articleNumber: string, _item: number): PricingMiscModel {
        const model = new PricingMiscModel();
        model.description = String.empty();
        model.articleNumber = _articleNumber;
        model.item = _item;
        return model;
    }

    static provideDefaultPricings(model: PricingMiscModel) {
        model.item = model.item || 0;
    }
}
