import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { environment } from 'environments/environment';
import { AuthorizationService } from '../auth/authorization.service';
import { ApplicationMenu } from '../models/applicationmenu.model';
import { CollapsableMenuItem, MenuItem, LanguageMenuItem, RoleMenuItem } from './../models/menu.model';

const menuLayout: Array<MenuItem> = [
    {
        text: 'Mijn profiel',
        translateKey: 'sidebar.nav.myprofile',
        icon: 'fa-user',
        link: ['/', 'my-profile'],
    },
    {
        text: 'Mijn leertrajecten',
        translateKey: 'sidebar.nav.mytrajectories',
        icon: 'fa-slideshare',
        link: ['/', 'dashboard'],
    },
    {
        text: 'User management',
        translateKey: 'sidebar.nav.usermanagement.title',
        icon: 'fa-users',
        permissionKey: 'UserManagement.MenuItem',
        subItems: [
            {
                text: 'Overview',
                permissionKey: 'Users.MenuItem',
                translateKey: 'sidebar.nav.usermanagement.overview',
                link: ['/', 'admin', 'users'],
            },
            {
                text: 'Merge users',
                permissionKey: 'Users.Merge.MenuItem',
                translateKey: 'sidebar.nav.usermanagement.merge',
                link: ['/', 'admin', 'users', 'merge'],
            },
            {
                text: 'UserMatching',
                translateKey: 'sidebar.nav.usermanagement.matching',
                link: ['/', 'admin', 'users', 'matching'],
                permissionKey: 'Users.Matching.MenuItem',
            },
        ],
    },
    {
        text: 'Company management',
        translateKey: 'sidebar.nav.companymanagement',
        icon: 'fa-building',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        text: 'Role management',
        translateKey: 'sidebar.nav.rolemanagement',
        icon: 'fa-suitcase',
        link: ['/', 'admin', 'roles'],
        permissionKey: 'Roles.MenuItem',
    },
    {
        text: 'QA management',
        translateKey: 'sidebar.nav.qamanagement.index',
        icon: 'fa-question-circle',
        permissionKey: 'QA.MenuItem',
        subItems: [
            {
                text: 'Questionaries',
                permissionKey: 'Questionaries.MenuItem',
                translateKey: 'sidebar.nav.qamanagement.questionaries',
                link: ['/', 'admin', 'qa', 'questionaries', 'index'],
            },
            {
                text: 'Questions',
                permissionKey: 'Questions.MenuItem',
                translateKey: 'sidebar.nav.qamanagement.questions',
                link: ['/', 'admin', 'qa', 'questions', 'index'],
            },
        ],
    },
    {
        text: 'Course management',
        translateKey: 'sidebar.nav.coursemanagement.index',
        icon: 'fa-graduation-cap',
        permissionKey: 'CourseManagement.MenuItem',
        subItems: [
            {
                text: 'Trajectories',
                translateKey: 'sidebar.nav.coursemanagement.trajectories',
                link: ['/', 'admin', 'coursemanagement'],
                permissionKey: 'Trajectories.MenuItem',
            },
            {
                text: 'Programs',
                translateKey: 'sidebar.nav.coursemanagement.programs',
                link: ['/', 'admin', 'programmanagement'],
                permissionKey: 'Programs.MenuItem',
            },
            {
                text: 'MediaItems',
                translateKey: 'sidebar.nav.coursemanagement.mediaitems',
                link: ['/', 'admin', 'mediaitems'],
                permissionKey: 'MediaItems.MenuItem',
            },
            {
                text: 'Subscriptions',
                translateKey: 'sidebar.nav.subscriptionmanagement',
                link: ['/', 'admin', 'subscriptions'],
                permissionKey: 'Subscriptions.MenuItem',
            },
        ],
    },
    {
        text: 'Pricing',
        translateKey: 'sidebar.nav.pricing',
        icon: 'fa-money',
        permissionKey: 'Prices.MenuItem',
        subItems: [
            {
                text: 'Pricing',
                permissionKey: 'Prices.MenuItem',
                translateKey: 'sidebar.nav.pricing',
                link: ['/', 'admin', 'pricing'],
            },
            {
                text: 'License management',
                permissionKey: 'Licenses.MenuItem',
                translateKey: 'sidebar.nav.licensemanagement',
                link: ['/', 'admin', 'licenses'],
            },
        ],
    },
    {
        text: 'Registrations',
        translateKey: 'sidebar.nav.registrations',
        icon: 'fa-street-view',
        link: ['/', 'registrations'],
        permissionKey: 'Registrations.MenuItem',
    },
    {
        text: 'Calendars',
        translateKey: 'sidebar.nav.calendar',
        icon: 'fa-calendar',
        permissionKey: 'Calendar.MenuItem',
        subItems: [
            {
                text: 'User Calendar',
                translateKey: 'sidebar.nav.usercalendar',
                permissionKey: 'Calendar.Personal.MenuItem',
                link: ['/', 'calendar'],
            },
            {
                text: 'OV Calendar',
                translateKey: 'sidebar.nav.ovcalendar',
                permissionKey: 'Calendar.Ov.MenuItem',
                link: ['/', 'calendar', 'ov'],
            },
            {
                text: 'Admin Calendar',
                translateKey: 'sidebar.nav.admincalendar',
                permissionKey: 'Calendar.Admin.MenuItem',
                link: ['/', 'calendar', 'admin'],
            },
        ],
    },
    {
        text: 'Course catalog',
        translateKey: 'sidebar.nav.coursecatalog',
        icon: 'fa-folder-open',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        text: 'Membership management',
        translateKey: 'sidebar.nav.membershipmanagement',
        icon: 'fa-object-ungroup',
        link: ['/', 'admin', 'membershipmanagement'],
        permissionKey: 'Memberships.MenuItem',
    },
    {
        text: 'Partnership management',
        translateKey: 'sidebar.nav.partnershipmanagement',
        icon: 'fa-slideshare',
        link: ['/', 'admin', 'partnershipmanagement'],
        permissionKey: 'Partnerships.MenuItem',
    },
    {
        text: 'Translations',
        translateKey: 'sidebar.nav.translations',
        icon: 'fa-language',
        link: ['/', 'admin', 'translations'],
        permissionKey: 'Translations.MenuItem',
    },
    {
        text: 'TestResults',
        translateKey: 'sidebar.nav.testresults',
        icon: 'fa-trophy',
        link: ['/', 'admin', 'testresults'],
        permissionKey: 'TestResults.MenuItem',
    },
    {
        text: 'Certificates',
        translateKey: 'sidebar.nav.certificates',
        icon: 'fa-certificate',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        text: 'Orders',
        translateKey: 'sidebar.nav.orders',
        icon: 'fa-list-ul',
        link: ['/', 'orders'],
        permissionKey: 'Orders.MenuItem',
    },
    {
        text: 'Invoices',
        translateKey: 'sidebar.nav.invoices.index',
        icon: 'fa-money',
        permissionKey: 'Invoices.MenuItem',
        subItems: [
            {
                text: 'Personal Invoicing',
                link: ['/', 'invoices'],
                permissionKey: 'Invoices.Personal.MenuItem',
                translateKey: 'sidebar.nav.invoices.personal',
            },
            {
                text: 'Joint Initiatives Invoicing',
                link: ['/', 'invoices', 'jointinitiatives'],
                permissionKey: 'Invoices.JointInitiatives.MenuItem',
                translateKey: 'sidebar.nav.invoices.jointinitiatives',
            },
        ],
    },
    {
        text: 'Templates',
        translateKey: 'sidebar.nav.templates',
        icon: 'fa-file-pdf-o',
        link: ['/', 'admin', 'templates'],
        permissionKey: 'Templates.MenuItem',
    },
    {
        text: 'Interests',
        translateKey: 'sidebar.nav.interests',
        icon: 'fa-lightbulb-o',
        link: ['/', 'admin', 'interests'],
        permissionKey: 'Interests.MenuItem',
    },
    {
        text: 'Reporting',
        translateKey: 'sidebar.nav.reporting',
        icon: 'fa-file-text-o',
        link: ['/', 'reporting'],
        permissionKey: 'Reporting.MenuItem',
    },
    {
        text: 'FAQ',
        translateKey: 'sidebar.nav.faq.index',
        icon: 'fa-question-circle',
        permissionKey: 'Faq.MenuItem',
        subItems: [
            {
                text: 'User FAQ',
                translateKey: 'sidebar.nav.faq.user',
                permissionKey: 'Faq.User.MenuItem',
                link: ['/', 'faq'],
            },
            {
                text: 'OV FAQ',
                translateKey: 'sidebar.nav.faq.ov',
                permissionKey: 'Faq.Ov.MenuItem',
                link: ['/', 'faq', 'ov'],
            },
            {
                text: 'Trainer FAQ',
                translateKey: 'sidebar.nav.faq.trainer',
                permissionKey: 'Faq.Trainer.MenuItem',
                link: ['/', 'faq', 'trainer'],
            },
            {
                text: 'Admin FAQ',
                translateKey: 'sidebar.nav.faq.admin',
                permissionKey: 'Faq.Admin.MenuItem',
                link: ['/', 'faq', 'admin'],
            },
        ],
    },
    {
        text: 'Configuration Module',
        translateKey: 'sidebar.nav.configuration',
        icon: 'fa-sliders',
        link: ['/', 'admin', 'configuration'],
        permissionKey: 'Configuration.MenuItem',
    },
    {
        text: 'Testing Module',
        translateKey: 'Testing Module',
        icon: 'fa-text-width',
        permissionKey: 'Testing.MenuItem',
        subItems: [
            {
                text: 'Norriq Invoicing',
                translateKey: 'Norriq Invoicing',
                link: ['/', 'testing', 'norriq'],
            },
            {
                text: 'Course management lookup',
                translateKey: 'Course management Lookup',
                link: ['/', 'testing', 'cm'],
            },
            {
                text: 'Catalog Tests',
                translateKey: 'Catalog Tests',
                link: ['/', 'testing', 'catalog'],
            },
            {
                text: 'Ingenico Feedback',
                translateKey: 'Ingenico Feedback Invoicing',
                link: ['/', 'testing', 'ingenicofeedback'],
            },
            {
                text: 'QA Test',
                translateKey: 'QA Test',
                link: ['/', 'testing', 'qa'],
            },
            {
                text: 'Scorm Test',
                translateKey: 'Scorm Test',
                link: ['/', 'testing', 'scorm'],
            },
            {
                text: 'Log',
                translateKey: 'Log',
                link: ['/', 'testing', 'log'],
            },
        ],
    },
];

const headerMenuLayout: Array<RoleMenuItem> = [
    //role: user
    {
        roleCode: 'USR',
        text: 'My overview',
        translateKey: 'header.nav.dashboard',
        link: ['/', 'dashboard'],
    },
    {
        roleCode: 'USR',
        text: 'My courses & exams',
        translateKey: 'header.nav.mycourse',
        permissionKey: 'MyCourses.MenuItem',
        link: ['/', 'mycourses'],
    },
    {
        roleCode: 'USR',
        text: 'My certificates',
        translateKey: 'header.nav.mycertificates',
        permissionKey: 'MyCertificates.MenuItem',
        link: ['/', 'mycertificates'],
    },
    {
        roleCode: 'USR',
        text: 'Course catalog',
        translateKey: 'header.nav.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        roleCode: 'USR',
        text: 'User FAQ',
        translateKey: 'header.nav.faquser',
        link: ['/', 'faq'],
        permissionKey: 'Faq.User.MenuItem',
        isVisibleForTenant: !JSON.parse(environment.multitenancy[window.location.host].fetchFaqExternalLinks),
    },
    {
        roleCode: 'USR',
        text: 'User FAQ',
        translateKey: 'header.nav.faquser',
        permissionKey: 'Faq.User.MenuItem',
        target: '_blank',
        translatableElinks: environment.multitenancy[window.location.host].uiConfig.externalLink.userFaq,
        isVisibleForTenant: JSON.parse(environment.multitenancy[window.location.host].fetchFaqExternalLinks),
    },
    //Uncomment the below code to enable calendar for user role
    // {
    //     roleCode: 'USR',
    //     text: 'Calendar',
    //     translateKey: 'header.nav.calendar',
    //     link: ['/', 'calendar', 'user'],
    //     permissionKey: 'Calendar.Personal.MenuItem',
    // },
    //role: ov
    {
        roleCode: 'OV',
        text: 'My overview',
        translateKey: 'header.nav.ov.dashboard',
        link: ['/', 'dashboard'],
    },
    {
        roleCode: 'OV',
        text: 'Exam supervision',
        translateKey: 'header.nav.ov.examsupervision',
        elink: environment.multitenancy[window.location.host].qaroot,
        target: '_blank',
        permissionKey: 'ExamSupervision.MenuItem',
    },
    {
        roleCode: 'OV',
        text: 'Course catalog',
        translateKey: 'header.nav.ov.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        roleCode: 'OV',
        text: 'Certificates',
        translateKey: 'header.nav.ov.certificates',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        roleCode: 'OV',
        text: 'User management',
        translateKey: 'header.nav.ov.usermanagement',
        link: ['/', 'admin', 'users'],
        permissionKey: 'Users.MenuItem',
    },
    {
        roleCode: 'OV',
        text: 'Company management',
        translateKey: 'header.nav.ov.companymanagement',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        roleCode: 'OV',
        text: 'Calendar',
        translateKey: 'header.nav.ov.calendar',
        permissionKey: 'Calendar.Ov.MenuItem',
        link: ['/', 'calendar', 'ov'],
    },
    //role: AFA
    {
        roleCode: 'AFA',
        text: 'My overview',
        translateKey: 'header.nav.afa.dashboard',
        link: ['/', 'dashboard'],
    },
    {
        roleCode: 'AFA',
        text: 'Exam supervision',
        translateKey: 'header.nav.afa.examsupervision',
        elink: environment.multitenancy[window.location.host].qaroot,
        target: '_blank',
        permissionKey: 'ExamSupervision.MenuItem',
    },
    {
        roleCode: 'AFA',
        text: 'Course catalog',
        translateKey: 'header.nav.afa.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        roleCode: 'AFA',
        text: 'Certificates',
        translateKey: 'header.nav.afa.certificates',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        roleCode: 'AFA',
        text: 'User management',
        translateKey: 'header.nav.afa.usermanagement',
        link: ['/', 'admin', 'users'],
        permissionKey: 'Users.MenuItem',
    },
    {
        roleCode: 'AFA',
        text: 'Company management',
        translateKey: 'header.nav.afa.companymanagement',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        roleCode: 'AFA',
        text: 'Calendar',
        translateKey: 'header.nav.afa.calendar',
        permissionKey: 'Calendar.Admin.MenuItem',
        link: ['/', 'calendar', 'admin'],
    },
    //role: SU
    {
        roleCode: 'SU',
        text: 'My overview',
        translateKey: 'header.nav.su.dashboard',
        link: ['/', 'dashboard'],
    },
    {
        roleCode: 'SU',
        text: 'Exam supervision',
        translateKey: 'header.nav.su.examsupervision',
        elink: environment.multitenancy[window.location.host].qaroot,
        target: '_blank',
        permissionKey: 'ExamSupervision.MenuItem',
    },
    {
        roleCode: 'SU',
        text: 'Course catalog',
        translateKey: 'header.nav.su.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        roleCode: 'SU',
        text: 'Certificates',
        translateKey: 'header.nav.su.certificates',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        roleCode: 'SU',
        text: 'User management',
        translateKey: 'header.nav.su.usermanagement',
        link: ['/', 'admin', 'users'],
        permissionKey: 'Users.MenuItem',
    },
    {
        roleCode: 'SU',
        text: 'Company management',
        translateKey: 'header.nav.su.companymanagement',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        roleCode: 'SU',
        text: 'Calendar',
        translateKey: 'header.nav.su.calendar',
        permissionKey: 'Calendar.Admin.MenuItem',
        link: ['/', 'calendar', 'admin'],
    },
    //role: IA
    {
        roleCode: 'IA',
        text: 'My overview',
        translateKey: 'header.nav.ia.dashboard',
        link: ['/', 'dashboard'],
    },
    {
        roleCode: 'IA',
        text: 'Exam supervision',
        translateKey: 'header.nav.ia.examsupervision',
        elink: environment.multitenancy[window.location.host].qaroot,
        target: '_blank',
        permissionKey: 'ExamSupervision.MenuItem',
    },
    {
        roleCode: 'IA',
        text: 'Course catalog',
        translateKey: 'header.nav.ia.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        roleCode: 'IA',
        text: 'Certificates',
        translateKey: 'header.nav.ia.certificates',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        roleCode: 'IA',
        text: 'User management',
        translateKey: 'header.nav.ia.usermanagement',
        link: ['/', 'admin', 'users'],
        permissionKey: 'Users.MenuItem',
    },
    {
        roleCode: 'IA',
        text: 'Company management',
        translateKey: 'header.nav.ia.companymanagement',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        roleCode: 'IA',
        text: 'Calendar',
        translateKey: 'header.nav.ia.calendar',
        permissionKey: 'Calendar.Ov.MenuItem',
        link: ['/', 'calendar', 'ov'],
    },
    //role: EX
    {
        roleCode: 'EX',
        text: 'My overview',
        translateKey: 'header.nav.ex.dashboard',
        link: ['/', 'dashboard'],
    },
    {
        roleCode: 'EX',
        text: 'Exam supervision',
        translateKey: 'header.nav.ex.examsupervision',
        elink: environment.multitenancy[window.location.host].qaroot,
        target: '_blank',
        permissionKey: 'ExamSupervision.MenuItem',
    },
    {
        roleCode: 'EX',
        text: 'Course catalog',
        translateKey: 'header.nav.ex.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    // {
    //     roleCode: 'EX',
    //     text: 'My courses & exams',
    //     translateKey: 'header.nav.ex.mycourse',
    //     permissionKey: 'MyCourses.MenuItem',
    //     link: ['/', 'mycourses'],
    // },
    {
        roleCode: 'EX',
        text: 'Certificates',
        translateKey: 'header.nav.ex.certificates',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        roleCode: 'EX',
        text: 'User management',
        translateKey: 'header.nav.ex.usermanagement',
        link: ['/', 'admin', 'users'],
        permissionKey: 'Users.MenuItem',
    },
    {
        roleCode: 'EX',
        text: 'Company management',
        translateKey: 'header.nav.ex.companymanagement',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        roleCode: 'EX',
        text: 'Calendar',
        translateKey: 'header.nav.ex.calendar',
        link: ['/', 'calendar', 'examiner'],
        permissionKey: 'Calendar.Personal.MenuItem',
    },
    //role: QAA
    { roleCode: 'QAA', text: 'My overview', translateKey: 'header.nav.dashboard', link: ['/', 'dashboard'] },
    //role: REP
    { roleCode: 'REP', text: 'My overview', translateKey: 'header.nav.dashboard', link: ['/', 'dashboard'] },
    {
        roleCode: 'REP',
        text: 'Exam supervision',
        translateKey: 'header.nav.rep.examsupervision',
        elink: environment.multitenancy[window.location.host].qaroot,
        target: '_blank',
        permissionKey: 'ExamSupervision.MenuItem',
    },
    {
        roleCode: 'REP',
        text: 'Course catalog',
        translateKey: 'header.nav.rep.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        roleCode: 'REP',
        text: 'Certificates',
        translateKey: 'header.nav.rep.certificates',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        roleCode: 'REP',
        text: 'User management',
        translateKey: 'header.nav.rep.usermanagement',
        link: ['/', 'admin', 'users'],
        permissionKey: 'Users.MenuItem',
    },
    {
        roleCode: 'REP',
        text: 'Company management',
        translateKey: 'header.nav.rep.companymanagement',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        roleCode: 'REP',
        text: 'Calendar',
        translateKey: 'header.nav.rep.calendar',
        permissionKey: 'Calendar.Ov.MenuItem',
        link: ['/', 'calendar', 'ov'],
    },
    //role: ACC
    { roleCode: 'ACC', text: 'My overview', translateKey: 'header.nav.dashboard', link: ['/', 'dashboard'] },
    //role: ATT
    { roleCode: 'ATT', text: 'My overview', translateKey: 'header.nav.dashboard', link: ['/', 'dashboard'] },
    //role: TE
    { roleCode: 'TE', text: 'My overview', translateKey: 'header.nav.dashboard', link: ['/', 'dashboard'] },
    {
        roleCode: 'TE',
        text: 'Calendar',
        translateKey: 'header.nav.teacher.calendar',
        link: ['/', 'calendar', 'teacher'],
        permissionKey: 'Calendar.Personal.MenuItem',
    },
    {
        roleCode: 'TE',
        text: 'frequently asked questions',
        translateKey: 'header.nav.faquser',
        link: ['/', 'faq'],
        permissionKey: 'Faq.User.MenuItem',
        isVisibleForTenant: !JSON.parse(environment.multitenancy[window.location.host].fetchFaqExternalLinks),
    },
    {
        roleCode: 'TE',
        text: 'frequently asked questions',
        translateKey: 'header.nav.faquser',
        permissionKey: 'Faq.User.MenuItem',
        target: '_blank',
        translatableElinks: environment.multitenancy[window.location.host].uiConfig.externalLink.userFaq,
        isVisibleForTenant: JSON.parse(environment.multitenancy[window.location.host].fetchFaqExternalLinks),
    },
    //role: CEM
    { roleCode: 'CEM', text: 'My overview', translateKey: 'header.nav.dashboard', link: ['/', 'dashboard'] },
    {
        roleCode: 'CEM',
        text: 'Exam supervision',
        translateKey: 'header.nav.cem.examsupervision',
        elink: environment.multitenancy[window.location.host].qaroot,
        target: '_blank',
        permissionKey: 'ExamSupervision.MenuItem',
    },
    {
        roleCode: 'CEM',
        text: 'Course catalog',
        translateKey: 'header.nav.cem.coursecatalog',
        link: ['/', 'coursecatalog'],
        permissionKey: 'CourseCatalog.MenuItem',
    },
    {
        roleCode: 'CEM',
        text: 'Certificates',
        translateKey: 'header.nav.cem.certificates',
        link: ['/', 'admin', 'certificates'],
        permissionKey: 'Certificates.MenuItem',
    },
    {
        roleCode: 'CEM',
        text: 'User management',
        translateKey: 'header.nav.cem.usermanagement',
        link: ['/', 'admin', 'users'],
        permissionKey: 'Users.MenuItem',
    },
    {
        roleCode: 'CEM',
        text: 'Company management',
        translateKey: 'header.nav.cem.companymanagement',
        link: ['/', 'admin', 'companies'],
        permissionKey: 'Companies.MenuItem',
    },
    {
        roleCode: 'CEM',
        text: 'Calendar',
        translateKey: 'header.nav.cem.calendar',
        permissionKey: 'Calendar.Ov.MenuItem',
        link: ['/', 'calendar', 'ov'],
    },
];

const applicationMenuStructure: ApplicationMenu = {
    get myProfile(): MenuItem {
        return <MenuItem>{
            text: 'My profile',
            link: ['/', 'my-profile'],
            translateKey: 'application.nav.myprofile',
            icon: ' fa-user-plus',
        };
    },
};

const footerMenuItems: LanguageMenuItem[] = [
    {
        cultureCode: 'en-GB',
        text: 'Search',
        translateKey: 'footer.nav.search',
        target: '_blank',
        link: '#',
    },
    {
        cultureCode: 'nl-BE',
        text: 'Zoeken',
        translateKey: 'footer.nav.search',
        target: '_blank',
        link: '#',
    },
    {
        cultureCode: 'fr-BE',
        text: 'Recherche',
        translateKey: 'footer.nav.search',
        target: '_blank',
        link: '#',
    },

    //Disclaimer
    {
        cultureCode: 'en-GB',
        text: 'Disclaimer',
        translateKey: 'footer.nav.disclaimer',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/en/disclaimer',
    },
    {
        cultureCode: 'nl-BE',
        text: 'Disclaimer',
        translateKey: 'footer.nav.disclaimer',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/nl/disclaimer',
    },
    {
        cultureCode: 'fr-BE',
        text: 'Avis de non-responsabilité',
        translateKey: 'footer.nav.disclaimer',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/fr/avis-de-non-responsabilite',
    },

    //Privacy policy
    {
        cultureCode: 'en-GB',
        text: 'Privacy policy',
        translateKey: 'footer.nav.privacypolicy',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/en/privacy-policy',
    },
    {
        cultureCode: 'nl-BE',
        text: 'Privacybeleid',
        translateKey: 'footer.nav.privacypolicy',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/nl/privacybeleid',
    },
    {
        cultureCode: 'fr-BE',
        text: 'Politique de confidentialité',
        translateKey: 'footer.nav.privacypolicy',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/fr/politique-de-confidentialite',
    },

    //Cookie statement
    {
        cultureCode: 'en-GB',
        text: 'Cookie statement',
        translateKey: 'footer.nav.cookiestatement',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/en/cookie-statement',
    },
    {
        cultureCode: 'nl-BE',
        text: 'Cookieverklaring',
        translateKey: 'footer.nav.cookiestatement',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/nl/cookieverklaring',
    },
    {
        cultureCode: 'fr-BE',
        text: 'Déclaration de Cookies',
        translateKey: 'footer.nav.cookiestatement',
        target: '_blank',
        elink: 'https://www.febelfin-academy.be/fr/declaration-de-cookies',
    },
];

@Injectable()
export class MenuService {
    public get menuItems(): Array<CollapsableMenuItem> {
        const menu = _.cloneDeep(menuLayout);
        return _.chain(menu)
            .filter((item) => String.isNullOrWhiteSpace(item.permissionKey) || this.authorizationService.hasPermission(item.permissionKey))
            .forEach(
                (item) =>
                    (item.subItems = _.chain(item.subItems)
                        .filter(
                            (subitem) =>
                                String.isNullOrWhiteSpace(subitem.permissionKey) || this.authorizationService.hasPermission(subitem.permissionKey)
                        )
                        .value())
            )
            .map((item) => Object.assign({ isCollapsed: false }, item))
            .value();
    }

    public getHeaderMenuItemsForRole(roleCode: string): Array<CollapsableMenuItem> {
        const menu = _.cloneDeep(headerMenuLayout.filter((f) => f.roleCode === roleCode));
        return _.chain(menu)
            .filter(
                (item) =>
                    (item.isVisibleForTenant === undefined || item.isVisibleForTenant === true) &&
                    (String.isNullOrWhiteSpace(item.permissionKey) || this.authorizationService.hasPermission(item.permissionKey))
            )
            .forEach(
                (item) =>
                    (item.subItems = _.chain(item.subItems)
                        .filter(
                            (subitem) =>
                                String.isNullOrWhiteSpace(subitem.permissionKey) || this.authorizationService.hasPermission(subitem.permissionKey)
                        )
                        .value())
            )
            .map((item) => Object.assign({ isCollapsed: false }, item))
            .value();
    }

    public get applicationMenuItems(): ApplicationMenu {
        return applicationMenuStructure;
    }

    public getFooterMenuItemsForLanguage(cultureCode: string): Array<LanguageMenuItem> {
        const menu = _.cloneDeep(footerMenuItems.filter((f) => f.cultureCode === cultureCode));
        return _.chain(menu)
            .filter((item) => String.isNullOrWhiteSpace(item.permissionKey) || this.authorizationService.hasPermission(item.permissionKey))
            .forEach(
                (item) =>
                    (item.subItems = _.chain(item.subItems)
                        .filter(
                            (subitem) =>
                                String.isNullOrWhiteSpace(subitem.permissionKey) || this.authorizationService.hasPermission(subitem.permissionKey)
                        )
                        .value())
            )
            .map((item) => Object.assign({ isCollapsed: false }, item))
            .value();
    }

    constructor(private authorizationService: AuthorizationService) {}
}
