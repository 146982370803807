import { TranslatableLinks } from '@lms-common/core/models/translatablelinks.model';

//External link for searching training when training card is empty on user dashboard
export const certassurSearchTrainingLink: TranslatableLinks[] = [
    {
        cultureCode: 'en-GB',
        elink: 'https://www.certassur.be/en/',
    },
    {
        cultureCode: 'nl-BE',
        elink: 'https://www.certassur.be/nl/exameninfo/examens-en-eindtermen',
    },
    {
        cultureCode: 'fr-BE',
        elink: 'https://www.certassur.be/fr/information-examens/examens-et-socles-de-competences ',
    },
];
