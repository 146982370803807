import { Action } from '@ngrx/store';

import { IMessage } from '../core/models/message.model';

export const NotificationActionsKey = {
    NOTIFICATIONCREATED: 'Notification.Created',
    GETALLNOTIFICATIONS: 'Notification.GetAll',
    GETALLSCHEDULEDNOTIFICATIONSACTION: 'Notification.GetAllScheduled',
    GETALLNOTIFICATIONS_COMPLETED: 'Notification.GetAll.Completed',
    GETALLSCHEDULEDNOTIFICATIONSACTION_COMPLETED: 'Notification.GetAllScheduled.Completed',
    DISMISSNOTIFICATION: 'Notification.Dismiss',
};

export class NotificationCreatedAction implements Action {
    readonly type = NotificationActionsKey.NOTIFICATIONCREATED;
    constructor(public message: IMessage) {}
}

export class GetAllNotificationsAction implements Action {
    readonly type = NotificationActionsKey.GETALLNOTIFICATIONS;
    constructor() {}
}

export class GetAllNotificationsCompletedAction implements Action {
    readonly type = NotificationActionsKey.GETALLNOTIFICATIONS_COMPLETED;
    constructor(public messages: IMessage[]) {}
}

export class DismissNotificationAction implements Action {
    readonly type = NotificationActionsKey.DISMISSNOTIFICATION;
    constructor(public message: IMessage) {}
}

export class GetAllScheduledNotificationsAction implements Action {
    readonly type = NotificationActionsKey.GETALLSCHEDULEDNOTIFICATIONSACTION;
    constructor() {}
}

export class GetAllScheduledNotificationsCompletedAction implements Action {
    readonly type = NotificationActionsKey.GETALLSCHEDULEDNOTIFICATIONSACTION_COMPLETED;
    constructor(public messages: IMessage[]) {}
}

export type NotificationActions =
    | NotificationCreatedAction
    | GetAllNotificationsAction
    | GetAllNotificationsCompletedAction
    | GetAllScheduledNotificationsAction
    | GetAllScheduledNotificationsCompletedAction
    | DismissNotificationAction;
