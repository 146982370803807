import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';

import { Languages } from '../core/constants/languages';
import { defaultTranslation, Translation } from '../core/models/translation.model';
import { TranslationManager } from '../core/services/translation.manager';
import { layoutActionKeys, SetLanguageAction, SetLanguageCompletedAction } from './layout.actions';

@Injectable()
export class TranslationEffects {
    setCurrentLanguageEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetLanguageAction>(layoutActionKeys.SETTRANSLATIONLANGUAGE),
                map((action) => {
                    let modifiedLanguage = { ...action.language }; // Create a new object with the same properties as action.language

                    if (!modifiedLanguage) {
                        modifiedLanguage = defaultTranslation;
                    }
                    if (String.isNullOrWhiteSpace(modifiedLanguage.displayName)) {
                        modifiedLanguage = _.find(Languages, (item: Translation) => item.code === modifiedLanguage.code);
                    }
                    this.translateService.use(modifiedLanguage.code);
                    moment.locale(modifiedLanguage.code);

                    this.translateService.get('primeng').subscribe((res) => this.primengconfig.setTranslation(res));
                    this.tManager._invokeLangUpdated(modifiedLanguage);
                    return new SetLanguageCompletedAction(modifiedLanguage);
                })
            ),
        { dispatch: true }
    );

    constructor(
        private actions$: Actions,
        private translateService: TranslateService,
        private tManager: TranslationManager,
        private primengconfig: PrimeNGConfig
    ) {}
}
