import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';

import { AppState } from '../../common/redux/app.state';

@Component({
    template: `<span>{{ 'auth.login' | translate }}</span>`,
})
export class LoginComponent implements OnInit {
    constructor(
        @Inject(OAuthService) private oauthService: OAuthService,
        @Inject(Store) private appStore: Store<AppState>,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        const redir = this.activatedRoute.snapshot.queryParams.redirectTo
            ? decodeURIComponent(this.activatedRoute.snapshot.queryParams.redirectTo)
            : undefined;
        window.localStorage.setItem('redirectTo', redir);

        this.oauthService.loadDiscoveryDocument().then(() => {
            this.oauthService.tryLogin().then((_) => {
                if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
                    if (redir) {
                    }
                    this.oauthService.initImplicitFlow();
                } else {
                    this.router.navigate(['/', 'auth', 'callback'], { queryParams: { redirectTo: redir } });
                }
            });
        });
    }

    hasQaTokenReferenceInToken(): boolean {
        const jwtPayload = this.oauthService.getAccessToken().split('.')[1];
        const payload = atob(jwtPayload);
        const payloadJson = JSON.parse(payload);
        return payloadJson['ExamToken'];
    }
}
