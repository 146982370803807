import { JsonProperty, SimpleProperty } from 'app/common/parsing/json-property.decorator';

export interface IlocationResource {
    id: number;
    name: string;
    roomName: string;
    street: string;
    postalCode: string;
    city: string;
    fullName: string;
}

export class LocationResource implements IlocationResource {
    @SimpleProperty()
    public id: number = undefined;
    @SimpleProperty()
    public name: string = undefined;
    @SimpleProperty()
    public roomName: string = undefined;
    @SimpleProperty()
    public street: string = undefined;
    @SimpleProperty()
    public postalCode: string = undefined;
    @SimpleProperty()
    public city: string = undefined;
    @SimpleProperty()
    public fullName: string = undefined;

    public get toAddress(): string {
        const address = [this.street, this.postalCode, this.city].filter((field) => field).join(' ');

        return address;
    }

    public get toCity(): string {
        const cityInfo = [this.postalCode, this.city].filter((field) => field).join(' ');
        return cityInfo;
    }

    public get toShortName(): string {
        const shortName = [this.name, this.roomName].filter((field) => field).join(' - ');
        return shortName;
    }
}
