import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ToggleableTranslateParser extends TranslateDefaultParser {
    private isShowKeyModeEnabled = false;

    toggle() {
        this.isShowKeyModeEnabled = !this.isShowKeyModeEnabled;
        return this.isShowKeyModeEnabled;
    }

    getValue(target: any, key: string): any {
        return this.isShowKeyModeEnabled ? key : super.getValue(target, key);
    }
}
