import { ComponentFactoryResolver, Inject, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Observable ,  ReplaySubject } from 'rxjs';

import { AbstractDialogComponent } from '../components/abstractdialog.component';
import { DialogContainerService } from './dialogcontainer.service';

@Injectable()
export class DialogService {
  private dialogCount = 0;
  private get viewContainer(): ViewContainerRef {
    return this.dialogContainer.viewContainer;
  }

  constructor(
    private dialogContainer: DialogContainerService,
    @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  /**
   * Creates a new Dialog and shows it.
   * @param dialog The Type of dialog specified by TDialog, which extends AbstractDialogComponent
   * @param parameters Optional Parameters of the dialog.
   *  Should be all PUBLIC properties the component has (=> name:string should be name:'Somestring')
   */
  public create<TResult, TDialog extends AbstractDialogComponent<TResult>>(dialog: Type<TDialog>, parameters?: object): Observable<TResult> {
    const sub = new ReplaySubject<TResult>();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<TDialog>(dialog);
    const componentRef = this.viewContainer.createComponent<TDialog>(componentFactory);
    Object.assign(componentRef.instance, parameters); // populate all @Input() properties
    componentRef.instance.openingDialogHandler(); // call the openingDialogHandler() => acts as the OnInit

    this.dialogContainer._onAdded(++this.dialogCount);

    componentRef.instance['destroy'] = () => {
      // when the component is Closing
      sub.next(componentRef.instance.result); // Complete the sub when the dialog is closing, with the last result.
      sub.complete();
      --this.dialogCount;
      if (this.dialogCount < 0) {
        this.dialogCount = 0;
      }
      this.dialogContainer._onDestroyed(this.dialogCount);
      componentRef.destroy(); // Destroy the rendered component
    };

    return sub.asObservable();
  }
}
