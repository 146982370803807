import * as moment from 'moment';

import { IParser } from './json-property.decorator';

export class DateTimeParser implements IParser<moment.Moment> {
  private _asutc = true;

  public get asUTC(): boolean {
    return this._asutc;
  }
  constructor(asUTC: boolean = true) {
    this._asutc = asUTC;
  }
  public parse(data: any): moment.Moment {
    if (typeof data === 'string' || data instanceof String || data === String || (data != null && data._isAMomentObject != null)) {
      const newDate = moment(data);
      if (newDate != null && newDate.isValid()) {
        const res = moment.utc(data);
        if (this.asUTC) {
          const offset = res.utcOffset();
          res.utcOffset();
        }
        return res;
      }
    }
    return null;
  }

  public jsonify(data: moment.Moment): any {
    if (data !== null && data !== undefined && data.isValid()) {
      if (this.asUTC) {
        return data.utc().toISOString();
      } else {
        return data.toISOString();
      }
    } else {
      return null;
    }
  }
}
