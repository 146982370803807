import { TrajectoryType } from '@lms-common/models/cm/trajectorytype.enum';
import { certassurSearchTrainingLink } from 'environments/constants/ca-translatablelinks.constant';
import { IUiConfiguration } from 'environments/iui.config';

export const certassurUiConfiguration: IUiConfiguration = {
    gdprDialog: {
        showAcceptNewsletter: false,
    },
    myCertificates: {
        showAttendanceCertificates: false,
        showExtraTrainingCertificates: false,
        showTrainingResults: false,
    },
    user360: {
        showTrainingResults: false,
        showAccreditations: false,
        showAttendanceCertificates: false,
        showExtraTrainingCertificates: false,
    },
    locations: {
        showFacilities: false,
        showCategories: false,
        showO365Mail: false,
    },
    courseManagement: {
        showSelfManagementToggle: false,
    },
    courseCatalog: {
        defaultType: TrajectoryType.Program,
        showTypeFilter: false,
        showCategoryFilter: false,
        showCertificationTypeFilter: false,
        showDifficultyLevelFilter: false,
        showAccreditationTypeFilter: false,
        showLearningTypeFilter: false,
    },
    dashboard: {
        showTrainingCertificatesWidget: false,
        showAccreditationProgressWidget: false,
        showInterestingTrainingsWidget: false,
    },
    externalLink: {
        userFaq: null,
        extraTraining: null,
        searchTraining: certassurSearchTrainingLink,
    },
};
