import { Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogContainerService } from '../../../common/core/dialog/dialogcontainer.service';
@Component({
    selector: 'app-dialog-container',
    templateUrl: './dialogcontainer.component.html',
    styleUrls: ['./dialogcontainer.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class DialogContainerComponent implements OnInit {
    @ViewChild('modalplaceholder', { read: ViewContainerRef, static: true })
    private container: ViewContainerRef;
    public showBackdrop = false;

    constructor(@Inject(DialogContainerService) private dialogService: DialogContainerService) {}

    ngOnInit() {
        this.dialogService.register(
            this.container,
            (count) => (this.showBackdrop = count !== 0),
            (count) => (this.showBackdrop = count !== 0)
        );
    }
}
