import * as moment from 'moment';

import { IResource } from '../../core/models/iresource.model';
import { NamedResource } from '../../core/models/namedresource.model';
import { JsonProperty } from '../../parsing/json-property.decorator';
import { SessionEntityTypedModel } from './fixedsession.readmodel';
import { OpenSessionReadModel } from './opensession.readmodel';

export class OpenBookedSessionReadModel extends OpenSessionReadModel implements IResource {
  public sessionType = 'open';

  @JsonProperty(null, SessionEntityTypedModel)
  public entities: SessionEntityTypedModel[] = undefined;

  public static empty(): OpenBookedSessionReadModel {
    const model = new OpenBookedSessionReadModel();
    model.startTime = moment().startOf('day');
    model.endTime = moment().startOf('day');

    model.teachers = new Array<NamedResource>();
    model.examLocation = undefined;

    model.elanPlusCapacity = 0;
    model.faCapacity = 0;
    model.availability = 0;

    model.name = String.empty();

    return model;
  }
}
