import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-custom-cell-renderer',
    template: `<span>{{ params.value }}</span>`,
})
export class CustomCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    // gets called whenever the cell refreshes
    //    return true to stop the grid from refreshing and clearing selection
    refresh(params: any): boolean {
        this.params = params;
        return true;
    }
}
