import { NamedResource } from '../../../../../common/core/models/namedresource.model';
import { JsonProperty, SimpleProperty } from '../../../../../common/parsing/json-property.decorator';
import { OccurrenceEntityReadModel } from './occurrenceentity.readmodel';

export class OccurrenceReadModel {
  @JsonProperty(null, NamedResource)
  public occurrence: NamedResource = undefined;

  @JsonProperty(null, NamedResource)
  public trajectory: NamedResource = undefined;

  @SimpleProperty()
  public isPublished: boolean = undefined;
  @SimpleProperty()
  public isAvailableForEditing: boolean = undefined;

  @JsonProperty(null, OccurrenceEntityReadModel)
  public occurrenceEntities: OccurrenceEntityReadModel[] = undefined;
}

export class OccurrenceWriteModel {
  @SimpleProperty()
  public id: number = undefined;

  @SimpleProperty()
  public name: string = undefined;

  @SimpleProperty()
  public elanPlusCapacity: number = undefined;

  @SimpleProperty()
  public elanPlusParticipants: number = undefined;
}
