export enum TrajectoryType {
  Trajectory = 0,
  Program = 1,
  Subscription = 2
}

export const TranslatableTrajectoryType = [
  { id: TrajectoryType.Trajectory, name: 'coursecatalog.trajectorytype.trajectory' },
  { id: TrajectoryType.Program, name: 'coursecatalog.trajectorytype.program' },
  { id: TrajectoryType.Subscription, name: 'coursecatalog.trajectorytype.subscription' }
];
