import { SimpleProperty } from '../../../../common/parsing/json-property.decorator';

export class StorageAccess {
    @SimpleProperty()
    public sasToken: string = undefined;
    @SimpleProperty()
    public accountName: string = undefined;
    @SimpleProperty()
    public container: string = undefined;
}
