import { NamedResource } from '../../../../../common/core/models/namedresource.model';
import { PurchasedLicenseReadModel } from '../../../../../common/models/items/licenses/purchasedlicense.readmodel';
import { SubscriptionReadModel } from '../../../../../common/models/items/subscriptions/subscription.readmodel';
import { JsonProperty, SimpleProperty } from '../../../../../common/parsing/json-property.decorator';
import { ProgramListModel } from '../../../programmanagement/overview/models/program.listmodel';

export class CompanyPurchasesReadModel {
    @SimpleProperty() public companyId: number = undefined;
    @SimpleProperty() public companyName: string = undefined;
    @SimpleProperty() public childCompanies: NamedResource[] = undefined;
    @JsonProperty(null, PurchasedLicenseReadModel) public licenses: PurchasedLicenseReadModel[] = undefined;
    @SimpleProperty() public subscriptions: SubscriptionReadModel[] = undefined;
    @SimpleProperty() public credits: number = undefined;
    @SimpleProperty() public programs: ProgramListModel[] = undefined;
}
