import { JsonProperty } from '../../parsing/json-property.decorator';
import { INamedResource } from './inamedresource.model';
import { Resource } from './resource.model';

export class NamedResource extends Resource implements INamedResource {
  @JsonProperty() public name: string = undefined;

  constructor(id?: number, name?: string) {
    super(id);
    this.name = name;
  }
}
