import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/public-api';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-grid-template',
    template: `<ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="context"></ng-container>`,
})
export class GridTemplateComponent implements ICellRendererAngularComp {
    public template: TemplateRef<any>;
    public context: any = {};

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams) {
        this.setTemplateAndParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams) {
        this.setTemplateAndParams(params);
        return true;
    }

    setTemplateAndParams(params) {
        this.template = params['ngTemplate'];
        this.context = {
            rowInfo: {
                rowData: params.data,
                rowId: params.node.id,
            },
        };
    }
}
