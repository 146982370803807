import { IMessage, MessageDuration, MessageLevel, MessageType } from '../models/message.model';

const defaultMessage: IMessage = {
  title: '',
  messageType: MessageType.Toast,
  messageLevel: MessageLevel.Info,
  messageDuration: MessageDuration.Medium
};

export class MessageTextBuilder {
  private result: { text: string; translate: boolean };
  constructor(text?: string) {
    this.result = {
      text: text,
      translate: false
    };
  }
  static makeText(text: string) {
    return new MessageTextBuilder(text);
  }

  withText(text: string) {
    this.result.text = text;
    return this;
  }
  doTranslate() {
    this.result.translate = true;
    return this;
  }

  build() {
    return this.result;
  }
}

export class MessageBuilder {
  private message: IMessage;

  constructor(message: IMessage = defaultMessage) {
    this.message = { ...(<IMessage>message), creationTimestamp: Date.now() };
  }

  /**
   * Specifies the Title of the message
   * @param title  The title of the message
   */
  public withTitle(textBuilder: MessageTextBuilder) {
    const data = textBuilder.build();
    this.message.title = data.text;
    this.message.translateTitle = data.translate;
    return this;
  }

  /**
   * Specifies the Icon class infront of the message, when the display component supports it
   * @param iconClass The icon class
   */
  public withIcon(iconClass: string) {
    this.message.iconClass = iconClass;
    return this;
  }

  /**
   * Specifies the Content of the message
   * @param content The content of the messsage
   */
  public withContent(textBuilder: MessageTextBuilder) {
    const data = textBuilder.build();
    this.message.content = data.text;
    this.message.translateContent = data.translate;
    return this;
  }

  public withTranslationParameters(parameters: { [key: string]: string }) {
    this.message.translationParameters = parameters;
    return this;
  }

  /**
   * Specifies the message type
   * @param type The message type of the Message (options: Toast, Notification)
   */
  public withMessageType(type: MessageType) {
    this.message.messageType = type;
    return this;
  }

  /**
   * Specifies the message level
   * @param level The message level of the message (Options: Info, Success, Warn, Danger)
   */
  public withMessageLevel(level: MessageLevel) {
    this.message.messageLevel = level;
    return this;
  }

  /**
   * Sets the messagetype as Toast
   */
  public asToast() {
    return this.withMessageType(MessageType.Toast);
  }

  /**
   * Sets the messagetype as Notification
   */
  public asNotification() {
    return this.withMessageType(MessageType.Notification);
  }

  /**
   * Sets the message display level as Info
   */
  public asInfo() {
    return this.withMessageLevel(MessageLevel.Info);
  }

  /**
   * Sets the message display level as Success
   */
  public asSuccess() {
    return this.withMessageLevel(MessageLevel.Success);
  }

  /**
   * Sets the message display level as Warning
   */
  public asWarning() {
    return this.withMessageLevel(MessageLevel.Warn);
  }

  /**
   * Sets the message display level as Danger
   */
  public asDanger() {
    return this.withMessageLevel(MessageLevel.Danger);
  }

  /**
   * Specifies the duration of the message
   * @param duration  The duration of the message (Options: Short = 1500, Medium = 2500, Long = 5000, ExtraLong = 7500, Fixed = -1)
   */
  public withDuration(duration: MessageDuration) {
    this.message.messageDuration = duration;
    return this;
  }

  /**
   * Sets the message display duration as Short
   */
  public withShortDuration() {
    return this.withDuration(MessageDuration.Short);
  }

  /**
   * Sets the message display duration as Short
   */
  public withMediumDuration() {
    return this.withDuration(MessageDuration.Medium);
  }

  /**
   * Sets the message display duration as Long
   */
  public withLongDuration() {
    return this.withDuration(MessageDuration.Long);
  }

  /**
   * Sets the message display duration as ExtraLong
   */
  public withExtraLongDuration() {
    return this.withDuration(MessageDuration.ExtraLong);
  }

  /**
   * Sets the message display duration as Fixed
   */
  public withFixedDuration() {
    return this.withDuration(MessageDuration.Fixed);
  }

  /**
   * Speficies if the message is dismissable
   * @param dismissable  Default true
   */
  public isDismissable(dismissable = true) {
    this.message.dismissable = dismissable;
    this.message.messageDuration = MessageDuration.Fixed;
    return this;
  }

  /**
   * The factory to get the promise which will trigger the dismiss functionality behind the message
   * @param dismissPromise
   */
  public withDismissMessage(dismissPromise: () => Promise<boolean>) {
    this.message.dismissMessage = dismissPromise;
    this.message.messageDuration = MessageDuration.Fixed;
    return this;
  }
  /**
   * Sets the onDismissable event
   * @param event The event
   */
  public withOnDismissEvent(event: () => void) {
    this.message.onDismiss = event;
    return this;
  }

  /**
   * The promise before the event is dismissed
   * @param event The Event
   */
  public withBeforeDismissEvent(event: () => Promise<boolean>) {
    this.message.beforeDismiss = event;
    return this;
  }

  /**
   * Clones the current builder
   */
  public clone() {
    return new MessageBuilder(Object.assign({}, this.message));
  }

  /**
   * Builds the message object
   */
  public build(): IMessage {
    return this.message;
  }
}
