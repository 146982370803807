export interface ActiveRole {
    code: string;
    displayName: string;
}

export const defaultActiveRole: ActiveRole = {
    code: 'USR',
    displayName: 'User',
};

export const defaultTranslatedActiveRole: ActiveRole = {
    code: 'USR',
    displayName: 'common.role.usr',
};

export const defaultShortActiveRole: ActiveRole = {
    code: 'USR',
    displayName: 'USR',
};
