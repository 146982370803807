import { SimpleProperty } from '../../../../../common/parsing/json-property.decorator';

export class CompanyCreditBalanceReadModel {
    @SimpleProperty() public companyId: number = undefined;
    @SimpleProperty() public credits: number = undefined;
    public static empty(): CompanyCreditBalanceReadModel {
        const model = new CompanyCreditBalanceReadModel();
        model.companyId = 0;
        model.credits = 0;
        return model;
    }
}
