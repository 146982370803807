import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { CalendarModule } from 'primeng/calendar';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule, CalendarModule, ReactiveFormsModule, FormsModule],
    providers: [],
})
export class DatepickerComponent implements OnInit, OnChanges {
    value: Date | undefined;
    _date: moment.Moment;
    _maxDate: moment.Moment;
    _initValue: moment.Moment;

    @Input()
    set date(value: moment.Moment) {
        value = moment(value);
        if (!value.isValid()) {
            value = undefined;
        }
        this._date = value;
    }
    get date() {
        return this._date;
    }

    @Input()
    set initValue(value: moment.Moment) {
        value = moment(value);
        if (!value.isValid()) {
            value = undefined;
        }
        this._initValue = value;
    }
    get initValue() {
        return this._initValue;
    }

    @Input()
    set maxDate(value: moment.Moment) {
        value = moment(value);
        if (!value.isValid()) {
            value = undefined;
        }
        this._maxDate = value;
    }
    get maxDate() {
        return this._maxDate;
    }

    @Input()
    isTextDisabled = false;
    @Input()
    minDate: moment.Moment;
    @Input()
    isDisabled = false;
    @Input()
    isMonthPicker: boolean;
    @Input()
    isYearPicker: boolean;
    @Input()
    showButtonBar = false;
    @Input()
    showIcon = true;
    @Input()
    icon = 'ph-calendar-blank';
    @Output()
    onChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();
    @Output()
    blur: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onClear: EventEmitter<any> = new EventEmitter<any>();

    public get maxDateAsDate(): Date {
        return this.maxDate && this.maxDate.isValid() ? this.maxDate.toDate() : undefined;
    }

    public get minDateAsDate(): Date | undefined {
        return this.minDate && this.minDate.isValid() ? this.minDate.toDate() : undefined;
    }

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.date) {
            this.value = changes.date.currentValue ? new Date(changes.date.currentValue) : undefined;
        }
    }

    setValue(val: Date) {
        const date = val ? moment(val) : null;
        if (!date || !date.isSame(this.date)) {
            this.onChange.emit(date);
            this.date = date;
        }
    }

    shown(dp: { value: any }) {
        if (!this.value && this.initValue) {
            dp.value = this.initValue.toDate();
        }
    }

    clearField() {
        this.setValue(undefined);
    }

    public get view(): string {
        return this.isYearPicker ? 'year' : this.isMonthPicker ? 'month' : 'date';
    }

    public get dateFormat(): string {
        return this.isYearPicker ? 'yy' : this.isMonthPicker ? 'MM yy' : 'dd/mm/yy';
    }

    onDateChange(value: any) {
        this.setValue(this.value);
    }

    ngOnInit() {
        if (this.date) {
            this.value = this.date.toDate();
        }
    }
}
