const jsonMetadataKey = 'jsonProperty';
const designType = 'design:type';

export interface IJsonMetaData<T> {
  name?: string;
  cls?: new () => T;
  parserFactory?: () => IParser<T>;
  simpleMapping?: boolean;
}

export interface IParser<T> {
  parse(data: any): T;
  jsonify(data: T): any;
}
export function SimpleProperty<T>(): any {
  return Reflect.metadata(jsonMetadataKey, <IJsonMetaData<T>>{
    name: null,
    cls: null,
    parserFactory: null,
    simpleMapping: true
  });
}
export function JsonProperty<T>(name?: string, cls?: new () => T, parserFactory: () => IParser<T> = null): any {
  return Reflect.metadata(jsonMetadataKey, <IJsonMetaData<T>>{
    name: name,
    cls: cls,
    parserFactory: parserFactory,
    simpleMapping: false
  });
}

export function getClazz(target: any, propertyKey: string) {
  return Reflect.getMetadata(designType, target, propertyKey);
}

export function getJsonProperty<T>(target: any, property: string): IJsonMetaData<T> {
  return Reflect.getMetadata(jsonMetadataKey, target, property);
}
