import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError as observableThrowError } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

import { HideAppLoadingIndicatorAction, ShowAppLoadingIndicatorAction } from './../../../layout/layout.actions';
import { AppState } from './../../../redux/app.state';

@Injectable()
export class HttpLongRequestInterceptor implements HttpInterceptor {
  constructor(@Inject(Store) private appStore: Store<AppState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.headers.has('app-long-request')) {
      this.appStore.dispatch(new ShowAppLoadingIndicatorAction());
      return next.handle(req).pipe(
        rxoperators.catchError((err, obs) => {
          return observableThrowError(err);
        }),
        rxoperators.finalize(() => {
          this.appStore.dispatch(new HideAppLoadingIndicatorAction());
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
