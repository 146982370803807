import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '../../../../../common/core/http/http.service';
import { IGetPagedFilteredService } from '../../../../../common/core/http/igetpagedfilteredservice';
import { MappingHttpService } from '../../../../../common/core/http/mappinghttp.service';
import { UriBuilder } from '../../../../../common/core/http/uri.builder';
import { NamedResource } from '../../../../../common/core/models/namedresource.model';
import { IOccurrenceViewModel } from '../models/ioccurrence.viewmodel';
import { OccurrenceReadModel, OccurrenceWriteModel } from '../models/occurrence.readmodel';
import { OccurrenceListPageResult } from '../models/occurrencelistpageresult.model';
import { IOccurrenceFilterDefinition } from './filterdefinitions/occurence.filterdefinition';

@Injectable()
export class HttpOccurrencesService implements IGetPagedFilteredService<IOccurrenceViewModel, IOccurrenceFilterDefinition, OccurrenceListPageResult> {
    private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('trajectoryoccurrences');
    protected get baseUri(): UriBuilder {
        return this._uri.clone();
    }

    constructor(
        private mappingHttpService: MappingHttpService,
        private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory
    ) {}

    getPaged(filterDefinition: IOccurrenceFilterDefinition, isLongRequest: boolean): Observable<OccurrenceListPageResult> {
        return this.httpService.getPagedFiltered<OccurrenceListPageResult, IOccurrenceFilterDefinition>(
            this.baseUri.build(),
            filterDefinition,
            null,
            isLongRequest
        );
    }

    getAll(): Observable<IOccurrenceViewModel[]> {
        return this.httpService.getAll(this.baseUri.build(), null, false);
    }

    public getById(id: number): Observable<OccurrenceReadModel> {
        return this.mappingHttpService.getAndMap(OccurrenceReadModel, this.baseUri.withId(id).build(), null, true);
    }

    // public create(trajectory: TrajectoryWriteModel): Observable<TrajectoryReadModel> {
    //   return this.mappingHttpService.createAndMap(TrajectoryReadModel, trajectory, this.baseUri.build(), null, true);
    // }
    public getElanPlusCapacity(id: number): Observable<OccurrenceWriteModel> {
        return this.mappingHttpService.getAndMap(OccurrenceWriteModel, this.baseUri.withId(id).withPath('elanpluscapacity').build(), null, true);
    }

    public updateElanPlusCapacity(occurrence: OccurrenceWriteModel): Observable<boolean> {
        return this.httpService
            .custom('PUT', this.baseUri.withId(occurrence.id).withPath('elanpluscapacity').build(), occurrence, null, true)
            .pipe(map((res) => res.status === 200));
    }

    public delete(id: number): Observable<boolean> {
        return this.httpService.delete(this.baseUri.withId(id).build(), null, true);
    }

    public publish(occurrence: NamedResource): Observable<boolean> {
        return this.httpService
            .custom('POST', this.baseUri.withId(occurrence.id).withPath('publish').build(), null, null, true)
            .pipe(map((resp) => resp.status === 200));
    }

    public unPublish(occurrence: NamedResource): Observable<boolean> {
        return this.httpService
            .custom('POST', this.baseUri.withId(occurrence.id).withPath('unpublish').build(), null, null, true)
            .pipe(map((resp) => resp.status === 200));
    }
    public isCommunicationTurnedOff(id: number): Observable<boolean> {
        return this.httpService.get<boolean>(this.baseUri.withId(id).withPath('iscommunicationturnedoff').build(), null, false);
    }
}
