import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';

import { HttpService } from '../../../common/core/http/http.service';
import { MappingHttpService } from '../../../common/core/http/mappinghttp.service';
import { UriBuilder } from '../../../common/core/http/uri.builder';
import { StorageAccess } from './models/storageaccess';







@Injectable()
export class FileUploadService {
    private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withMediaApiRoot().withPath('storage');
    protected get baseUri(): UriBuilder {
        return this._uri.clone();
    }

    constructor(private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory,
        private mappingHttpService: MappingHttpService) { }

    public getStorageAccessByContainerName(containerName: string): Observable<StorageAccess> {
        return this.mappingHttpService.getAndMap(StorageAccess,
            this.baseUri.withPath('sas').withPath(containerName).build(),
            null,
            true);
    }
}
