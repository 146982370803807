import { SimpleProperty } from '../../parsing/json-property.decorator';

export class CreatedPurchaseModel {
  @SimpleProperty()
  public reference: string = undefined;
  @SimpleProperty()
  public paymentUri: string = undefined;
  @SimpleProperty()
  public invoiceReference: string = undefined;
}
