import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';

import { SubscriptionCleanerService } from '../../common/core/subscriptioncleaner.service';
import { AppState } from '../../common/redux/app.state';
import { GetCurrentUserDataAction, SetIsAuthenticatedAction } from '../../common/redux/auth/auth.actions';
import { authSelectors } from '../../common/redux/auth/auth.selector';
import { decomposeUri } from './uri.helper';

@Component({
    template: `
        <p *ngIf="step != 3">
            <span *ngIf="step == 1">{{ 'auth.callback.step1' | translate }}</span>
            <span *ngIf="step == 2">{{ 'auth.callback.step2' | translate }}</span>
            <span *ngIf="step == 3">{{ 'auth.callback.step3' | translate }}</span>
        </p>
        <p *ngIf="step == 3">
            <i class="fa-check-circle-o" aria-hidden="true"></i>
            {{ 'auth.callback.step1' | translate }}
        </p>
    `,
})
export class CallbackComponent implements OnInit, OnDestroy {
    public step = 0;
    public subCleaner: SubscriptionCleanerService;
    constructor(
        private oauthService: OAuthService,
        private appStore: Store<AppState>,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.subCleaner = new SubscriptionCleanerService();
    }

    ngOnInit() {
        let redir = this.activatedRoute.snapshot.queryParams.redirectTo
            ? decodeURIComponent(this.activatedRoute.snapshot.queryParams.redirectTo)
            : decodeURIComponent(window.localStorage.getItem('redirectTo')) || null;

        if (window.localStorage.getItem('goto-profile') === 'true') {
            redir = '/my-profile';
        }

        window.localStorage.removeItem('goto-profile');

        const uriComponents = decomposeUri(redir);
        this.subCleaner.push(
            this.appStore.select(authSelectors.currentUserSelector).subscribe((user) => {
                if (user && user.id !== undefined && user.consent.acceptedPolicy) {
                    this.step = 3;
                    if (redir) {
                        window.localStorage.removeItem('redirectTo');
                        setTimeout(() => this.router.navigate([uriComponents.uri], { queryParams: uriComponents.queryParams }), 1500);
                    } else {
                        setTimeout(() => this.router.navigate(['/']), 1500);
                    }
                }
            })
        );
        this.step = 1;
        if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
            this.loadUserData();
        } else {
            this.oauthService.loadDiscoveryDocument().then(() => {
                this.oauthService
                    .tryLogin()
                    .then((_) => {
                        if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
                            this.router.navigate(['/', 'auth', 'login']);
                        } else {
                            this.loadUserData();
                        }
                    })
                    .catch((result) => {
                        const errorResponsesRequiringUserInteraction = [
                            'interaction_required',
                            'login_required',
                            'account_selection_required',
                            'consent_required',
                        ];
                        if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
                            console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                            // Could also call this.oauthService.initImplicitFlow() here...
                            return Promise.resolve();
                        }
                        return Promise.reject(result);
                    });
            });
        }
    }

    hasQaTokenReferenceInToken(): boolean {
        const jwtPayload = this.oauthService.getAccessToken().split('.')[1];
        const payload = atob(jwtPayload);
        const payloadJson = JSON.parse(payload);
        return payloadJson['ExamToken'];
    }

    ngOnDestroy() {
        this.subCleaner.destroy();
    }

    loadUserData() {
        this.step = 2;
        this.appStore.dispatch(new SetIsAuthenticatedAction(this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()));
        this.appStore.dispatch(new GetCurrentUserDataAction());
    }
}
