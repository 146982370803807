import { NamedResource } from '../../../core/models/namedresource.model';

export enum Entities {
  Trajectory = 1,
  Course = 2,
  Exam = 3,
  Training = 4,
  StudyMaterial = 5,
  LearningEntity = 6,
  ExamEntity = 7,
  TrainingEntity = 8,
  StudyEntity = 9
}

export const TranslatableTestResultEntityTypes: NamedResource[] = [
  new NamedResource(Entities.ExamEntity, 'testresult.entitytypes.examentity'),
  new NamedResource(Entities.TrainingEntity, 'testresult.entitytypes.trainingentity')
];
