import { PendingDataLayerModel } from './pending.datalayermodel';

export class PurchaseProductDataLayerModel {
  sku: string;
  name: string;
  category: string;
  price: number;
  quantity: number;

  constructor(pending: PendingDataLayerModel) {
    this.sku = pending.trajectoryOccurrenceId.toString();
    this.name = pending.name;
    this.category = pending.category;
    this.price = pending.price;
    this.quantity = pending.quantity;
  }
}

export class PurchaseDataLayerModel {
  transactionId: string;
  transactionTotal: number;
  transactionProducts: PurchaseProductDataLayerModel;

  constructor(transactionId: string, pending: PendingDataLayerModel) {
    this.transactionId = transactionId;
    this.transactionTotal = pending.price;
    this.transactionProducts = new PurchaseProductDataLayerModel(pending);
  }

  toDataLayerFormat() { // convert to format specified by D&G
    return {
      'event': 'purchase',
      'transactionId': this.transactionId,
      'transactionTotal': this.transactionTotal,
      'transactionProducts': [{
        'sku': this.transactionProducts.sku,
        'name': this.transactionProducts.name,
        'category': this.transactionProducts.category,
        'price': this.transactionProducts.price,
        'quantity': this.transactionProducts.quantity
      }]
    };
  }
}


