import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../../redux/app.state';
import { AuthorizationService } from '../auth/authorization.service';
import { CurrentUserService } from '../auth/currentuser.service';
import { ImpersonationService } from '../auth/impersonation.service';
import { HttpService } from '../http/http.service';
import { UriBuilder } from '../http/uri.builder';
import { IMessage, Message } from '../models/message.model';
import { SignalRService } from './signalr.service';

@Injectable()
export class HttpTaskNotificationService {
    constructor(
        private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory,
        private currentUser: CurrentUserService,
        private impersonationService: ImpersonationService,
        private authService: AuthorizationService,
        private signalR: SignalRService,
        private appStore: Store<AppState>
    ) { }

    getAll(): Observable<Message[]> {
        if (
            (this.impersonationService.isImpersonating && this.currentUser.userId !== this.impersonationService.impersonatingUserId) ||
            !this.authService.hasPermission('TaskNotifications.ViewAll')
        ) {
            return of(<IMessage[]>{});
        }

        return this.httpService
            .getAll<IMessage>(
                new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('tasknotifications').build(),
                null,
                false
            )
            .pipe(
                map((messages) => {
                    return messages.map((message) => {
                        const msg = new Message(message.title, message.messageType, message.messageLevel, message.messageDuration,
                            message.id, message.creationTimestamp, message.iconClass, message.translateTitle, message.content,
                            message.translateContent, true, message.isDismissed, 'serverside', message.translationParameters,
                            message.isTask, message.hasAttachment, message.dismissMessage, message.onDismiss,
                            message.beforeDismiss);
                        if (msg.id) {
                            msg.beforeDismiss = () => {
                                return new Promise((onResolve, onReject) => {
                                    this.dismiss(msg.id).subscribe((res) => {
                                        onResolve(res);
                                    });
                                });
                            };
                        }
                        return msg;
                    });
                })
            );
    }

    dismiss(id: number): Observable<boolean> {
        return this.httpService.delete(
            new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('tasknotifications').withId(id).build()
        );
    }
}
