import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';

export interface IDropdownStyleOptions {
    size: 'small' | 'normal';
}
export const defaultDropdownStyleOptions: IDropdownStyleOptions = {
    size: 'normal',
};

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule, DropdownModule, ReactiveFormsModule, FormsModule],
    providers: [TranslateService],
})
export class DropdownComponent implements OnChanges {
    @Input() name = '';
    @Input() selected?: any;
    @Input() displaySelector?: string;
    @Input() items: any[] = [];
    @Input() isDisabled = false;
    @Input() translateMessage = false;
    @Input() autoSelectSingleItem = true;
    @Input() styleOptions: IDropdownStyleOptions = defaultDropdownStyleOptions;
    @Input() showLabels = false;
    @Input() placeholderText?: string;
    @Input() translatePlaceholder = false;
    @Input() showPlaceholderWhenEmpty = false;
    @Input() triggerSelectEventByDefault = false;

    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private translateService: TranslateService) {
        translateService.use(translateService.currentLang);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.autoSelectSingleItem) {
            this.autoSelectSingleItemByDefault();
        }
    }

    selectValue(event: any) {
        this.selected = event.value;
        this.onChange.next(event.value);
    }

    isSelected(obj: any) {
        if (!this.selected) {
            return false;
        }
        return this.displaySelector ? obj[this.displaySelector] === this.selected[this.displaySelector] : obj.toString() === this.selected.toString();
    }

    getDisplayText(item: any) {
        let displayText = this.displaySelector ? item[this.displaySelector] : item.toString();
        // Our bindings aren't always correct, translateMessage is of type boolean but because of binding is can be a string 'true'
        if (
            this.translateMessage &&
            (this.translateMessage === true || this.translateMessage === 'true') &&
            !String.isNullOrWhiteSpace(displayText)
        ) {
            displayText = this.translateService.instant(displayText);
        }
        return !String.isNullOrWhiteSpace(displayText) ? displayText : '';
    }

    getLabels(item: any): string[] {
        const labels = item && item.getLabels ? item.getLabels() : null;

        if (!labels) {
            return [];
        }

        return labels.map((label) => this.translateService.instant(label));
    }

    getTranslatedPlaceholder(): string {
        if (this.translatePlaceholder && !String.isNullOrWhiteSpace(this.placeholderText)) {
            return this.translateService.instant(this.placeholderText);
        }
        return !String.isNullOrWhiteSpace(this.placeholderText) ? this.placeholderText : '';
    }

    private autoSelectSingleItemByDefault() {
        if (this.selected) {
            return;
        }

        if (!this.items || this.items.length !== 1) {
            return;
        }

        this.selected = this.items[0];

        if (this.triggerSelectEventByDefault) {
            this.onChange.next(this.selected);
        }
    }
}
