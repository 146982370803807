import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

@Injectable()
export class HttpDebugInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const reqInfo = {
      correlationId: Date.now(),
      method: req.method,
      url: req.urlWithParams,
      headers: req.headers,
      body: req.body
    };
    // eslint-disable-next-line no-console
    console.debug(`Http Request ${reqInfo.correlationId}`, reqInfo);
    return next.handle(req).pipe(
      rxoperators.tap(event => {
        if (event instanceof HttpResponse) {
          const resp = <HttpResponse<any>>event;
          const respInfo = {
            correlationId: reqInfo.correlationId,
            status: resp.status,
            statusText: resp.statusText,
            body: resp.body,
            header: resp.headers
          };
          // eslint-disable-next-line no-console
          console.debug(`Http Response ${reqInfo.correlationId}`, respInfo);
        }
      })
    );
  }
}
