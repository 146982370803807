import { SignalRConfiguration } from 'ng2-signalr';

import { TenantConfigurationFactory } from './tenantconfiguration.factory';

export function createSignalRConfig(): SignalRConfiguration {
    const c = new SignalRConfiguration();
    c.hubName = 'SignalRHub';
    c.url = TenantConfigurationFactory.getTenantConfigDirectly().signalrroot;
    c.logging = true;
    return c;
}
