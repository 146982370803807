import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { INamedResource } from '@lms-common/core/models/inamedresource.model';
import { MediaItemReadModel } from '@lms-common/core/models/mediaitem.readmodel';
import { HttpService } from '@lms-common/core/http/http.service';
import { IGetPagedFilteredService } from '@lms-common/core/http/igetpagedfilteredservice';
import { MappingHttpService } from '@lms-common/core/http/mappinghttp.service';
import { UriBuilder } from '@lms-common/core/http/uri.builder';
import { MapUtils } from '@lms-common/parsing/mapping.util';
import { FileSubmitModel } from '@lms-commoncomponents/fileupload/models/filesubmit.model';
import { MediaItemListModel } from '../models/mediaitemlist.model';
import { MediaItemListPageResult } from '../models/mediaitemlist.pageresult';
import { NamedResourceListPageResult } from '../models/namedresource.pageresult';

import { IMediaItemFilterDefinition } from '../models/mediaitemfilterdefinition';
import { defaultTrajectoryFilterDefinition, TrajectoryFilterDefinition } from '../models/trajectoryfilterdefinition';

@Injectable()
export class HttpMediaItemsService implements IGetPagedFilteredService<MediaItemListModel, IMediaItemFilterDefinition, MediaItemListPageResult> {
    private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('mediaitems');
    protected get baseUri(): UriBuilder {
        return this._uri.clone();
    }

    private _mediaApiUri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withMediaApiRoot().withPath('storage');
    protected get baseMediaApiUri(): UriBuilder {
        return this._mediaApiUri.clone();
    }

    private _trajectoriesUri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('trajectories');
    protected get baseTrajectoriesUri(): UriBuilder {
        return this._trajectoriesUri.clone();
    }

    constructor(
        private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory,
        private mappingHttpService: MappingHttpService,
        private authService: OAuthService
    ) {}

    getStreamUrl() {
        return this.baseMediaApiUri.withPath('stream').build();
    }

    getPaged(filterDefinition: IMediaItemFilterDefinition, isLongRequest: boolean): Observable<MediaItemListPageResult> {
        return this.httpService.getPagedFiltered<MediaItemListPageResult, IMediaItemFilterDefinition>(
            this.baseUri.build(),
            filterDefinition,
            null,
            isLongRequest
        );
    }

    download(id: number, filename: string) {
        return this.generateSas(id).subscribe((res) => {
            const uri = this.baseMediaApiUri.withPath('download').build();
            const accessToken = this.authService.getAccessToken();

            const link = document.createElement('a');

            link.href = `${uri}?bearer=${accessToken}&${res}&id=${id}`;
            // link.href = `${uri}?${res}&id=${id}`;
            link.download = filename;
            let event: any;

            if (document.createEvent) {
                // Only for IE and Firefox
                event = document.createEvent('MouseEvent');
                event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            } else {
                // For Chrome
                event = new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window });
            }
            link.dispatchEvent(event);
            link.remove();
        });
    }

    generateSas(id: number) {
        return this.httpService.get(this.baseMediaApiUri.withId(id).withPath('generatesas').build(), null, true);
    }

    public finaliseUpload(fileSubmit: FileSubmitModel): Observable<MediaItemReadModel> {
        return this.httpService.custom('POST', this.baseUri.build(), fileSubmit, null, true).pipe(
            map((resp) => {
                if (resp.status === 204 || resp.status === 200) {
                    const mediaItem = MapUtils.deserialize(MediaItemReadModel, resp.body);
                    return mediaItem;
                }
                return null;
            })
        );
    }

    public getTrajectoriesAndPrograms(filter: string): Observable<INamedResource[]> {
        const filterDef = defaultTrajectoryFilterDefinition;
        filterDef.textFilter = filter;
        filterDef.maxItems = 15;

        return this.httpService
            .getPagedFiltered<NamedResourceListPageResult, TrajectoryFilterDefinition>(
                this.baseTrajectoriesUri.withPath('all').build(),
                filterDef,
                null,
                true
            )
            .pipe(map((t) => t.items));
    }

    public addNewMediaItems(trajectoryIds: number[], mediaItemIds: number[]) {
        return this.httpService.create(
            { trajectoryIds: trajectoryIds, mediaItemIds: mediaItemIds },
            this.baseTrajectoriesUri.withPath('link').withPath('new').build()
        );
    }

    public delete(id: number): Observable<any> {
        return this.httpService.delete(this.baseUri.withId(id).build(), null, true);
    }
}
