import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { AuthenticatedGuard } from './authenticated.guard';
import { AuthorizationService } from './authorization.service';

/**
 * Protects routes using the data in the route:
 * Usage:
 *  ...
 *  path: 'myroute',
 *  data: {
 *      permission: 'permission'
 *  },
 *  ...
 */
export interface AuthRouteData {
  permission: string;
}
@Injectable()
export class HasPermissionGuard implements CanActivate {
  constructor(private authorizedService: AuthorizationService, private router: Router, private authenticatedGuard: AuthenticatedGuard) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const data = <AuthRouteData>next.data;
    if (this.authenticatedGuard.canActivate(next, state) && data && data.permission) {
      const permissions = data.permission.split('|');
      if (_.some(permissions, permission => this.authorizedService.hasPermission(permission.trim()))) {
        return true;
      }
      this.router.navigate(['/', 'auth', 'forbidden']);
    }
    return false;
  }
}
