import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

@Injectable()
export class NoContentInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return next.handle(req).pipe(rxoperators.map(resp => {
            if (resp instanceof HttpResponse && (<HttpResponse<any>>resp).status === 204) {
                return new HttpResponse<any>(Object.assign({}, resp, { body: true }));
            }
            return resp;
        }));
    }
}
