import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { CurrentUserService } from './currentuser.service';

@Injectable()
export class AuthorizationService {
  constructor(private currentUserService: CurrentUserService) {}
  /**
   * Verifies if an has a certain Permission.
   * <strong>Note:</strong> You can use a wildcard at the end to indicate that the user should have a group of permissions:
   * Example: Users* -> User has any permission which starts with User
   * @param key The key to search for
   */
  hasPermission(key: string): boolean {
    if (String.isNullOrWhiteSpace(key)) {
      return true;
    }

    const keys = key.split('|');

    return _.some(keys, k => {
      k = k.trim();
      if (k.endsWith('*')) {
        k = k.replace(/[*]/, '');
        return _.some(this.currentUserService.permissions, permission => permission.toLowerCase().indexOf(k.toLowerCase()) === 0);
      } else {
        return _.some(this.currentUserService.permissions, permission => permission.toLowerCase() === k.toLowerCase());
      }
    });
  }
}
