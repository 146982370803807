import { Injectable } from '@angular/core';
import { PendingDataLayerModel } from './pending.datalayermodel';
import { PurchaseDataLayerModel } from './purchase.datalayermodel';
import { UserDataLayerModel } from './user.datalayermodel';
@Injectable()
export class DataLayerService {

  private currentUser: UserDataLayerModel;
  private pending: PendingDataLayerModel;

  setCurrentUser(reference: string, isCourseManager: boolean) {
    this.currentUser = new UserDataLayerModel(reference, isCourseManager);
  }

  pushPendingReservationStarted(trajectoryOccurrenceId: number, name: string, category: string, variant: string, quantity?: number) {
    this.pending = new PendingDataLayerModel(this.currentUser, trajectoryOccurrenceId, name, category, variant, quantity);
    (<any>window).dataLayer.push(this.pending.toDataLayerFormat());
  }

  pushPendingReservationQuantitySelected(quantity: number) {
    this.pending.setQuantity(quantity);
    (<any>window).dataLayer.push(this.pending.toDataLayerFormat());
  }

  pushPendingReservationPriceRequested(price: number) {
    this.pending.setPrice(price);
    (<any>window).dataLayer.push(this.pending.toDataLayerFormat());
  }

  pushReservationPurchased(transactionId: string) {
    const purchase = new PurchaseDataLayerModel(transactionId, this.pending);
    (<any>window).dataLayer.push(purchase.toDataLayerFormat());
  }
}
