import { SimpleProperty } from '../../parsing/json-property.decorator';

export class SessionAvailabilityModel {
  @SimpleProperty()
  public capacity: number = undefined;
  @SimpleProperty()
  public availableSlots: number = undefined;

  public toString(): string {
    return `${this.availableSlots} / ${this.capacity}`;
  }
}
