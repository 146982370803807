import { pagingConstants } from '@lms-common/core/constants/paging.constants';
import { IPageDefinition } from '@lms-common/core/models/ipagedefinition';

export class TrajectoryFilterDefinition implements IPageDefinition {
    page: number;
    maxItems?: number;
    orderBy: string;
    textFilter: string;

    constructor() {
        (this.maxItems = pagingConstants.maxItems), (this.page = 0), (this.orderBy = ''), (this.textFilter = '');
    }

    empty() {
        return new TrajectoryFilterDefinition();
    }
}

export const defaultTrajectoryFilterDefinition: TrajectoryFilterDefinition = new TrajectoryFilterDefinition();
