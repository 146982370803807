import * as _ from 'lodash';

export function getPaths(obj: any): string[] {
  const keys = Object.keys(obj);
  const res: string[] = [];
  _.forEach(keys, k => {
    if (typeof obj[k] === 'string') {
      res.push(obj[k]);
    } else if (typeof obj[k] === 'object') {
      res.push(...getPaths(obj[k]));
    }
  });
  return res;
}
