import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class FilterService {
  private map: Map<string, any> = new Map();

  constructor(private router: Router) { }

  clear(key: string): void {
    this.map.delete(this.getCurrentKey(key));
  }

  update(value: any, key?: string): void {
    this.map.set(this.getCurrentKey(key), value);
  }

  get(key?: string): any {
    if (this.map.has(this.getCurrentKey(key))) {
      return this.map.get(this.getCurrentKey(key));
    }
    return null;
  }

  getCurrentKey(key?: string): string {
    return this.router.url + (key ? key : '');
  }
}
