import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TextMaskModule } from 'angular2-text-mask';
import * as _ from 'lodash';
import { DragulaModule } from 'ng2-dragula';
import { FileUploadModule } from 'ng2-file-upload';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { nlBeLocale } from 'ngx-bootstrap/chronos';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TooltipModule as PrimengTooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ListboxModule } from 'primeng/listbox';

import { IsVisibleForTenantDirective } from 'app/common/core/directives/isvisiblefortenant.directive';
import { HasPermissionDirective } from '../../common/core/directives/haspermission.directive';
import { HttpCountryServiceService } from '../../common/httpservices/httpcountryservice.service';
import { HttpCompanyPurchaseService } from '../admin/companies/purchases/httpcompanypurchase.service';
import { CreateProgramDialogComponent } from '../admin/companies/purchases/programs/create/createprogramdialog.component';
import { HttpMediaItemsService } from '../admin/mediaitems/overview/services/httpmediaitems.service';
import { HttpPricingDiscountService } from '../admin/pricing/misc/httppricingdiscount.service';
import { HttpPricingMiscService } from '../admin/pricing/misc/httppricingmisc.service';
import { UserFormComponent } from '../admin/users/detail/userform/userform.component';
import { TestMailFlowDialogComponent } from '../dialogs/testmailflow/testmailflow.component';
import { HttpUserPurchaseService } from '../purchases/userpurchases/httpuserpurchase.service';
import { AddressComponent } from './address/address.component';
import { ButtonComponent } from './button/button.component';
import { DayCalendarComponent } from './calendars/daycalendar/daycalendar.component';
import { MonthCalendarComponent } from './calendars/monthcalendar/monthcalendar.component';
import { OccurrenceItemComponent } from './calendars/trajectoryoccurrences/components/occurrenceitem/occurrenceitem.component';
import { OccurrenceItemsComponent } from './calendars/trajectoryoccurrences/components/occurrenceitems.component';
import { HttpOccurrencesService } from './calendars/trajectoryoccurrences/services/httpoccurrences.service';
import { WeekCalendarComponent } from './calendars/weekcalendar/weekcalendar.component';
import { ClickInsideDirective } from './clickinside.directive';
import { ClickOutsideDirective } from './clickoutside.directive';
import { CollapsableMultiTypeaheadComponent } from './collapsablemultitypeahead/collapsablemultitypeahead.component';
import { HistCreditsDialogComponent } from './credits/hist/histcreditsdialog.component';
import { CheckboxCellComponent } from './datatable/checkboxcell/checkboxcell.component';
import { DatatableWrapperComponent } from './datatable/datatablewrapper.component';
import { DateCellComponent } from './datatable/datecell/datecell.component';
import { DateMomentFormatterComponent } from './datagrid/cellComponents/date-format-moment.component';
import { GridEditorTemplateComponent } from './datagrid/cellComponents/grideditortemplate.component';
import { GridTemplateComponent } from './datagrid/cellComponents/gridtemplate.component';
import { DropdownCellComponent } from './datatable/dropdowncell/dropdowncell.component';
import { EmailCellComponent } from './datatable/emailcell/emailcell.component';
import { SelectCellComponent } from './datatable/selectcell/selectcell.component';
import { CellComponent } from './datatable/textboxcell/cell.component';
import { TimepickerCellComponent } from './datatable/timepickercell/timepickercell.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { FormDatepickerComponent } from './datepicker/formdatepicker.component';
import { DateTimepickerComponent } from './datetimepicker/datetimepicker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TranslateDropdownPipe } from './dropdown/translate-dropdown.pipe';
import { TranslateDropdownDirective } from './dropdown/translate-dropdown.directive';
import { DataGridComponent } from './datagrid/datagrid.component';
import { FormDropdownComponent } from './dropdown/formdropdown.component';
import { DropdownButtonComponent } from './dropdownbutton/dropdownbutton.component';
import { DurationTimepickerComponent } from './durationtimepicker/durationtimepicker.component';
import { FileDropDirective } from './fileupload/filedrop.directive';
import { FileSelectDirective } from './fileupload/fileselect.directive';
import { FileUploadComponent } from './fileupload/fileupload.component';
import { FileUploadService } from './fileupload/fileupload.service';
import { CheckableFilterComponent } from './filters/checkablefilter/checkablefilter.component';
import { DateFilterComponent } from './filters/datefilter/datefilter.component';
import { DropdownFilterComponent } from './filters/dropdownfilter/dropdownfilter.component';
import { FocusDirective } from './focus.directive';
import { HelpButtonComponent } from './helpbutton/helpbutton.component';
import { HttpHelpButtonService } from './helpbutton/helpbutton.service';
import { HttpCultureService } from './httpculture.service';
import { ItemSelectorComponent } from './itemselector/itemselector.component';
import { ItsmeButtonComponent } from './itsme/itsmebutton.component';
import { KboComponent } from './kbo/kbo.component';
import { ListboxComponent } from './listbox/listbox.component';
import { LoaderComponent } from './loader/loader.component';
import { MembershipDialogComponent } from './membership/membershipdialog.component';
import { MiscArticlesDialogComponent } from './miscarticlesdialog/miscarticles.dialog';
import { MultiTypeaheadComponent } from './multitypeahead/multitypeahead.component';
import { NotificationItemComponent } from './notifications/notificationitem.component';
import { NotificationComponent } from './notifications/notifications.component';
import { DecimalToTimePipe } from './pipes/decimaltotime.pipe';
import { FormatLocalMomentPipe } from './pipes/formatlocalmoment.pipe';
import { FormatMomentPipe } from './pipes/formatmoment.pipe';
import { LimitToPipe } from './pipes/limitto.pipe';
import { OrderbyArrayPipe, OrderbyAscArrayPipe, OrderbyDescArrayPipe } from './pipes/orderby.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { StripHtmlPipe } from './pipes/striphtml.pipe';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { OrderConfirmationComponent } from './purchase/orderconfirmation.component';
import { InvoicingInformationComponent } from './purchase/parts/invoicinginformation.component';
import { OrderInformationComponent } from './purchase/parts/orderinformation.component';
import { PaymentTypeSelectionComponent } from './purchase/parts/paymenttypeselection.component';
import { PersonalInformationComponent } from './purchase/parts/personalinformation.component';
import { PricingInformationComponent } from './purchase/parts/pricinginformation.component';
import { ProfessionalInformationComponent } from './purchase/parts/professionalinformation.component';
import { RichTextDirective } from './richtext/richtext.directive';
import { SelectComponent } from './select/select.component';
import { SelectableNodesComponent } from './selectablenodes/selectablenodes.component';
import { TabsModule } from './tabs/tabs.module';
import { TagsComponent } from './tags/tags.component';
import { FormToggleComponent } from './toggle/formtoggle.component';
import { ToggleComponent } from './toggle/toggle.component';
import { FormToggleButtonsComponent } from './togglebuttons/formtogglebuttons.component';
import { ToggleButtonsComponent } from './togglebuttons/togglebuttons.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { UserInvoiceAddressComponent } from './userinvoiceaddress/userinvoiceaddress.component';
import { UserProfilePictureComponent } from './userprofilepicture/userprofilepicture.component';
import { ModelStateComponent } from './validation/modelstate.component';
import { ValidationComponent } from './validation/validation.component';
import { VideoPlayerDialogComponent } from './videoplayer/dialog/videoplayer.dialog';
import { VideoComponent } from './videoplayer/videoplayer.component';
import { CatalogdetailService } from './viewtreenodes/catalogdetail.service';
import { ViewTreeNodesComponent } from './viewtreenodes/viewtreenodes.component';
import { InputSwitchComponent } from './datatable/input-switch/input-switch.component';
import { StringToLocalMomentPipe } from './pipes/stringToLocalMoment.pipe';
import { LocationToCityPipe, LocationToFullAddressPipe, LocationToShortNamePipe } from './pipes/location.pipe';
import { StepperService } from './header/stepper/stepper.service';
import { WrappedCalendarComponent } from './calendars/calenderwithfilter/wrappedcalendar.component';
import { TranslateLinksPipe } from './pipes/translatelink.pipe';
import { CustomCellRendererComponent } from './datagrid/cellComponents/custom-cell-renderer.component';

defineLocales();

@NgModule({
    imports: [
        DynamicDialogModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        CollapseModule.forRoot(),
        FileUploadModule,
        TabsModule,
        NgxDatatableModule,
        TextMaskModule,
        DragulaModule.forRoot(),
        AlertModule.forRoot(),
        TimepickerModule.forRoot(),
        RatingModule.forRoot(),
        BsDropdownModule.forRoot(),
        VgOverlayPlayModule,
        VgCoreModule,
        VgControlsModule,
        VgBufferingModule,
        DatepickerComponent,
        DropdownComponent,
        ButtonModule,
        PrimengTooltipModule,
        DataGridComponent,
        InputSwitchModule,
        AddressComponent,
        InputTextModule,
        ProgressSpinnerModule,
        ListboxModule,
    ],
    declarations: [
        WrappedCalendarComponent,
        TypeaheadComponent,
        MultiTypeaheadComponent,
        UserProfilePictureComponent,
        ClickOutsideDirective,
        ClickInsideDirective,
        TranslateDropdownPipe,
        TranslateDropdownDirective,
        FormDropdownComponent,
        LoaderComponent,
        HasPermissionDirective,
        IsVisibleForTenantDirective,
        FormDatepickerComponent,
        DateFilterComponent,
        DropdownFilterComponent,
        ItsmeButtonComponent,
        ListboxComponent,
        ToggleComponent,
        FormToggleComponent,
        ValidationComponent,
        ModelStateComponent,
        VideoComponent,
        DatatableWrapperComponent,
        RichTextDirective,
        NotificationComponent,
        NotificationItemComponent,
        OrderbyArrayPipe,
        UserFormComponent,
        SafeHtmlPipe,
        TimeAgoPipe,
        LimitToPipe,
        StringToLocalMomentPipe,
        LocationToFullAddressPipe,
        LocationToCityPipe,
        LocationToShortNamePipe,
        FileUploadComponent,
        FileDropDirective,
        FileSelectDirective,
        OrderbyDescArrayPipe,
        OrderbyAscArrayPipe,
        FormatMomentPipe,
        MonthCalendarComponent,
        KboComponent,
        UserInvoiceAddressComponent,
        StripHtmlPipe,
        TagsComponent,
        DateTimepickerComponent,
        CellComponent,
        DateCellComponent,
        DateMomentFormatterComponent,
        GridEditorTemplateComponent,
        GridTemplateComponent,
        CheckboxCellComponent,
        DropdownCellComponent,
        ItemSelectorComponent,
        DurationTimepickerComponent,
        FormatLocalMomentPipe,
        EmailCellComponent,
        DayCalendarComponent,
        WeekCalendarComponent,
        FocusDirective,
        DecimalToTimePipe,
        CollapsableMultiTypeaheadComponent,
        MembershipDialogComponent,
        HistCreditsDialogComponent,
        ToggleButtonsComponent,
        CreateProgramDialogComponent,
        ButtonComponent,
        MiscArticlesDialogComponent,
        VideoPlayerDialogComponent,
        TimepickerCellComponent,
        DropdownButtonComponent,
        OccurrenceItemsComponent,
        OccurrenceItemComponent,
        OrderConfirmationComponent,
        PersonalInformationComponent,
        ProfessionalInformationComponent,
        PricingInformationComponent,
        PaymentTypeSelectionComponent,
        InvoicingInformationComponent,
        OrderInformationComponent,
        SelectComponent,
        SelectCellComponent,
        FormToggleButtonsComponent,
        CheckableFilterComponent,
        HelpButtonComponent,
        ViewTreeNodesComponent,
        SelectableNodesComponent,
        TestMailFlowDialogComponent,
        InputSwitchComponent,
        TranslateLinksPipe,
        CustomCellRendererComponent,
    ],
    providers: [
        FileUploadService,
        HttpCultureService,
        HttpMediaItemsService,
        HttpCountryServiceService,
        HttpCompanyPurchaseService,
        HttpPricingMiscService,
        HttpPricingDiscountService,
        HttpUserPurchaseService,
        HttpOccurrencesService,
        HttpHelpButtonService,
        CatalogdetailService,
        StepperService,
    ],
    exports: [
        StringToLocalMomentPipe,
        LocationToFullAddressPipe,
        LocationToShortNamePipe,
        LocationToCityPipe,
        FileUploadComponent,
        HelpButtonComponent,
        FileDropDirective,
        FileSelectDirective,
        AddressComponent,
        TypeaheadComponent,
        MultiTypeaheadComponent,
        UserProfilePictureComponent,
        ClickOutsideDirective,
        ClickInsideDirective,
        DropdownComponent,
        DataGridComponent,
        FormDropdownComponent,
        LoaderComponent,
        TooltipModule,
        PrimengTooltipModule,
        AlertModule,
        HasPermissionDirective,
        IsVisibleForTenantDirective,
        DatepickerComponent,
        FormDatepickerComponent,
        DateFilterComponent,
        DropdownFilterComponent,
        ListboxComponent,
        ToggleComponent,
        ItsmeButtonComponent,
        FormToggleComponent,
        ValidationComponent,
        ModelStateComponent,
        DatatableWrapperComponent,
        RichTextDirective,
        TabsModule,
        NotificationComponent,
        NotificationItemComponent,
        OrderbyArrayPipe,
        TextMaskModule,
        UserFormComponent,
        SafeHtmlPipe,
        TimeAgoPipe,
        LimitToPipe,
        OrderbyDescArrayPipe,
        OrderbyAscArrayPipe,
        FormatMomentPipe,
        MonthCalendarComponent,
        KboComponent,
        UserInvoiceAddressComponent,
        StripHtmlPipe,
        TagsComponent,
        VideoComponent,
        DateTimepickerComponent,
        CellComponent,
        DateCellComponent,
        DateMomentFormatterComponent,
        GridEditorTemplateComponent,
        CheckboxCellComponent,
        DropdownCellComponent,
        ItemSelectorComponent,
        DurationTimepickerComponent,
        FormatLocalMomentPipe,
        RatingModule,
        EmailCellComponent,
        DayCalendarComponent,
        WeekCalendarComponent,
        DecimalToTimePipe,
        CollapsableMultiTypeaheadComponent,
        MembershipDialogComponent,
        HistCreditsDialogComponent,
        ToggleButtonsComponent,
        CreateProgramDialogComponent,
        ButtonComponent,
        MiscArticlesDialogComponent,
        VideoPlayerDialogComponent,
        TimepickerCellComponent,
        DropdownButtonComponent,
        OccurrenceItemsComponent,
        OccurrenceItemComponent,
        OrderConfirmationComponent,
        SelectComponent,
        SelectCellComponent,
        FormToggleButtonsComponent,
        CheckableFilterComponent,
        ViewTreeNodesComponent,
        SelectableNodesComponent,
        InputSwitchComponent,
        TranslateLinksPipe,
    ],
    entryComponents: [
        CustomCellRendererComponent,
        GridTemplateComponent,
        MiscArticlesDialogComponent,
        VideoPlayerDialogComponent,
        TestMailFlowDialogComponent,
    ],
})
export class CommonComponentsModule {}

function defineLocales() {
    const locales = [nlBeLocale, enGbLocale, frLocale];
    _.each(locales, (l) => defineLocale(l.abbr, l));
}
