import * as moment from 'moment';

import { INamedResource } from '../../core/models/inamedresource.model';
import { IResource } from '../../core/models/iresource.model';
import { NamedResource } from '../../core/models/namedresource.model';
import { DateTimeParser } from '../../parsing/datetime.parser';
import { JsonProperty, SimpleProperty } from '../../parsing/json-property.decorator';
import { SessionReadModel } from './session.readmodel';
import { SessionArticleReadModel } from './sessionarticle.readmodel';

export enum AppointmentEntityType {
  LearningEntity = 6,
  ExamEntity = 7,
  TrainingEntity = 8
}
export class SessionEntityTypedModel implements INamedResource {
  @SimpleProperty()
  id: number = undefined;
  @SimpleProperty()
  name: string = undefined;
  @JsonProperty('type')
  trajectoryEntityType: number = undefined;
  @JsonProperty(null, null, () => new DateTimeParser())
  startTime: moment.Moment = undefined;
  @JsonProperty(null, null, () => new DateTimeParser())
  endTime: moment.Moment = undefined;

  isReadonly = true;

  constructor(id?: number, name?: string, trajectoryEntityType?: number, startTime?: moment.Moment, endTime?: moment.Moment) {
    this.id = id;
    this.name = name;
    this.trajectoryEntityType = trajectoryEntityType;
    this.startTime = startTime;
    this.endTime = endTime;
  }

  public get type(): AppointmentEntityType {
    return <AppointmentEntityType>this.trajectoryEntityType;
  }

  public set type(value: AppointmentEntityType) {
    this.trajectoryEntityType = <number>value;
  }
}

export class SessionEntityReadModel implements IResource {
  @SimpleProperty()
  id: number = undefined;
  @SimpleProperty()
  type: number = undefined;
  @JsonProperty(null, SessionEntityTypedModel)
  public entity: SessionEntityTypedModel = undefined;
  @JsonProperty(null, null, () => new DateTimeParser())
  public startTime: moment.Moment = undefined;
  @JsonProperty(null, null, () => new DateTimeParser())
  public endTime: moment.Moment = undefined;
}

export class FixedSessionReadModel extends SessionReadModel implements IResource {
  public sessionType = 'fixed';
  @JsonProperty(null, SessionEntityReadModel)
  public entities: SessionEntityReadModel[] = undefined;
  @JsonProperty(null, SessionArticleReadModel)
  public articles: SessionArticleReadModel[] = undefined;
  @JsonProperty(null, NamedResource)
  public trajectoryOccurrence: NamedResource = undefined;

  @SimpleProperty()
  public tags: string = undefined;
  public static empty(): FixedSessionReadModel {
    const model = new FixedSessionReadModel();
    model.startTime = moment();
    model.endTime = moment();

    model.entities = [];
    model.articles = [];

    model.teachers = new Array<NamedResource>();
    model.examiners = new Array<NamedResource>();
    model.location = undefined;
    model.examLocation = undefined;

    model.elanPlusCapacity = 0;
    model.faCapacity = 0;
    model.availability = 0;

    model.name = String.empty();
    return model;
  }
}
