import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpService } from '../http/http.service';
import { UriBuilder } from '../http/uri.builder';
import { AuthUserModel } from './authuser.model';






export enum ImpersonationServerStates {
  Allowed = 204,
  NotFound = 404,
  NotAllowed
}

@Injectable()
export class HttpAuthService {
  private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('users');
  protected get baseUri(): UriBuilder {
    return this._uri.clone();
  }

  constructor(private httpService: HttpService, private tenantConfigurationFactory: TenantConfigurationFactory) { }

  public getCurrentUser(): Observable<AuthUserModel> {
    return this.httpService.get<AuthUserModel>(this.baseUri.withPath('current').build(), null, true);
  }

  public impersonateUser(userId: number): Observable<ImpersonationServerStates> {
    return this.httpService
      .custom(
        'GET',
        this.baseUri
          .withId(userId)
          .withPath('impersonate')
          .build(),
        {},
        null,
        true
      )
      .pipe(
        map(res => <ImpersonationServerStates>res.status),
        catchError(err => {
          return of(ImpersonationServerStates.NotAllowed);
        })
      );
  }
}
