export const moduleTranslateIdentifiers = {
  common: {
    common: 'common',
    dialogs: 'common.dialogs',
    validation: 'common.validation',
    notifications: 'common.notifications'
  },
  components: {
    layout: 'layout',
    dashboard: 'dashboard',
    users: 'users',
    user360: 'user360',
    companies: 'companies',
    locations: 'locations',
    roles: 'roles',
    qa: 'qa',
    membershipmanagement: 'memberships',
    subscriptionmanagement: 'subscriptions',
    licensemanagement: 'licenses',
    coursemanagement: {
      coursemgmt: 'coursemgmt',
      trajectory: 'coursemgmt.trajectory',
      course: 'coursemgmt.course',
      learningentity: 'coursemgmt.learningentity',
      exam: 'coursemgmt.exam',
      examentity: 'coursemgmt.examentity',
      studymaterial: 'coursemgmt.studymaterial',
      studyentity: 'coursemgmt.studyentity',
      training: 'coursemgmt.training',
      trainingentity: 'coursemgmt.trainingentity',
      accreditationscore: 'coursemgmt.accreditationscore'
    },
    calendar: 'calendar',
    pricing: 'pricing',
    coursecatalog: 'coursecatalog',
    translations: 'translations'
  },
  myprofile: 'myprofile',
  notifications: 'notifications'
};
