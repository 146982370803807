import * as moment from 'moment';

import { IResource } from '../../core/models/iresource.model';
import { NamedResource } from '../../core/models/namedresource.model';
import { SessionReadModel } from './session.readmodel';

export class OpenSessionReadModel extends SessionReadModel implements IResource {
  public sessionType = 'open';

  public static empty(): OpenSessionReadModel {
    const model = new OpenSessionReadModel();
    model.startTime = moment().startOf('day');
    model.endTime = moment().startOf('day');

    model.teachers = new Array<NamedResource>();
    model.examiners = new Array<NamedResource>();
    model.examLocation = undefined;

    model.elanPlusCapacity = 0;
    model.faCapacity = 0;
    model.availability = 0;

    model.name = String.empty();

    return model;
  }
}
