import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../../common/core/http/http.service';
import { MappingHttpService } from '../../../../common/core/http/mappinghttp.service';
import { UriBuilder } from '../../../../common/core/http/uri.builder';
import { IPageDefinition } from '../../../../common/core/models/ipagedefinition';
import { StudyMaterialListPageResult } from '../../../../common/models/cm/sm/studymateriallist.result';
import { AssignCreditsWriteModel } from '../../../../common/models/items/credits/assigncredits.writemodel';
import { BuyCreditWriteModel } from '../../../../common/models/items/credits/buycredit.writemodel';
import { CreditPageResult } from '../../../../common/models/items/credits/credit.result';
import { LicenseWriteModel } from '../../../../common/models/items/licenses/license.writemodel';
import { PurchasedLicenseReadModel } from '../../../../common/models/items/licenses/purchasedlicense.readmodel';
import { CreatedPurchaseModel } from '../../../../common/models/orders/createdpurchase.model';
import { MapUtils } from '../../../../common/parsing/mapping.util';
import { CompanyMembershipModel } from '../../memberships/companies/models/company.membership.model';
import { ProgramListModel } from '../../programmanagement/overview/models/program.listmodel';
import { LicenseOverruleDatesWriteModel } from './licenses/expiredlicenses/licenseoverruledates.writemodel';
import { CompanyCreditBalanceReadModel } from './models/companycreditbalance.readmodel';
import { CompanyPurchasesReadModel } from './models/companypurchases.readmodel';
import { PlaceProgramOrder } from './models/placeprogramorder.model';

@Injectable()
export class HttpCompanyPurchaseService {
    private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('companypurchases');
    protected get baseUri(): UriBuilder {
        return this._uri.clone();
    }

    constructor(
        private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory,
        private mappingHttpService: MappingHttpService
    ) {}

    public getById(companyId: number, isLongRequest: boolean = true): Observable<CompanyPurchasesReadModel> {
        return this.mappingHttpService.getAndMap<CompanyPurchasesReadModel>(
            CompanyPurchasesReadModel,
            this.baseUri.withId(companyId).build(),
            null,
            isLongRequest
        );
    }

    public getCreditBalanceById(companyId: number, isLongRequest: boolean = true): Observable<CompanyCreditBalanceReadModel> {
        return this.mappingHttpService.getAndMap<CompanyCreditBalanceReadModel>(
            CompanyCreditBalanceReadModel,
            this.baseUri.withId(companyId).withPath('creditbalance').build(),
            null,
            isLongRequest
        );
    }

    public getValidLicenses(
        companyId: number,
        filterDefinition: IPageDefinition,
        isLongRequest: boolean = true
    ): Observable<PurchasedLicenseReadModel[]> {
        return this.httpService
            .getPagedFiltered<PurchasedLicenseReadModel[], IPageDefinition>(
                this.baseUri.withId(companyId).withPath('validlicenses').build(),
                filterDefinition,
                null,
                isLongRequest
            )
            .pipe(
                map((r) =>
                    _.map(r, (license) => {
                        license.startDate = moment.utc(license.startDate);
                        license.purchaseDate = moment.utc(license.purchaseDate);
                        license.expireDate = moment.utc(license.expireDate);
                        return license;
                    })
                )
            );
    }

    public getCredits(companyId: number, filterDefinition: IPageDefinition, isLongRequest: boolean = true): Observable<CreditPageResult> {
        return this.httpService.getPagedFiltered<CreditPageResult, IPageDefinition>(
            this.baseUri.withId(companyId).withPath('credits').build(),
            filterDefinition,
            null,
            isLongRequest
        );
    }

    public export(companyId: number, isLongRequest: boolean = true): Observable<boolean> {
        return this.httpService.downloadFile(this.baseUri.withId(companyId).withPath('credits').withPath('export').build(), isLongRequest);
    }

    public getExpiredLicenses(
        companyId: number,
        filterDefinition: IPageDefinition,
        isLongRequest: boolean = true
    ): Observable<PurchasedLicenseReadModel[]> {
        return this.httpService
            .getPagedFiltered<PurchasedLicenseReadModel[], IPageDefinition>(
                this.baseUri.withId(companyId).withPath('expiredlicenses').build(),
                filterDefinition,
                null,
                isLongRequest
            )
            .pipe(
                map((r) =>
                    _.map(r, (license) => {
                        license.startDate = moment.utc(license.startDate);
                        license.purchaseDate = moment.utc(license.purchaseDate);
                        license.expireDate = moment.utc(license.expireDate);
                        return license;
                    })
                )
            );
    }

    public overruleLicenseDates(
        companyId: number,
        licenseItemId: number,
        model: LicenseOverruleDatesWriteModel
    ): Observable<PurchasedLicenseReadModel> {
        const url = this.baseUri.withId(companyId).withPath('licenses').withId(licenseItemId).withPath('overruledates').build();

        return this.httpService.custom<PurchasedLicenseReadModel>('POST', url, model, null, true).pipe(
            map((r) => r.body),
            map((r) => MapUtils.deserialize(PurchasedLicenseReadModel, r))
        );
    }

    public getLicensesStudyMaterials(
        companyId: number,
        filterDefinition: IPageDefinition,
        isLongRequest: boolean = true
    ): Observable<StudyMaterialListPageResult> {
        return this.httpService.getPagedFiltered<StudyMaterialListPageResult, IPageDefinition>(
            this.baseUri.withId(companyId).withPath('licenses').withPath('studymaterials').build(),
            filterDefinition,
            null,
            isLongRequest
        );
    }

    public buyCredits(credits: BuyCreditWriteModel, isLongRequest: boolean = true): Observable<CreatedPurchaseModel> {
        return this.mappingHttpService.createAndMap<BuyCreditWriteModel, CreatedPurchaseModel>(
            CreatedPurchaseModel,
            credits,
            this.baseUri.withPath('buycredits').build(),
            null,
            true
        );
    }

    public buyLicense(license: LicenseWriteModel, isLongRequest: boolean = true): Observable<CreatedPurchaseModel> {
        return this.mappingHttpService.createAndMap<LicenseWriteModel, CreatedPurchaseModel>(
            CreatedPurchaseModel,
            license,
            this.baseUri.withPath('buylicense').build(),
            null,
            true
        );
    }

    public buyMembership(membership: CompanyMembershipModel, isLongRequest: boolean = true): Observable<boolean> {
        return this.httpService
            .custom('POST', this.baseUri.withPath('buymembership').build(), MapUtils.serialize(membership), null, isLongRequest)
            .pipe(map((resp) => resp.status === 204));
    }

    getDecommissionedPrograms(id: number): Observable<ProgramListModel[]> {
        return this.mappingHttpService.getAllAndMap<ProgramListModel>(ProgramListModel, this.baseUri.withId(id).withPath('decommisioned').build());
    }

    public assignCredits(assignCredits: AssignCreditsWriteModel, forCompany: boolean = true, isLongRequest: boolean = true): Observable<boolean> {
        return this.httpService
            .custom(
                'POST',
                this.baseUri.withPath(forCompany ? 'assigncreditscompany' : 'assigncreditsusers').build(),
                assignCredits,
                null,
                isLongRequest
            )
            .pipe(map((resp) => resp.status === 204));
    }

    public assignProgram(program: ProgramListModel, orderId: number, isLongRequest: boolean = true): Observable<boolean> {
        return this.httpService
            .custom('POST', this.baseUri.withPath('assignprogram').withPath(orderId.toString()).build(), program, null, isLongRequest)
            .pipe(map((resp) => resp.status === 204));
    }

    public placeProgramOrder(placeProgramOrder: PlaceProgramOrder, isLongRequest: boolean = true): Observable<number> {
        return this.httpService
            .custom('POST', this.baseUri.withPath('placeprogramorder').build(), placeProgramOrder, null, isLongRequest)
            .pipe(map((resp) => +resp.body));
    }
}
